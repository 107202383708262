// api/axiosClient.js
import axios from 'axios';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import ToastHelper from 'general/helpers/ToastHelper';
import UserHelper from 'general/helpers/UserHelper';
import _ from 'lodash';
import queryString from 'query-string';

const sTag = '[AxiosClient]';

// Cai dat config mac dinh cho http request
// Tham khao: `https://github.com/axios/axios#request-config`
// de xem chi tiet
const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'content-type': 'application/json',
    langcode: 'vi',
    // mode: 'web',
    deviceinfo: 'web',
    appversion: '64',
    osinfo: 'Web',
    uuid: '123456',
    fcmtoken: '123456',
  },
  paramsSerializer: (params) => queryString.stringify(params),
});

axiosClient.interceptors.request.use(async (config) => {
  // LogHelper.log(`${sTag} - ${config.baseURL}${config.url}, ${config.method}, ${config.method === 'post' ? JSON.stringify(config.data) : JSON.stringify(config.params)}`);
  // console.log(`${sTag} - headers: ${JSON.stringify(config.headers.common)}`);
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }

    return response;
  },
  (error) => {
    console.log(`${sTag} - ${error}`);
    let errorMessage = null;
    const response = error.response;
    // console.log({ response });
    if (response && response.status === 403) {
      const { name, message } = response?.data?.error;
      if (message) {
        ToastHelper.showError(message);
      }
      throw 1001;
      // UserHelper.signOut();
      // window.location.href = '/auth/sign-in';
      return;
    }
    if (response && response.status === 401) {
      // goi refreshLogin de refresh token
      // refreshLogin();
      UserHelper.signOut();
      window.location.href = '/auth/sign-in';
      return;
    }
    if (response && response.data) {
      const data = response.data;
      const { result, reason, detail } = data;
      if (result === 'failed') {
        if (reason) {
          errorMessage = reason;
        } else if (detail) {
          errorMessage = detail;
        }
      }
    }
    if (errorMessage) {
      ToastHelper.showError(errorMessage);
    }
    throw error;
  }
);

// MARK: --- Functions ---
async function refreshLogin() {
  const url = '/auth/refreshlogin';
  const params = {
    user_id: localStorage.getItem(PreferenceKeys.userId),
    refresh_key: localStorage.getItem(PreferenceKeys.refreshKey),
  };
  const res = await axiosClient.post(url, params, {
    headers: {
      phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
    },
  });
  const { data, errors } = res;
  if (_.isNull(errors) && !_.isNull(data)) {
    const { apiSecret, refreshKey } = data;
    localStorage.setItem(PreferenceKeys.apiKey, apiSecret);
    localStorage.setItem(PreferenceKeys.refreshKey, refreshKey);
    updateAxiosApiKey(apiSecret);
  }
}

// MARK: --- Exported functions ---
// Update base url
const updateAxiosBaseURL = (baseUrl) => {
  axiosClient.defaults.baseURL = baseUrl;
};

// Update api key
const updateAxiosApiKey = (apiKey) => {
  axiosClient.defaults.headers.common['apisecret'] = apiKey;
};

// Remove api key
const removeAxiosApiKey = () => {
  delete axiosClient.defaults.headers.common['apiSecret'];
};

// Update user id
const updateAxiosUserId = (userId) => {
  axiosClient.defaults.headers.common['userid'] = userId;
};

// Remove user id
const removeAxiosUserId = () => {
  delete axiosClient.defaults.headers.common['userid'];
};

(() => {
  const isApiKeyValid = UserHelper.checkApiKeyValid();
  if (isApiKeyValid) {
    const apiKey = localStorage.getItem(PreferenceKeys.apiKey);
    updateAxiosApiKey(apiKey);
  }

  const userId = localStorage.getItem(PreferenceKeys.userId);
  updateAxiosUserId(userId);
})();

export {
  updateAxiosBaseURL,
  updateAxiosApiKey,
  removeAxiosApiKey,
  updateAxiosUserId,
  removeAxiosUserId,
};

export default axiosClient;
