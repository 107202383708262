import accountApi from 'api/accountApi';
import authApi from 'api/authApi';
import { removeAxiosApiKey, removeAxiosUserId, updateAxiosApiKey } from 'api/axiosClient';
import loyaltyApi from 'api/loyaltyApi';
import paymentApi from 'api/paymentApi';
import transactionApi from 'api/transactionApi';
import store from 'app/store';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import {
  resetAccount,
  setLoyaltyInfo,
  setPackage,
  setPaymentHistoryList,
  setPhoneInfo,
  setProfileList,
  setRegisteredServiceList,
  setTransactionHistoryList,
  setUserInfo,
} from 'modules/Saymee/features/Account/accountSlice';
import { resetLoyalty } from 'modules/Saymee/features/Loyalty/loyaltySlice';

const UserHelper = {
  // Get display name
  getDisplayName: (user) => {
    let displayName = '';
    if (user) {
      return user.contactName ?? user.fullname ?? user.phone;
    }
    return displayName;
  },

  // Get display phone
  getDisplayPhone: (user) => {
    let displayPhone = '';
    if (user) {
      return `0${user.phone}`;
    }
    return displayPhone;
  },

  // Check api key valid
  checkApiKeyValid: () => {
    const apiKey = localStorage.getItem(PreferenceKeys.apiKey);

    if (apiKey) {
      return true;
    }

    return false;
  },

  // Sign out
  signOut: () => {
    authApi.logout();
    localStorage.removeItem(PreferenceKeys.apiKey);
    localStorage.removeItem(PreferenceKeys.userId);
    localStorage.removeItem(PreferenceKeys.savedLoginPhone);
    removeAxiosApiKey();
    removeAxiosUserId();
    store.dispatch(resetAccount());
    store.dispatch(resetLoyalty());
  },

  getAccountAvatar: () => {
    const currentAccount = store.getState().account.current;
    const avatar = currentAccount?.avatar;
    if (avatar) {
      return Utils.getFullUrl(avatar);
    } else {
      return AppResource.icons.keens.avatarBoy;
    }
  },

  // MARK: --- Request API ---
  requestCurrentAccountInfo: async () => {
    try {
      const res = await accountApi.getPhoneInfo();
      const { data } = res;
      if (data) {
        store.dispatch(setPhoneInfo(data));
      }
    } catch (error) {}
  },

  requestLoyaltyInfo: async () => {
    try {
      const res = await loyaltyApi.profile();
      const data = res?.data;
      if (data) {
        store.dispatch(setLoyaltyInfo(data));
      }
    } catch (error) {}
  },

  requestProfile: async () => {
    try {
      const res = await accountApi.getProfile();
      const data = res?.data;
      if (data) {
        store.dispatch(setProfileList(data));
      }
    } catch (error) {}
  },

  requestUserInfo: async () => {
    try {
      const res = await accountApi.getUserInfo();
      const data = res?.data;
      if (data) {
        store.dispatch(setUserInfo(data));
      }
    } catch (error) {}
  },

  requestPackageAndData: async () => {
    try {
      const res = await accountApi.getPackageAndData();
      const packageList = res?.package;
      if (packageList && _.isArray(packageList) && packageList.length > 0) {
        store.dispatch(setPackage(packageList[0]));
      }
    } catch (error) {}
  },

  requestRegisteredServiceList: async () => {
    try {
      const res = await accountApi.getRegisteredServiceList();
      const data = res?.data;
      store.dispatch(setRegisteredServiceList(data));
    } catch (error) {}
  },

  // Lay ds lich su thanh toan
  requestPaymentHistoryList: async () => {
    try {
      const res = await paymentApi.getPaymentHistory();
      const data = res?.data;
      store.dispatch(setPaymentHistoryList(data));
    } catch (error) {}
  },

  // Lay ds lich su giao dich
  requestTransactionHistoryList: async () => {
    try {
      const res = await transactionApi.getTransactionHistory();
      const data = res?.data;
      store.dispatch(setTransactionHistoryList(data));
    } catch (error) {}
  },

  /**
   * Lay lai api token
   */
  requestRefreshLogin: async () => {
    try {
      const res = await authApi.refreshLogin(
        localStorage.getItem(PreferenceKeys.userId),
        localStorage.getItem(PreferenceKeys.refreshKey)
      );
      const { data, errors } = res;
      if (_.isNull(errors) && !_.isNull(data)) {
        const { apiSecret, refreshKey } = data;
        localStorage.setItem(PreferenceKeys.apiKey, apiSecret);
        localStorage.setItem(PreferenceKeys.refreshKey, refreshKey);
        updateAxiosApiKey(apiSecret);
      }
    } catch (error) {
      console.log(`request refresh login error: ${error?.message}`);
    }
  },
};

export default UserHelper;
