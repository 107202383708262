import { Navigate, Route, Routes } from 'react-router-dom';
import Loyalty from '../Loyalty';
import AccountInfoScreen from './screens/AccountInfoScreen';
import FeeCheckAndHistoryScreen from './screens/FeeCheckAndHistoryScreen';
import HelpDownloadScreen from './screens/HelpDownloadScreen';
import HistoryRechargeMoneyScreen from './screens/HistoryRechargeMoneyScreen';
import TransactionDetailScreen from './screens/TransactionDetailScreen';
import UpdateInfoScreen from './screens/UpdateInfoScreen';
import UtilitiesScreen from './screens/UtilitiesScreen';

Account.propTypes = {};

function Account(props) {
  return (
    <Routes>
      <Route path="" element={<Navigate to="info" />} />
      <Route path="info" element={<AccountInfoScreen />} />
      <Route path="utilities" element={<UtilitiesScreen />} />
      <Route path="fee-check" element={<FeeCheckAndHistoryScreen />} />
      <Route path="help-download-app" element={<HelpDownloadScreen />} />
      <Route path="history-recharge-money" element={<HistoryRechargeMoneyScreen />} />
      <Route path="transaction-detail/:type" element={<TransactionDetailScreen />} />
      <Route path="update-info" element={<UpdateInfoScreen />} />
      <Route path="transaction-detail/*" element={<Navigate to="/saymee/account/fee-check" />} />
      <Route path="loyalty/*" element={<Loyalty />} />
    </Routes>
  );
}

export default Account;
