import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Utils from 'general/utils/Utils';
import AppResource from 'general/constants/AppResource';

CellTransactionDetailList.propTypes = {
  title: PropTypes.string,
  volume: PropTypes.number,
  charge: PropTypes.number,
  time: PropTypes.string,
  icon: PropTypes.any,
};

CellTransactionDetailList.defaultProps = {
  title: '',
  volume: 0,
  charge: 0,
  time: '',
  icon: null,
};

/**
 *
 * @param {{
 * title: string,
 * volume: number,
 * charge: number,
 * time: string,
 * icon: any,
 * }} props
 * @returns
 */
function CellTransactionDetailList(props) {
  // MARK: --- Params ---
  const { title, volume, charge, time, icon } = props;

  return (
    <div
      className="bg-white d-flex mb-6 px-6 py-4 align-items-center justify-content-between"
      style={{
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
        borderRadius: '12px',
      }}
    >
      <div className="d-flex align-items-center">
        {icon && <img src={icon} className="mr-3" />}
        <div>
          <p className="mb-1" style={{ fontSize: '1.4rem', fontWeight: 400, color: '#262224' }}>
            {title}
          </p>
          <p className="mb-0" style={{ color: '#A8A8A8', fontSize: '1rem' }}>
            {time}
          </p>
        </div>
      </div>
      <span className="text-remaining font-weight-boldest" style={{ fontSize: '1.43rem' }}>
        {`${Utils.formatNumber(charge)} đ`}
      </span>
    </div>
  );
}

export default CellTransactionDetailList;
