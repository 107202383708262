import AppResource from 'general/constants/AppResource';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';

SearchField.propTypes = {
  value: PropTypes.string,
  searchFieldId: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
};

SearchField.defaultProps = {
  placeholder: '',
  onSubmit: null,
  onFocus: null,
  onBlur: null,
  value: '',
  onChange: null,
};

/**
 *
 * @param {{
 * searchFieldId: string,
 * placeholder: string,
 * onSubmit: function,
 * onFocus: function,
 * onBlur: function,
 * value: string,
 * onChange: function,
 * }} props
 * @returns
 */
function SearchField(props) {
  // MARK: --- Params ---
  const { searchFieldId, placeholder, onSubmit, onFocus, onBlur, value, onChange } = props;
  const [text, setText] = useState('');
  const refTypingTimeout = useRef(null);
  const [focus, setFocus] = useState(false);
  const refSearchText = useRef('');

  // MARK: --- Functions ---
  function handleChange(newValue) {
    if (onChange) {
      onChange(newValue);
    }
    refSearchText.current = newValue;
    // if (!onSubmit) {
    //   return;
    // }
    // if (refTypingTimeout.current) {
    //   clearTimeout(refTypingTimeout.current);
    // }
    // refTypingTimeout.current = setTimeout(() => {
    //   onSubmit(value);
    // }, 500);
  }

  function handleKeyboardPress(event) {
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Enter') {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      if (onSubmit) {
        onSubmit(refSearchText.current);
      }
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    const searchField = document.getElementById(searchFieldId);
    searchField?.addEventListener('keypress', handleKeyboardPress);

    return () => {
      searchField?.removeEventListener('keypress', handleKeyboardPress);
    };
  }, []);

  return (
    <div
      className={`border px-3 py-2 d-flex align-items-center ${focus ? 'saymee-border-focus' : ''}`}
      style={{
        borderRadius: 12,
      }}
    >
      <img src={AppResource.icons.icSearch} className="mr-2" />
      <input
        id={searchFieldId}
        className="saymee-none-outline border-0"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          const value = e?.target?.value;
          handleChange(value);
        }}
        onFocus={() => {
          setFocus(true);
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          setFocus(false);
          if (onBlur) {
            onBlur();
          }
        }}
      />
      {value.length > 0 && (
        <i
          className="fal fa-times cursor-pointer text-hover-dark"
          onClick={() => {
            handleChange('');
          }}
        />
      )}
    </div>
  );
}

export default SearchField;
