import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './style.scss';

SaymeeMonthOrYearPicker.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  yearList: PropTypes.array,
  onChooseValue: PropTypes.func,
  value: PropTypes.number,
};

SaymeeMonthOrYearPicker.defaultProps = {
  type: 'year',
  className: '',
  style: {},
  yearList: [],
  onChooseValue: null,
  value: 0,
};

function SaymeeMonthOrYearPicker(props) {
  // MARK --- Params: ---
  const { type, className, style, yearList, value, onChooseValue } = props;
  const { t } = useTranslation();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  // const currentYear = new Date().getFullYear();
  // const yearRange = [currentYear - 10, currentYear + 10];
  // const years = [];
  // for (let i = yearRange[0]; i <= yearRange[1]; i++) {
  //   years.push(i);
  // }
  // const [value, setValue] = useState(() => {
  //   if (initialValue === 0) {
  //     return type === 'year' ? t('ChooseYear') : t('ChooseMonth');
  //   }
  //   return initialValue;
  // });
  const dates = type === 'year' ? yearList : months;

  // MARK --- Functions: ---
  const handleListClick = (date) => {
    if (onChooseValue) {
      onChooseValue(date);
    }
  };

  // useEffect(() => {
  //   if (initialValue > 0) {
  //     setValue(initialValue);
  //   }
  // }, [initialValue]);

  return (
    <div className={`SaymeeMonthOrYearPicker ${className}`} style={style}>
      <Dropdown>
        <Dropdown.Toggle
          className="w-100 d-flex justify-content-between align-items-center"
          variant=""
        >
          {type === 'year' || isNaN(value) ? value : `${t('Month')} ${value}`}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100 py-4">
          <div className="overflow-auto max-h-200px w-100">
            {dates.map((item, index) => (
              <Dropdown.Item onClick={() => handleListClick(item)} key={item}>
                {type === 'year' ? item : `${t('Month')} ${item}`}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default SaymeeMonthOrYearPicker;
