import store from 'app/store';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';

const LoyaltyHelper = {
  /**
   * Lay avatar theo diem
   * @param {number} point Diem loyalty
   */
  getAvatarByPoint: (point) => {
    let avatar = null;
    const loyaltyLevelList = store.getState().loyalty.loyaltyLevelList;
    if (loyaltyLevelList) {
      const foundLoyaltyLevels = loyaltyLevelList.filter((item) =>
        _.inRange(point, item.fromPoint, item.toPoint)
      );
      if (foundLoyaltyLevels && foundLoyaltyLevels.length > 0) {
        const avatarIndex = _.findIndex(AppData.loyaltyLevelItems, function (item) {
          return (
            item.fromPoint <= foundLoyaltyLevels[0].fromPoint &&
            item.toPoint >= foundLoyaltyLevels[0].toPoint
          );
        });
        if (_.inRange(avatarIndex, 0, AppData.loyaltyLevelAvatars.length)) {
          return AppData.loyaltyLevelAvatars[avatarIndex];
        }
      }
    }

    return avatar;
  },

  /**
   * Lay level tiep theo theo diem
   * @param {number} point Diem loyalty
   */
  getNextLevelByPoint: (point) => {
    const loyaltyLevelList = store.getState().loyalty.loyaltyLevelList;
    if (loyaltyLevelList) {
      const foundLoyaltyLevels = loyaltyLevelList.filter((item) =>
        _.inRange(point, item.fromPoint, item.toPoint)
      );
      if (foundLoyaltyLevels && foundLoyaltyLevels.length > 0) {
        const index = _.findIndex(AppData.loyaltyLevelItems, function (item) {
          return (
            item.fromPoint === foundLoyaltyLevels[0].fromPoint &&
            item.toPoint === foundLoyaltyLevels[0].toPoint
          );
        });
        let nextIndex = index + 1;
        if (nextIndex > AppData.loyaltyLevelItems.length - 1) {
          // nextIndex = AppData.loyaltyLevelItems.length - 1;
          return {
            loyalty: null,
            avatar: null,
          };
        }
        return {
          loyalty: loyaltyLevelList[nextIndex],
          avatar: AppData.loyaltyLevelAvatars[nextIndex],
        };
      }
    }
    return null;
  },

  /**
   * Lay anh check in theo ma tham chieu
   * @param {string} referenceId Ma tham chieu
   */
  getCheckInStatusAvatarByReferenceId: (referenceId) => {
    switch (referenceId) {
      case AppData.loyaltyCheckInStatusReference.angry:
        return AppResource.icons.icCheckInStatusNau;
      case AppData.loyaltyCheckInStatusReference.happy:
        return AppResource.icons.icCheckInStatusVui;
      case AppData.loyaltyCheckInStatusReference.sad:
        return AppResource.icons.icCheckInStatusBuon;
      case AppData.loyaltyCheckInStatusReference.ukila:
        return AppResource.icons.icCheckInStatusOk;
      default:
        return null;
    }
  },
};

export default LoyaltyHelper;
