import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

SaymeeFooter.propTypes = {};

const footerMenuList = [
  {
    sectionTitle: 'Introduce',
    items: [
      { title: 'IntroduceSaymee', url: '#' },
      { title: 'Recruit', url: '#' },
    ],
  },
  {
    sectionTitle: 'MobileService',
    items: [
      { title: 'DataPackages', url: '#' },
      { title: 'BuySimSaymee', url: '#' },
      { title: 'SwitchToPostpaid', url: '#' },
    ],
  },
  {
    sectionTitle: 'CustomerSupport',
    items: [
      { title: 'FAQs', url: '#' },
      { title: 'ContactHotline', url: '#' },
      { title: 'SendFeedback', url: '#' },
      { title: 'ReportServiceError', url: '#' },
    ],
  },
  {
    sectionTitle: 'TermsAndPrivacy',
    items: [
      { title: 'TermsOfUse', url: '#' },
      { title: 'InformationSecurity', url: '#' },
    ],
  },
];

function SaymeeFooter(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();

  return (
    <div className="footer">
      {/* footer menu */}
      <div
        className=""
        style={{
          background:
            'linear-gradient(90.24deg, rgba(25, 211, 197, 0.12) 4.63%, rgba(0, 113, 206, 0.12) 95.78%)',
        }}
      >
        <div className="container pt-10 pb-15">
          <div className="row">
            <div className="col-lg-2 col-sm-3">
              <div>
                <p
                  className=""
                  style={{
                    color: AppResource.colors.darkGrey,
                    fontWeight: 700,
                    fontSize: '1.1rem',
                  }}
                >
                  {t('ConnectWithSaymee')}
                </p>
                <div className="mb-4">
                  <a href="#" className="mr-6">
                    <i
                      className="fab fa-facebook-square fa-2x text-hover-primary"
                      style={{
                        color: AppResource.colors.darkGrey,
                      }}
                    />
                  </a>
                  <a href="#" className="">
                    <i
                      className="fab fa-youtube fa-2x text-hover-danger"
                      style={{
                        color: AppResource.colors.darkGrey,
                      }}
                    />
                  </a>
                </div>
                <img src={AppResource.images.imgCheckedGov} />
              </div>
            </div>

            <div className="col d-flex flex-wrap mt-6 mt-sm-0">
              {footerMenuList.map((item, index) => {
                return (
                  <div key={index} className="px-6 pb-6">
                    <p
                      className=""
                      style={{
                        color: AppResource.colors.darkGrey,
                        fontWeight: 700,
                        fontSize: '1.1rem',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      {t(item.sectionTitle)}
                    </p>
                    <div className="d-flex flex-column">
                      {item.items.map((subItem, subIndex) => {
                        return (
                          <a
                            href={subItem.url}
                            key={subIndex}
                            className="saymee-link mb-2"
                            style={{
                              color: AppResource.colors.darkGrey,
                              fontWeight: 400,
                              fontSize: '1rem',
                              whiteSpace: 'nowrap',
                            }}
                          >
                            {t(subItem.title)}
                          </a>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* copyright */}
      <div
        className="py-4 d-flex flex-lg-column"
        style={{
          backgroundColor: AppResource.colors.feature,
        }}
      >
        <div className="container d-flex flex-column flex-md-row align-items-center justify-content-center">
          <div className="text-white order-2 order-md-1">
            <span className="text-white font-weight-bold mr-2">©</span>
            <a className="text-white text-hover-muted" target="_blank" href="#">
              {t('CopyrightText')}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaymeeFooter;
