import React from 'react';
import PropTypes from 'prop-types';
import SaymeeHeader from 'general/components/Headers/SaymeeHeader';
import SaymeeContent from 'general/components/Contents/SaymeeContent';
import SaymeeFooter from 'general/components/Footers/SaymeeFooter';

SaymeeBaseLayout.propTypes = {};

function SaymeeBaseLayout(props) {
  return (
    <div>
      <div className="d-flex flex-column flex-root min-vh-100">
        <div className="d-flex flex-row flex-column-fluid page">
          <div id="kt_wrapper" className="d-flex flex-column flex-row-fluid wrapper">
            {/* Header */}
            <SaymeeHeader />

            {/* Content */}
            <SaymeeContent>{props.children}</SaymeeContent>

            {/* Footer */}
            <SaymeeFooter />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaymeeBaseLayout;
