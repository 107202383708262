import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const paymentApi = {
  /**
   * Lay lich su thanh toan
   * @returns
   */
  getPaymentHistory: () => {
    const url = '/payment/get-payment-history';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },
};

export default paymentApi;
