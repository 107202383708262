import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import useAccount from 'hooks/useAccount';
import CellGiftGrid from '../../components/CellGiftGrid';
import ModalVoucherDetail from '../../components/ModalVoucherDetail';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';

MyVoucherScreen.propTypes = {};

function MyVoucherScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { myGiftList } = useAccount();
  const [modalVoucherDetailShowing, setModalVoucherDetailShowing] = useState(false);
  const [selectedGift, setSelectedGift] = useState(null);

  return (
    <div className="pb-10">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account', active: false },
          { title: t('Loyalty'), url: '/saymee/account/loyalty', active: false },
          { title: t('YourVouchers'), url: '#', active: true },
        ]}
      />

      {/* danh sach vouchers */}
      <div className="mt-6">
        {myGiftList?.length > 0 ? (
          <div className="row">
            {myGiftList?.map((item, index) => {
              return (
                <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-6">
                  <CellGiftGrid
                    giftObject={item?.voucher}
                    expireTime={item?.gift?.expired}
                    onPress={() => {
                      setSelectedGift(item);
                      setModalVoucherDetailShowing(true);
                    }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <SaymeeEmpty message={t('YouDoNotHaveAnyVouchers')} />
          </div>
        )}
      </div>

      {/* Modal voucher detail */}
      <ModalVoucherDetail
        show={modalVoucherDetailShowing}
        onClose={() => {
          setModalVoucherDetailShowing(false);
        }}
        voucherItem={selectedGift?.voucher}
        giftItem={selectedGift?.gift}
      />
    </div>
  );
}

export default MyVoucherScreen;
