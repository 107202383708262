import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import LogHelper from 'general/helpers/LogHelper';
import { thunkGetConfigs, thunkGetHeroscopeList } from 'app/appSlice';

DataCommonListener.propTypes = {};

const sTag = '[DataCommonListener]';

function DataCommonListener(props) {
  // MARK: --- Params ---
  const dispatch = useDispatch();

  // MARK: --- Hooks ---
  useEffect(() => {
    dispatch(thunkGetHeroscopeList());
    dispatch(thunkGetConfigs());

    return () => {
      // LogHelper.log(`${sTag} will dismiss`);
    };
  }, []);

  return <></>;
}

export default DataCommonListener;
