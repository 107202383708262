import store from 'app/store';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import _ from 'lodash';
import { Navigate } from 'react-router-dom';

// Route yeu cau phai dang nhap
// Neu chua dang nhap nhay ve man hinh dang nhap '/sign-in'
function PrivateRoute(props) {
  // MARK: --- Params ---
  //   const currentAccount = store.getState().account.current;
  //   const isAuth = !_.isNull(currentAccount);
  const isAuth = !!localStorage.getItem(PreferenceKeys.apiKey);

  return isAuth ? props.children : <Navigate to="/auth/sign-in" />;
}

export default PrivateRoute;
