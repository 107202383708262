import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Utils from 'general/utils/Utils';
import { useTranslation } from 'react-i18next';

function useLoyalty(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const pointHistoryList = useSelector((state) => state.loyalty.pointHistoryList);
  const categoryList = useSelector((state) => state.loyalty.categoryList);
  const giftList = useSelector((state) => state.loyalty.giftList);
  const formattedPointHistoryList = pointHistoryList?.map((item) => {
    return {
      ...item,
      monthYear: moment(item.time).format('MM/YYYY'),
      textTime: moment(item.time).format('HH:mm - DD/MM/YYYY'),
      textPoint:
        item.point > 0
          ? `+${Utils.formatNumber(item.point)}`
          : `-${Utils.formatNumber(Math.abs(item.point))}`,
      pointColor: item.point > 0 ? '#17BEB1' : '#E31D93',
    };
  });

  const voucherCategoryList = [
    {
      name: t('All'),
      value: '',
    },
    ...categoryList?.map((item) => {
      return {
        name: item?.title,
        value: item?.catId,
      };
    }),
  ];

  return useMemo(() => {
    return {
      // lich su diem loyalty
      pointHistoryList: formattedPointHistoryList,
      // ds the loai voucher
      voucherCategoryList: voucherCategoryList,
      // ds qua
      giftList: giftList,
    };
  }, [pointHistoryList, categoryList, giftList]);
}

export default useLoyalty;
