import axios from 'axios';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Global from 'general/utils/Global';
import axiosClient from './axiosClient';

const loyaltyApi = {
  profile: () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/profile`;
    return axios.get(url, {
      headers: {
        apisecret: localStorage.getItem(PreferenceKeys.apiKey),
        userid: localStorage.getItem(PreferenceKeys.userId),
      },
    });
  },

  /**
   * Lay danh sach levels
   * @returns
   */
  getLevelList: () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/levels`;
    return axios.get(url, {
      headers: {
        apisecret: localStorage.getItem(PreferenceKeys.apiKey),
        userid: localStorage.getItem(PreferenceKeys.userId),
      },
    });
  },

  /**
   * Lay danh sach the loai qua
   */
  getCategoryList: () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/categories`;
    return axios.get(url, {
      headers: {
        apisecret: localStorage.getItem(PreferenceKeys.apiKey),
        userid: localStorage.getItem(PreferenceKeys.userId),
      },
    });
  },

  /**
   * Lay ds qua da doi
   * @returns
   */
  getMyGifts: () => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/myGifts`;
    return axiosClient.get(
      url,
      {},
      {
        headers: {
          apisecret: localStorage.getItem(PreferenceKeys.apiKey),
          userid: localStorage.getItem(PreferenceKeys.userId),
        },
      }
    );
  },

  /**
   * Lay danh sach qua
   * @param {number} page Trang
   * @param {number} limit So phan tu tra ve
   * @param {string} title Tu khoa tim kiem
   * @param {number} catId Id the loai
   */
  getGiftList: (page = 0, limit = Global.g_defaultPaginationLimit, title = '', catId = null) => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/gifts`;
    let params = {
      page,
      limit,
      title,
    };
    if (!_.isNull(catId)) {
      params['catId'] = catId;
    }
    return axios.get(
      url,
      {
        params,
      },
      {
        headers: {
          apisecret: localStorage.getItem(PreferenceKeys.apiKey),
          userid: localStorage.getItem(PreferenceKeys.userId),
        },
      }
    );
  },

  /**
   * Diem danh hang ngay nhan diem
   * @param {string} status Trang thai
   */
  loyaltyCheckin: (status) => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/checkin`;
    let params = { status };
    return axios.post(url, params, {
      headers: {
        apisecret: localStorage.getItem(PreferenceKeys.apiKey),
        userid: localStorage.getItem(PreferenceKeys.userId),
      },
    });
  },

  /**
   * Lat bai nhan diem
   */
  loyaltyLatBai: (status) => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/latbai`;
    let params = { status };
    return axios.post(
      url,
      {},
      {
        headers: {
          apisecret: localStorage.getItem(PreferenceKeys.apiKey),
          userid: localStorage.getItem(PreferenceKeys.userId),
        },
      }
    );
  },
};

export default loyaltyApi;
