import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import LoyaltyHelper from 'general/helpers/LoyaltyHelper';
import PaymentHelper from 'general/helpers/PaymentHelper';
import StringUtils from 'general/utils/StringUtils';
import Utils from 'general/utils/Utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useAccount() {
  // MARK: --- Params ---
  const currentAccount = useSelector((state) => state.account.phoneInfo);
  const currentUserInfo = useSelector((state) => state.account.userInfo);
  const currentLoyaltyInfo = useSelector((state) => state.account.loyaltyInfo);
  const currentProfileList = useSelector((state) => state.account.profileList);
  const currentRegisteredServiceList = useSelector((state) => state.account.registeredServiceList);
  const paymentHistoryList = useSelector((state) => state.account.paymentHistoryList);
  const transactionHistoryList = useSelector((state) => state.account.transactionHistoryList);
  const myGiftList = useSelector((state) => state.loyalty.myGiftList);

  return useMemo(() => {
    // ten hien thi
    const displayName =
      currentAccount?.fullname ?? currentAccount?.name ?? currentUserInfo?.name ?? '';
    // sdt
    const phone = StringUtils.phoneFormat(`0${currentAccount?.phone}`);
    // trang thai dang nhap
    const isLoggedIn = !_.isNull(currentAccount);
    // tai khoan chinh
    const mainBalance = _.filter(currentProfileList, (item) => item.type === 1).reduce(
      (sum, number) => {
        return sum + parseInt(number?.balance);
      },
      0
    );
    const mainExpireDateList = _.chain(currentProfileList)
      .filter((item) => item.type === 1)
      .sort(function (a, b) {
        return moment(a?.expireDate, 'DD/MM/YYYY').isAfter(moment(b?.expireDate, 'DD/MM/YYYY'));
      })
      .value();
    const mainExpireDate = mainExpireDateList?.length > 0 ? mainExpireDateList[0].expireDate : '';

    // tai khoan khuyen mai
    const promotionalBalance = _.filter(currentProfileList, (item) => item.type !== 1).reduce(
      (sum, number) => {
        return sum + parseInt(number?.balance);
      },
      0
    );
    const promotionalExpireDate = '';

    // ds dich vu da dang ky
    const mappedRegisteredServiceList = currentRegisteredServiceList?.map((item) => {
      // console.log({ item });
      return {
        serviceId: item?.serviceId,
        title: item?.title,
        price: item?.price,
        expiredDate: Utils.formatDateTime(item?.expireTime, 'DD/MM/YYYY'),
        backgroundColor: item?.backgroundColor,
        textColor: item?.textColor ?? AppResource.colors.textPrimary,
        descriptionList: [
          /*item?.description*/
        ], //['Spotify không quảng cáo', 'Không giới hạn số lần nghe, chọn bài'],
        // iconText: '⚽️ 🏀 ⛳️ 🥊',
        icon: item?.thumb ?? AppResource.icons.icServiceMusic,
      };
    });

    // lich su nap tien
    const groupedPaymentHistoryList = PaymentHelper.groupPaymentHistoryList(paymentHistoryList);

    // lich su giao dich
    const transactionHistoryBillCycleYearList = _.chain(
      transactionHistoryList?.map((item) => {
        return moment(item.billCycle).get('year');
      })
    )
      .uniq()
      .sort(function (a, b) {
        return a - b;
      })
      .value();
    const transactionHistoryBillCycleMonthList = _.chain(
      transactionHistoryList?.map((item) => {
        return moment(item.billCycle).get('month') + 1;
      })
    )
      .uniq()
      .sort(function (a, b) {
        return a - b;
      })
      .value();
    const nextLoyaltyItem = LoyaltyHelper.getNextLevelByPoint(
      currentLoyaltyInfo?.basicProfile?.loyalty?.point ?? 0
    );

    let todayCheckInStatus = null;
    const today = moment().format('YYYY-MM-DD');
    const foundCheckInStatuses = _.find(
      currentLoyaltyInfo?.checkinStatuses,
      (item) => moment(item?.time).format('YYYY-MM-DD') === today
    );
    if (!!foundCheckInStatuses) {
      todayCheckInStatus = foundCheckInStatuses;
    }

    // ds qua da doi
    const allMyGiftList = [];
    myGiftList?.forEach((item) => {
      item?.gifts?.forEach((gift) => {
        allMyGiftList.push({
          gift: gift,
          voucher: item?.gift,
        });
      });
    });

    return {
      isLoggedIn,
      displayName,
      phone,
      currentAccount,
      registeredServiceList: mappedRegisteredServiceList,
      checkInStatuses: currentLoyaltyInfo?.checkinStatuses,
      todayCheckInStatus,
      currentLoyalty: {
        name: currentLoyaltyInfo?.curentLevel?.name,
        point: currentLoyaltyInfo?.basicProfile?.loyalty?.point ?? 0,
        avatar: LoyaltyHelper.getAvatarByPoint(
          currentLoyaltyInfo?.basicProfile?.loyalty?.point ?? 0
        ),
      },
      nextLoyalty: {
        name: nextLoyaltyItem?.loyalty?.name,
        point: nextLoyaltyItem?.loyalty?.fromPoint,
        avatar: nextLoyaltyItem?.avatar,
      },
      ballance: {
        main: `${Utils.formatNumber(mainBalance)} đ`,
        mainExpireDate,
        promotional: `${Utils.formatNumber(promotionalBalance)} đ`,
        promotionalExpireDate,
      },
      packageInfo: {
        data: {
          total: 0,
          remaining: 0,
        },
        call: {
          total: 0,
          remaining: 0,
        },
        sms: {
          total: 0,
          remaining: 0,
        },
      },
      paymentHistory: groupedPaymentHistoryList,
      transactionHistoryList: _.orderBy(transactionHistoryList, ['billCycle'], ['asc']),
      transactionHistoryMonthList: transactionHistoryBillCycleMonthList,
      transactionHistoryYearList: transactionHistoryBillCycleYearList,
      myGiftList: allMyGiftList,
    };
  }, [
    currentAccount,
    currentLoyaltyInfo,
    currentUserInfo,
    currentProfileList,
    currentRegisteredServiceList,
    paymentHistoryList,
    transactionHistoryList,
    myGiftList,
  ]);
}

export default useAccount;
