import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import useAccount from 'hooks/useAccount';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import _ from 'lodash';
import AppResource from 'general/constants/AppResource';
import useScrollToTop from 'hooks/useScrollToTop';

UpdateInfoScreen.propTypes = {};

function UpdateInfoScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { displayName, phone, currentAccount } = useAccount();

  useScrollToTop();

  return (
    <div>
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account/info', active: false },
          { title: t('UpdateInfo'), url: '#', active: true },
        ]}
      />

      <div className="d-flex flex-column align-items-center">
        <div className="w-100 w-md-50 mt-4 mt-md-6 px-6 px-md-0">
          <p
            className="mb-0 text-center"
            style={{
              fontWeight: 700,
              fontSize: '1.8rem',
              color: '##262224',
            }}
          >
            {t('AccountInfo')}
          </p>
          <div className="mt-4">
            {/* fullname */}
            <KTFormGroup
              label={t('Fullname')}
              inputName="fullname"
              inputElement={
                <KTFormInput
                  name="fullname"
                  value={displayName}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('Fullname'))}`}
                  readonly
                />
              }
            />

            {/* phone */}
            <KTFormGroup
              label={t('Phone')}
              inputName="phone"
              inputElement={
                <KTFormInput
                  name="phone"
                  value={`mee.id ${phone}`}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('Phone'))}`}
                  readonly
                />
              }
            />

            {/* dob */}
            <KTFormGroup
              label={t('Dob')}
              inputName="dob"
              inputElement={
                <KTFormInput
                  name="dob"
                  value={currentAccount?.birthDate}
                  type={KTFormInputType.password}
                  placeholder={`${_.capitalize(t('Dob'))}`}
                  readonly
                  showEye={false}
                />
              }
            />

            {/* gender */}
            <KTFormGroup
              label={t('Gender')}
              inputName="gender"
              inputElement={
                <KTFormInput
                  name="gender"
                  value={currentAccount?.genderText}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('Gender'))}`}
                  readonly
                />
              }
            />

            {/* nationnality */}
            <KTFormGroup
              label={t('Nationnality')}
              inputName="nationnality"
              inputElement={
                <KTFormInput
                  name="nationnality"
                  value={currentAccount?.nationality}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('Nationnality'))}`}
                  readonly
                />
              }
            />

            {/* IdNumber */}
            <KTFormGroup
              label={t('IdNumber')}
              inputName="idNumber"
              inputElement={
                <KTFormInput
                  name="idNumber"
                  value={currentAccount?.idNumber}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('IdNumber'))}`}
                  readonly
                />
              }
            />

            {/* startDate */}
            <KTFormGroup
              label={t('StartDate')}
              inputName="startDate"
              inputElement={
                <KTFormInput
                  name="startDate"
                  value={currentAccount?.startDateTime}
                  type={KTFormInputType.text}
                  placeholder={`${_.capitalize(t('StartDate'))}`}
                  readonly
                />
              }
            />
          </div>
          <p
            style={{
              fontSize: '12px',
              textAlign: 'center',
              color: AppResource.colors.red,
              fontStyle: 'italic',
            }}
          >
            Để cập nhật thông tin bạn vui lòng mở app Saymee trên điện thoại.
          </p>
        </div>
      </div>
    </div>
  );
}

export default UpdateInfoScreen;
