import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import commonApi from 'api/commonApi';

// MARK: --- Thunks ---
// Lay ds cung hoang dao
export const thunkGetHeroscopeList = createAsyncThunk(
  'app/getHeroscopeList',
  async (params, thunkAPI) => {
    const res = await commonApi.getHeroscope();
    return res?.data;
  }
);

// Lay configs
export const thunkGetConfigs = createAsyncThunk('app/getConfigs', async (params, thunkAPI) => {
  const res = await commonApi.getConfigs();
  return res?.data;
});

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    breadcrumbItems: [
      { title: 'Trang chủ', url: '#', active: false },
      { title: 'Đăng nhập', url: '#', active: true },
    ],

    heroscopeList: null,
    configData: null,
    flagSearchHistoryChange: {},
  },
  reducers: {
    setBreadcrumbItems: (state, action) => {
      state.breadcrumbItems = action.payload;
    },
    setFlagSearchHistoryChange: (state, action) => {
      state.flagSearchHistoryChange = action.payload;
    },
  },
  extraReducers: {
    // Lay danh sach 12 cung hoang dao
    [thunkGetHeroscopeList.fulfilled]: (state, action) => {
      const data = action.payload;
      if (data) {
        state.heroscopeList = data;
      }
    },

    // Lay configs
    [thunkGetConfigs.fulfilled]: (state, action) => {
      const data = action.payload;
      if (data) {
        state.configData = data;
      }
    },
  },
});

const { reducer, actions } = appSlice;
export const { setBreadcrumbItems, setFlagSearchHistoryChange } = actions;
export default reducer;
