import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';

CellVoucherAddressList.propTypes = {
  address: PropTypes.string,
  onPress: PropTypes.func,
};

CellVoucherAddressList.defaultProps = {
  address: '',
  onPress: null,
};

function CellVoucherAddressList(props) {
  // MARK: --- Params ---
  const { address, onPress } = props;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="bg-white px-6 py-4 d-flex align-items-center cursor-pointer hover-opacity-60"
      onClick={handlePress}
      style={{
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
        borderRadius: '12px',
      }}
    >
      <img src={AppResource.icons.icLocation} />
      <div className="d-flex align-items-center justify-content-between flex-grow-1">
        <p className="mb-0 text-dark ml-2" style={{}}>
          {address}
        </p>
        <img src={AppResource.icons.icArrowRight} />
      </div>
    </div>
  );
}

export default CellVoucherAddressList;
