import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';

SaymeeAvatar.propTypes = {
  backgroundColor: PropTypes.string,
  icon: PropTypes.any,
  additionalClassName: PropTypes.string,
  additionalStyle: PropTypes.object,
  onPress: PropTypes.func,
};

SaymeeAvatar.defaultProps = {
  backgroundColor: AppResource.colors.primary,
  icon: null,
  additionalClassName: '',
  additionalStyle: {},
  onPress: null,
};

function SaymeeAvatar(props) {
  // MARK: --- Params ---
  const { backgroundColor, icon, additionalClassName, additionalStyle, onPress } = props;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className={`w-40px h-40px overflow-hidden ${additionalClassName} ${
        onPress ? 'cursor-pointer hover-opacity-80' : ''
      }`}
      style={{
        backgroundColor: backgroundColor,
        ...additionalStyle,
        borderRadius: 'calc(50%)',
      }}
      onClick={handlePress}
    >
      {icon && <img className="w-100 h-100" style={{ objectFit: 'cover' }} src={icon} />}
    </div>
  );
}

export default SaymeeAvatar;
