import loyaltyApi from 'api/loyaltyApi';
import transactionApi from 'api/transactionApi';
import Global from 'general/utils/Global';

const { createSlice, createAsyncThunk } = require('@reduxjs/toolkit');

// Thunk
// Thunk get loyalty level list
export const thunkGetLoyaltyLevelList = createAsyncThunk(
  'loyalty/getLevelList',
  async (params, thunkAPI) => {
    const res = await loyaltyApi.getLevelList();
    return res?.data;
  }
);

// Thunk get points history
export const thunkGetPointHistory = createAsyncThunk(
  'loyalty/getPointList',
  async (params, thunkAPI) => {
    const res = await transactionApi.getLoyaltyTransactions(
      params?.type,
      params?.fromDate,
      params?.toDate
    );
    return res;
  }
);

// Thunk get category list
export const thunkGetLoyaltyCategoryList = createAsyncThunk(
  'loyalty/getLoyaltyCategoryList',
  async (params, thunkAPI) => {
    const res = await loyaltyApi.getCategoryList();
    return res?.data;
  }
);

// Thunk get gift list
export const thunkGetGiftList = createAsyncThunk(
  'loyalty/getLoyaltyGiftList',
  async (params, thunkAPI) => {
    const res = await loyaltyApi.getGiftList(
      params?.page,
      params?.limit,
      params?.title,
      params?.catId
    );
    return res?.data;
  }
);

// Thunk get my gift list
export const thunkGetMyGiftList = createAsyncThunk(
  'loyalty/getLoyaltyMyGiftList',
  async (params, thunkAPI) => {
    const res = await loyaltyApi.getMyGifts();
    return res;
  }
);

const loyaltySlice = createSlice({
  name: 'loyalty',
  initialState: {
    loyaltyLevelList: [],
    pointHistoryList: [],

    categoryList: [],
    giftList: [],
    loadingGiftList: false,

    loadingMyGiftList: false,
    myGiftList: [],
  },
  reducers: {
    resetLoyalty: (state, action) => {
      state.myGiftList = [];
    },
  },
  extraReducers: {
    // Lay danh sach level
    [thunkGetLoyaltyLevelList.fulfilled]: (state, action) => {
      const { result, levels } = action.payload;
      if (result === 'success' && levels) {
        state.loyaltyLevelList = levels;
      }
    },

    // Lay lich su diem
    [thunkGetPointHistory.fulfilled]: (state, action) => {
      const { result, transactions } = action.payload;
      if (result === 'success' && transactions) {
        state.pointHistoryList = transactions;
      }
    },

    // Lay ds the loai
    [thunkGetLoyaltyCategoryList.fulfilled]: (state, action) => {
      const { result, categories } = action.payload;
      if (result === 'success' && categories) {
        state.categoryList = categories;
      }
    },

    // Lay ds qua
    [thunkGetGiftList.pending]: (state, action) => {
      state.loadingGiftList = true;
    },
    [thunkGetGiftList.rejected]: (state, action) => {
      state.loadingGiftList = false;
    },
    [thunkGetGiftList.fulfilled]: (state, action) => {
      state.loadingGiftList = false;
      const { result, gifts, total } = action.payload;
      if (result === 'success' && gifts) {
        if (Global.g_filterVoucherGift.page === 0) {
          state.giftList = gifts;
        } else {
          state.giftList = [...state.giftList, ...gifts];
        }

        Global.g_filterVoucherGift = {
          ...Global.g_filterVoucherGift,
          total: total,
        };
      }
    },

    // Lay ds qua da doi
    [thunkGetMyGiftList.pending]: (state, action) => {
      state.loadingMyGiftList = true;
    },
    [thunkGetMyGiftList.rejected]: (state, action) => {
      state.loadingMyGiftList = false;
    },
    [thunkGetMyGiftList.fulfilled]: (state, action) => {
      state.loadingMyGiftList = false;
      const { result, transactions } = action.payload;
      if (result === 'success' && transactions) {
        if (transactions) {
          state.myGiftList = transactions;
        }
      }
    },
  },
});

const { actions, reducer } = loyaltySlice;
export const { resetLoyalty } = actions;
export default reducer;
