import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';

CellGiftGrid.propTypes = {
  giftObject: PropTypes.object,
  onPress: PropTypes.func,
  expireTime: PropTypes.string,
};

CellGiftGrid.defaultProps = {
  giftObject: null,
  onPress: null,
  expireTime: null,
};

/**
 *
 * @param {{
 * giftObject: object,
 * onPress: function,
 * expireTime: string,
 * }} props
 * @returns
 */
function CellGiftGrid(props) {
  // MARK: --- Params ---
  const { giftObject, onPress, expireTime } = props;
  const { images, image } = giftObject;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div className="d-flex flex-column cursor-pointer hover-opacity-60" onClick={handlePress}>
      <div
        className="position-relative"
        style={{
          borderRadius: '20px',
          overflow: 'hidden',
          aspectRatio: 2,
          backgroundColor: AppResource.colors.lightGrey50,
        }}
      >
        <img
          alt={giftObject?.giftId}
          src={images['640'] ?? AppResource.images.imgDefaultGift}
          className="w-100 h-100"
          style={{
            objectFit: 'cover',
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = AppResource.images.imgDefaultGift;
          }}
        />

        <div
          className="d-flex align-items-center justify-content-center justify-content-md-start position-absolute bg-white px-2 py-1"
          style={{
            borderRadius: '8px',
            right: '8px',
            top: '8px',
          }}
        >
          <p
            className="mb-0"
            style={{
              fontWeight: 600,
              fontSize: '1.1rem',
              color: AppResource.colors.feature,
            }}
          >
            {Utils.formatNumber(giftObject?.point)}
          </p>
          <div
            className="ml-2 w-20px h-20px rounded-circle d-flex align-items-center justify-content-center p-1"
            style={{
              backgroundColor: AppResource.colors.feature,
            }}
          >
            <img src={AppResource.icons.icLoyaltyPoint} className="w-100 h-100" />
          </div>
        </div>
      </div>

      <div className="mt-2">
        <p
          className="mb-0 text-maxline-2"
          style={{
            fontWeight: 500,
            color: AppResource.colors.textPrimary,
            fontSize: '1.2rem',
          }}
        >
          {giftObject?.title}
        </p>
        <div className="d-flex align-items-center mt-1">
          <i className="fal fa-clock mr-2" />
          <p
            className="mb-0"
            style={{
              fontWeight: 400,
              color: AppResource.colors.placeholder,
              fontSize: '1rem',
            }}
          >
            {expireTime ? expireTime : Utils.formatDateTime(giftObject?.lastUpdate, 'DD/MM/YYYY')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CellGiftGrid;
