import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

export const KTBSDropdownDirections = {
  left: 'dropleft',
  up: 'dropup',
  right: 'dropright',
  down: 'dropdown',
};

export const KTBSDropdownAlignments = {
  start: 'dropdown-menu-start',
  end: 'dropdown-menu-end',
};

export const KTBSDropdowAutoCloseBehavior = {
  true: 'true',
  false: 'false',
  inside: 'inside',
  outside: 'outside',
};

KTBSDropdown.propTypes = {
  toggleWrapperClassName: PropTypes.string,
  toggleElement: PropTypes.element,
  dropdownMenuClassName: PropTypes.string,
  dropdownMenuItems: PropTypes.array,
  selectedValue: PropTypes.string,
  direction: PropTypes.oneOf(Object.values(KTBSDropdownDirections)),
  alignment: PropTypes.oneOf(Object.values(KTBSDropdownAlignments)),
  offset: PropTypes.object,
  staticDisplay: PropTypes.bool, // on | off dynamic positioning
  autoCloseBehavior: PropTypes.oneOf(Object.values(KTBSDropdowAutoCloseBehavior)),
  contentEl: PropTypes.element,
};

KTBSDropdown.defaultProps = {
  toggleWrapperClassName: '',
  toggleElement: <></>,
  dropdownMenuClassName: '',
  dropdownMenuItems: [],
  selectedValue: null,
  direction: KTBSDropdownDirections.down,
  alignment: KTBSDropdownAlignments.start,
  offset: null,
  staticDisplay: true,
  autoCloseBehavior: KTBSDropdowAutoCloseBehavior.true,
  contentEl: null,
};

/**
 *
 * @param {{
 * toggleWrapperClassName: string,
 * toggleElement: element,
 * dropdownMenuClassName: string,
 * dropdownMenuItems: object[],
 * selectedValue: string,
 * direction: string,
 * alignment: string,
 * offset: {},
 * staticDisplay: boolean,
 * autoCloseBehavior: boolean,
 * contentEl: element
 * }} props
 * @returns
 */
function KTBSDropdown(props) {
  // MARK: --- Params ---
  const {
    toggleWrapperClassName,
    toggleElement,
    dropdownMenuClassName,
    dropdownMenuItems,
    selectedValue,
    direction,
    alignment,
    offset,
    staticDisplay,
    autoCloseBehavior,
    contentEl,
  } = props;

  return (
    <div className={`${direction}`}>
      {/* Toggle element */}
      {staticDisplay ? (
        <div
          className={`${toggleWrapperClassName} ${direction}`}
          data-bs-toggle="dropdown"
          data-bs-display="static"
          aria-expanded="false"
          data-bs-auto-close={autoCloseBehavior}
        >
          {toggleElement}
        </div>
      ) : (
        <div
          className={`${toggleWrapperClassName} ${direction}`}
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close={autoCloseBehavior}
        >
          {toggleElement}
        </div>
      )}

      {/* Dropdown menu */}
      <div className={`dropdown-menu ${dropdownMenuClassName} ${alignment}`}>
        {!_.isEmpty(contentEl) ? (
          <div>{contentEl}</div>
        ) : (
          <div>
            {dropdownMenuItems.map((item, index) => {
              return (
                <div key={index}>
                  <a
                    className={`dropdown-item ${
                      !_.isEmpty(selectedValue) && selectedValue === item.value && 'active'
                    }`}
                    href="#"
                  >
                    {item.name}
                  </a>
                  {item.showDivider && <div className="dropdown-divider"></div>}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

export default KTBSDropdown;
