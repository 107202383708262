import authApi from 'api/authApi';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeMonthOrYearPicker from 'general/components/SaymeeOtherComponents/SaymeeMonthOrYearPicker';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import ToastHelper from 'general/helpers/ToastHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import ModalOTPInput from 'modules/Saymee/features/Others/components/ModalOTPInput';
import moment from 'moment';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTransactionHistoryMonth, setTransactionHistoryYear } from '../../accountSlice';
import CellFeeCheck from '../CellFeeCheck';

FeeCheck.propTypes = {};

function FeeCheck(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const [gettingOTP, setGettingOTP] = useState({
    call: false,
    service: false,
  });
  const selectedFareType = useRef();
  const { transactionHistoryList, transactionHistoryYearList } = useAccount();
  const { transactionHistoryMonth, transactionHistoryYear } = useSelector((state) => state.account);
  const [modalOTPInputShowing, setModalOTPInputShowing] = useState(false);
  const [flagResendOTPCodeSuccess, setFlagResendOTPCodeSuccess] = useState({});
  const callbackCheckFeeNeedOTP = useCallback(async (type) => {
    selectedFareType.current = type;
    // Kiem tra ma otp cu con su dung duoc khong
    const savedOtpChargeExpiredTime = localStorage.getItem(
      PreferenceKeys.savedOtpChargeExpiredTime
    );
    if (savedOtpChargeExpiredTime) {
      if (moment(savedOtpChargeExpiredTime).isAfter(moment())) {
        setModalOTPInputShowing(true);
        return;
      }
    }

    if (type === 'call') {
      setGettingOTP({
        ...gettingOTP,
        call: true,
      });
    }
    if (type === 'service') {
      setGettingOTP({
        ...gettingOTP,
        service: true,
      });
    }
    try {
      const res = await authApi.getOTPFactor2(
        localStorage.getItem(PreferenceKeys.savedLoginPhone),
        AppData.otpFactorFunctionName.charge
      );
      const { data, errors } = res;
      if (!!errors && _.isArray(errors) && errors.length > 0) {
        const error = errors[0];
        ToastHelper.showError(error?.message);
      } else {
        if (data) {
          const expiredTime = moment().add(1, 'minutes').toISOString();
          localStorage.setItem(PreferenceKeys.savedOtpChargeExpiredTime, expiredTime);
          setModalOTPInputShowing(true);
          setFlagResendOTPCodeSuccess({});
        }
      }
    } catch (error) {
      console.log(`Get otp factor error: ${error?.message}`);
    }
    setGettingOTP({
      ...gettingOTP,
      service: false,
      call: false,
    });
  }, []);
  const CellFeeCheckList = useMemo(() => {
    const filteredTransactionHistoryList = transactionHistoryList?.filter((item) =>
      item?.billCycle?.includes(
        `${transactionHistoryYear}-${_.padStart(transactionHistoryMonth, 2, '0')}`
      )
    );
    if (
      filteredTransactionHistoryList &&
      _.isArray(filteredTransactionHistoryList) &&
      filteredTransactionHistoryList.length > 0
    ) {
      const firstTransactionHistoryItem = filteredTransactionHistoryList[0];
      // console.log({ firstTransactionHistoryItem, filteredTransactionHistoryList });
      return [
        {
          beforeIcon: AppResource.icons.icPhone,
          label: t('CallFare'),
          fare: firstTransactionHistoryItem?.call,
          afterIcon: AppResource.icons.icLocked,
          onPress: () => {
            callbackCheckFeeNeedOTP('call');
          },
          loading: gettingOTP.call,
        },
        {
          beforeIcon: AppResource.icons.icServicePack,
          label: t('ServiceFare'),
          fare: firstTransactionHistoryItem?.vas + firstTransactionHistoryItem?.cvqt,
          afterIcon: AppResource.icons.icLocked,
          onPress: () => {
            callbackCheckFeeNeedOTP('service');
          },
          loading: gettingOTP.service,
        },
        {
          beforeIcon: AppResource.icons.icPhoneData,
          label: t('DataFare'),
          fare: firstTransactionHistoryItem?.data,
          // afterIcon: AppResource.icons.icCaretRight,
          onPress: () => {
            router.navigate('/saymee/account/transaction-detail/data');
          },
        },
        {
          beforeIcon: AppResource.icons.icDataPack,
          label: t('PackageFare'),
          fare: firstTransactionHistoryItem?.package,
          // afterIcon: AppResource.icons.icCaretRight,
          onPress: () => {
            router.navigate('/saymee/account/transaction-detail/package');
          },
        },
      ];
    }

    return [];
  }, [transactionHistoryList, transactionHistoryMonth, transactionHistoryYear, gettingOTP]);

  const totalFare = useMemo(() => {
    if (CellFeeCheckList && _.isArray(CellFeeCheckList)) {
      return CellFeeCheckList.reduce((sum, number) => {
        return sum + parseInt(number?.fare);
      }, 0);
    }
  }, [CellFeeCheckList]);

  // MARK: --- Functions ---
  async function verifyOTPCode(code) {
    try {
      const res = await authApi.verifyOTPFactor2(code);
      const { data, errors } = res;
      if (!!errors && _.isArray(errors) && errors.length > 0) {
        const error = errors[0];
        ToastHelper.showError(error?.message);
      } else {
        if (data) {
          // verify otp thanh cong
          setModalOTPInputShowing(false);
          Utils.unlockedBodyScroll();
          const token = data;
          Global.g_tempChargeOtpToken = token;
          router.navigate(`/saymee/account/transaction-detail/${selectedFareType.current}`);
        }
      }
    } catch (error) {
      console.log(`Verify OTP code error: ${error?.message}`);
    }
  }

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <SaymeeMonthOrYearPicker
          style={{ width: '48%' }}
          className=""
          value={transactionHistoryMonth}
          type="month"
          onChooseValue={(value) => {
            dispatch(setTransactionHistoryMonth(value));
          }}
        />
        <SaymeeMonthOrYearPicker
          style={{ width: '48%' }}
          className=""
          value={transactionHistoryYear}
          type="year"
          yearList={transactionHistoryYearList}
          onChooseValue={(value) => {
            dispatch(setTransactionHistoryYear(value));
          }}
        />
      </div>
      <div
        className="bg-white d-flex flex-column flex-lg-row justify-content-around align-items-center py-8 mt-6"
        style={{
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div>
          <p
            className="text-remaining font-weight-boldest mb-0 text-center text-lg-left"
            style={{ fontSize: '1.43rem' }}
          >
            {t('Fare')}
          </p>
          <span className="text-remaining font-weight-boldest" style={{ fontSize: '2.86rem' }}>
            {Utils.formatNumber(totalFare)} đ
          </span>
        </div>
        <div>
          <SaymeeButton
            text={t('RechargeMoney')}
            style={{
              height: '2.86rem',
              width: '170px',
            }}
            onPress={() => {
              router.navigate('/saymee/account/utilities');
            }}
          />
          <SaymeeButton
            text={t('HistoryRechargeMoney')}
            style={{
              height: '2.86rem',
              width: '170px',
            }}
            className="saymee-primary-outline-button mt-3"
            onPress={() => {
              router.navigate('/saymee/account/history-recharge-money');
            }}
          />
        </div>
      </div>
      {CellFeeCheckList.map((item, index) => {
        return (
          <CellFeeCheck
            key={index}
            beforeIcon={item.beforeIcon}
            label={item.label}
            fare={item.fare}
            afterIcon={item.afterIcon}
            onPress={item?.onPress}
            loading={item?.loading}
          />
        );
      })}

      {/* Modal OTP input */}
      <ModalOTPInput
        preferenceKey={PreferenceKeys.savedOtpChargeExpiredTime}
        show={modalOTPInputShowing}
        onClose={() => {
          setModalOTPInputShowing(false);
        }}
        phone={localStorage.getItem(PreferenceKeys.savedLoginPhone)}
        onPressResendOTP={() => {
          callbackCheckFeeNeedOTP();
        }}
        flagResendOTPCodeSuccess={flagResendOTPCodeSuccess}
        onPressSubmit={(code) => {
          verifyOTPCode(code);
        }}
      />
    </div>
  );
}

export default FeeCheck;
