/**
 * 
 * Usage: 
 *  AppDialogHelper.show('Title', 'Description', [
        {
            title: 'Yes', type: 'positive', onClick: () => {
                AppDialogHelper.hide();
            }
        },
        {
            title: 'Cancel', type: 'neutral', onClick: () => {
                AppDialogHelper.hide();
            }
        },
        {
            title: 'No', type: 'negative', onClick: () => {
                AppDialogHelper.hide();
            }
        }
    ]);
 * 
 */

import AppResource from 'general/constants/AppResource';
import LogHelper from 'general/helpers/LogHelper';
import Utils from 'general/utils/Utils';
import { createRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SaymeeButton from '../SaymeeOtherComponents/SaymeeButton';

AppDialog.propTypes = {};

const ref = createRef();
const sTag = '[AppDialog]';

// MARK: --- Helper functions ---
function getButtonVariant(type) {
  let buttonVariant = 'primary';
  if (type === 'negative') {
    buttonVariant = 'danger';
  }
  if (type === 'neutral') {
    buttonVariant = 'secondary';
  }
  return buttonVariant;
}

function AppDialog(props) {
  // MARK: --- Params ---
  const [dialogShowing, setDialogShowing] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [buttons, setButtons] = useState([]);
  const [icon, setIcon] = useState(AppResource.images.imgDialogSignOut);
  const [titleColor, setTitleColor] = useState(AppResource.colors.textPrimary);

  // MARK: --- Functions ---
  function handleClose() {
    setDialogShowing(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    ref.current = {
      show: (title, description, buttons, icon, titleColor) => {
        setDialogShowing(true);
        setTitle(title);
        setDescription(description);
        setButtons(buttons);
        setIcon(icon);
        setTitleColor(titleColor);
      },
      hide: () => {
        setDialogShowing(false);
      },
    };

    return () => {};
  }, []);

  return (
    <div>
      <Modal
        className="AppDialog"
        backdrop="static"
        show={dialogShowing}
        onHide={handleClose}
        centered
        onExit={() => {
          Utils.unlockedBodyScroll();
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
      >
        {/* Header */}
        <Modal.Header className="px-5 py-5">
          <Modal.Title className="w-100">
            <div
              className="text-center"
              style={{
                color: titleColor,
                fontWeight: 700,
                fontSize: '1.4rem',
              }}
            >
              {title}
            </div>
          </Modal.Title>
          {/* <div
            className="btn btn-xs btn-icon btn-light btn-hover-secondary cursor-pointer"
            onClick={() => {
              handleClose();
            }}
          >
            <i className="far fa-times"></i>
          </div> */}
        </Modal.Header>

        {/* Body */}
        <Modal.Body className="p-0">
          <div className="d-flex flex-column align-items-center">
            <img
              className=""
              src={icon}
              style={{
                width: '30%',
              }}
            />
            <div
              className="mt-4 text-center px-10"
              style={{
                fontWeight: 400,
                fontSize: '1rem',
                color: AppResource.colors.textPrimary,
              }}
            >
              {description}
            </div>
          </div>
        </Modal.Body>

        {buttons && buttons.length > 0 && (
          <Modal.Footer className="d-flex align-items-center justify-content-center pb-2">
            <div
              className="d-flex flex-column align-items-center justify-content-center"
              style={{
                width: '80%',
              }}
            >
              {buttons.map((item, index) => {
                return (
                  <div
                    className={`w-100 ${index === 0 && 'pl-0'} ${
                      index === buttons.length - 1 && 'pr-0'
                    }`}
                    key={index}
                  >
                    <SaymeeButton
                      text={item?.title}
                      className={`${
                        item?.type === 'negative'
                          ? 'saymee-primary-outline-button'
                          : 'saymee-primary-button'
                      } px-10 h-44px mb-4`}
                      onPress={() => {
                        if (item?.onClick) {
                          item?.onClick();
                        }
                      }}
                    />
                    {/* <Button
                      className="font-weight-bold flex-grow-1 col"
                      variant={getButtonVariant(item?.type)}
                      onClick={() => {
                        if (item?.onClick) {
                          item?.onClick();
                        }
                      }}
                    >
                      {item?.title}
                    </Button> */}
                  </div>
                );
              })}
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}

export default AppDialog;
export const AppDialogHelper = {
  /**
   * Show dialog
   * @param {string} title Dialog title
   * @param {string} description Dialog description
   * @param {[object]} buttons Dialog buttons
   * @param {any} icon Dialog icon
   * @param {string} icon Dialog title color
   */
  show: (
    title,
    description,
    buttons = [],
    icon = AppResource.images.imgDialogSignOut,
    titleColor = AppResource.colors.textPrimary
  ) => {
    ref.current.show(title, description, buttons, icon, titleColor);
  },
  /**
   * Hide dialog
   */
  hide: () => {
    ref.current.hide();
  },
};
