import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

ServiceItem.propTypes = {
  title: PropTypes.string,
  descriptionList: PropTypes.arrayOf(PropTypes.string),
  iconText: PropTypes.string,
  backgroundColor: PropTypes.string,
  icon: PropTypes.any,
  onPress: PropTypes.func,
  expiredDate: PropTypes.string,
};

ServiceItem.defaultProps = {
  title: '',
  descriptionList: [],
  iconText: '',
  backgroundColor: '',
  icon: null,
  onPress: null,
  expiredDate: '',
};

/**
 *
 * @param {{
 * title: string,
 * descriptionList: string[],
 * iconText: string,
 * backgroundColor: string,
 * icon: any,
 * onPress: function,
 * expiredDate: string,
 * }} props
 * @returns
 */
function ServiceItem(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { title, descriptionList, iconText, backgroundColor, icon, onPress, expiredDate } = props;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="p-4 w-100 h-100 d-flex flex-column justify-content-between position-relative cursor-pointer hover-opacity-60 overflow-hidden"
      style={{
        backgroundColor: backgroundColor,
        borderRadius: '12px',
      }}
      onClick={handlePress}
    >
      <img
        src={icon}
        className="position-absolute right-0 top-0 zindex-0 w-70px h-70px"
        style={{
          objectFit: 'cover',
        }}
      />

      <p
        className="mb-2 zindex-1"
        style={{
          fontWeight: 700,
          fontSize: '1.2rem',
          color: AppResource.colors.textPrimary,
        }}
      >
        {title}
      </p>
      {descriptionList?.length > 0 && (
        <ul
          className="zindex-1 mb-0"
          style={{
            listStyleType: descriptionList.length > 1 ? 'disc' : 'none',
            paddingLeft: descriptionList.length > 1 ? '' : '0px',
          }}
        >
          {/* 
        <div dangerouslySetInnerHTML={{ __html: mailItem?.emailContent }}></div>
        */}
          {descriptionList.map((item, index) => {
            return (
              <li key={index}>
                <div dangerouslySetInnerHTML={{ __html: item }}></div>
              </li>
            );
          })}
        </ul>
      )}
      {/* <div className="font-size-h3">{iconText}</div> */}
      <p
        className="mb-2 zindex-1"
        style={{
          fontWeight: 600,
          fontSize: '0.9rem',
          color: AppResource.colors.textSecondary,
        }}
      >
        {`${t('ExpiredDate')}: ${expiredDate}`}
      </p>

      {/* <div className="mt-2 d-flex align-items-center justify-content-between">
        <p
          className="mb-0"
          style={{
            fontWeight: 700,
            fontSize: '2.1rem',
            color: AppResource.colors.feature,
          }}
        >
          29k
          <span
            style={{
              fontWeight: 700,
              fontSize: '1.4rem',
              color: AppResource.colors.feature,
            }}
          >
            /30 ngày
          </span>
        </p>

        <div className="d-flex align-items-center justify-content-center">
          <p
            className="mb-0"
            style={{
              fontWeight: 500,
              fontSize: '1.2rem',
              color: AppResource.colors.textPrimary,
            }}
          >
            Free Data
          </p>
          <img src={AppResource.icons.icArrowRight} />
        </div>
      </div> */}
    </div>
  );
}

export default ServiceItem;
