import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

SaymeeColumnChart.propTypes = {
  additionalClassName: PropTypes.string,
  chartSeries: PropTypes.array,
  chartLabels: PropTypes.array,
  title: PropTypes.string,
  additonalElement: PropTypes.element,
};

SaymeeColumnChart.defaultProps = {
  additionalClassName: '',
  chartSeries: [],
  chartLabels: [],
  title: '',
  additonalElement: <></>,
};

function SaymeeColumnChart(props) {
  // MARK: --- Params ---
  const { additionalClassName, chartLabels, chartSeries, title, additonalElement } = props;
  const { t } = useTranslation();

  const series = useMemo(() => {
    return [
      {
        data: chartSeries,
        name: t('Fare'),
      },
    ];
  }, [chartSeries]);
  const options = useMemo(
    () => ({
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: true,
      },
      colors: [AppResource.colors.feature],
      xaxis: {
        categories: chartLabels,
        labels: {
          show: true,
        },
        axisBorder: {
          show: true,
        },
        crosshairs: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        tooltip: {
          enabled: false,
        },
      },
      stroke: {
        curve: 'smooth',
        width: 2,
      },
      yaxis: {
        show: true,
        labels: {
          show: true,
          formatter: function (val, index) {
            return `${Utils.formatNumber(val)} đ`;
          },
        },
      },
      grid: {
        show: true,
        xaxis: {
          lines: {
            show: true,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        strokeDashArray: 8,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 1,
      },
      plotOptions: {
        bar: {
          borderRadius: 12,
          borderRadiusApplication: 'end',
          dataLabels: { position: 'center' },
        },
      },
    }),
    [chartLabels]
  );

  return (
    <div
      className="SaymeeColumnChart h-100 mt-12"
      style={{
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
        borderRadius: '20px',
      }}
    >
      <div className={`card card-custom card-stretch gutter-b ${additionalClassName}`}>
        {/* card header */}
        <div className="card-header border-0 pt-6 d-flex flex-wrap align-items-center justify-content-between">
          <h3 className="card-title mb-4">
            <span
              className="card-label"
              style={{
                fontWeight: 700,
                fontSize: '1.8rem',
                color: '##262224',
              }}
            >
              {title}
            </span>
          </h3>
          <div className="mb-4">{additonalElement}</div>
        </div>

        {/* card body */}
        <div className="card-body d-flex align-items-center justify-content-lg-start justify-content-center pb-7 pt-0 flex-wrap">
          <div className="w-100">
            <ReactApexChart options={options} series={series} type="bar" height={320} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaymeeColumnChart;
