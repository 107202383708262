import { AppDialogHelper } from 'general/components/AppDialog';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import SaymeeSecment from 'general/components/SaymeeOtherComponents/SaymeeSecment';
import AppResource from 'general/constants/AppResource';
import ToastHelper from 'general/helpers/ToastHelper';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import CellVoucherAddressList from '../CellVoucherAddressList';

ModalVoucherDetail.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  voucherItem: PropTypes.object,
  giftItem: PropTypes.object,
};

ModalVoucherDetail.defaultProps = {
  show: false,
  onClose: null,
  voucherItem: null,
  giftItem: null,
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * voucherItem: object,
 * giftItem: object,
 * }} props
 * @returns
 */
function ModalVoucherDetail(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, voucherItem, giftItem } = props;
  const [showing, setShowing] = useState(true);
  const tabs = useMemo(() => {
    return [
      _.isNull(giftItem) ? t('ExchangePoint') : t('Voucher'),
      t('Description'),
      t('ConditionUse'),
      t('Address'),
    ];
  }, [giftItem]);
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // MARK: --- Hooks ---
  useEffect(() => {
    setSelectedTab(tabs[0]);
  }, [giftItem]);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleSelectedTab(newTab) {
    setSelectedTab(newTab);
  }

  return (
    <div>
      <Modal
        className=""
        show={show && showing}
        centered
        backdrop="static"
        size="lg"
        onHide={handleClose}
        onExit={() => {
          Utils.unlockedBodyScroll();
          setSelectedTab(tabs[0]);
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title className="w-100 text-center font-weight-bolder font-size-lg">
            {_.isNull(giftItem) ? t('VoucherDetail') : t('GiftDetail')}
          </Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>
        <Modal.Body
          className="overflow-auto"
          style={{
            maxHeight: '80vh',
          }}
        >
          <div>
            {/* anh voucher */}
            <div
              className="mb-2"
              style={{
                borderRadius: '20px',
                overflow: 'hidden',
                aspectRatio: 3,
                backgroundColor: AppResource.colors.lightGrey50,
              }}
            >
              <img
                alt={voucherItem?.giftId}
                src={voucherItem?.images['640']}
                className="w-100 h-100"
                style={{
                  objectFit: 'cover',
                }}
              />
            </div>

            {/* tabs */}
            <SaymeeSecment
              tabs={tabs}
              activeTab={selectedTab}
              handleClick={handleSelectedTab}
              className="d-flex justify-content-center align-items-center"
            />
            <div>
              {selectedTab === t('Description') ? (
                <div>
                  {voucherItem?.content ? (
                    <div dangerouslySetInnerHTML={{ __html: voucherItem?.content }}></div>
                  ) : (
                    <SaymeeEmpty message={t('NoDataToDisplay')} />
                  )}
                </div>
              ) : selectedTab === t('ConditionUse') ? (
                <div>
                  {voucherItem?.content ? (
                    <div dangerouslySetInnerHTML={{ __html: voucherItem?.note }}></div>
                  ) : (
                    <SaymeeEmpty message={t('NoDataToDisplay')} />
                  )}
                </div>
              ) : selectedTab === t('Address') ? (
                voucherItem?.offices?.length > 0 ? (
                  <div>
                    {voucherItem?.offices?.map((item, index) => {
                      return (
                        <div key={index} className="mb-6">
                          <CellVoucherAddressList
                            address={item?.address}
                            onPress={() => {
                              Utils.openInNewTab(
                                `http://maps.google.com/?q=${item?.latitude},${item?.longitude}`
                              );
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <SaymeeEmpty message={t('NoDataToDisplay')} />
                )
              ) : selectedTab === t('ExchangePoint') || selectedTab === t('Voucher') ? (
                <div>
                  <p
                    className="mb-2 text-maxline-2"
                    style={{
                      fontWeight: 500,
                      color: AppResource.colors.textPrimary,
                      fontSize: '1.2rem',
                    }}
                  >
                    {voucherItem?.title}
                  </p>
                  <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                    <div
                      className="mr-2 w-20px h-20px rounded-circle d-flex align-items-center justify-content-center p-1"
                      style={{
                        backgroundColor: AppResource.colors.feature,
                      }}
                    >
                      <img src={AppResource.icons.icLoyaltyPoint} className="w-100 h-100" />
                    </div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: 600,
                        fontSize: '1.1rem',
                        color: AppResource.colors.feature,
                      }}
                    >
                      {Utils.formatNumber(voucherItem?.point)}
                    </p>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <p className="mb-0">
                      {t('ViewCount')}: {Utils.formatNumber(voucherItem?.views)}
                    </p>
                    <p className="mb-0">
                      {t('RemainCount')}: {Utils.formatNumber(voucherItem?.quantity)}
                    </p>
                  </div>
                  {/* thoi han su dung (giftItem) */}
                  {!_.isNull(giftItem) && (
                    <div className="mt-2 d-flex align-items-center">
                      <i className="far fa-clock mr-2 text-dark-50" />
                      <p className="mb-0">{giftItem?.expired}</p>
                    </div>
                  )}
                  {/* uu dai tu thuong hieu */}
                  <div className="mt-4 d-flex flex-column align-items-start">
                    <p
                      className="mb-2 text-maxline-2"
                      style={{
                        fontWeight: 500,
                        color: AppResource.colors.textPrimary,
                        fontSize: '1.2rem',
                      }}
                    >
                      {t('OfferFromBrand')}
                    </p>
                    <div
                      className="bg-white p-4"
                      style={{
                        boxShadow:
                          '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                        borderRadius: '12px',
                      }}
                    >
                      <img src={voucherItem?.brandImage} className="w-120px h-120px mb-2" />
                      <p className="mb-0 mt-2">{voucherItem?.brandName}</p>
                    </div>
                  </div>
                  {/* cung cap boi */}
                  <div className="mt-4 d-flex flex-column align-items-start">
                    <p
                      className="mb-2 text-maxline-2"
                      style={{
                        fontWeight: 500,
                        color: AppResource.colors.textPrimary,
                        fontSize: '1.2rem',
                      }}
                    >
                      {t('ProvidedBy')}
                    </p>
                    <div
                      className="bg-white p-4"
                      style={{
                        boxShadow:
                          '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                        borderRadius: '12px',
                      }}
                    >
                      <img src={AppResource.icons.icUrbox} className="w-120px h-60px mb-0" />
                      {/* <p className="mb-0 mt-2">{voucherItem?.brandName}</p> */}
                    </div>
                  </div>

                  {/* gift detail */}
                  {!_.isNull(giftItem) && (
                    <div className="mt-4 d-flex flex-column align-items-start">
                      <p
                        className="mb-2 text-maxline-2"
                        style={{
                          fontWeight: 500,
                          color: AppResource.colors.textPrimary,
                          fontSize: '1.2rem',
                        }}
                      >
                        {t('Serial')}
                      </p>
                      <div
                        className="d-flex align-items-center cursor-pointer hover-opacity-60"
                        onClick={() => {
                          Utils.copyTextToClipboard(giftItem?.serial, () => {
                            ToastHelper.showSuccess(t('Copied'));
                          });
                        }}
                      >
                        <p className="mb-0 mr-2">{giftItem?.serial}</p>
                        <i className="far fa-copy text-dark-50" />
                      </div>

                      <div className="mt-2 d-flex flex-column align-items-center justify-content-center w-100">
                        <p className="mb-0">{t('GiveThisCodeToSeller')}</p>
                        <img src={giftItem?.code_image} />
                        <p className="mb-0">{t('OrUseThisCode')}</p>
                        <div
                          className="px-4 py-2 rounded-lg mt-2 cursor-pointer hover-opacity-60"
                          style={{
                            border: `1px solid ${AppResource.colors.feature}`,
                          }}
                          onClick={() => {
                            Utils.copyTextToClipboard(giftItem?.code, () => {
                              ToastHelper.showSuccess(t('Copied'));
                            });
                          }}
                        >
                          <p className="mb-0 font-size-lg font-weight-bold text-dark">
                            {giftItem?.code}
                            <i
                              className="far fa-copy ml-2"
                              style={{
                                color: AppResource.colors.feature,
                              }}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {_.isNull(giftItem) && (
            <div className="w-100">
              <SaymeeButton
                text={t('ExchangeGift')}
                className="saymee-primary-button px-10 h-40px"
                onPress={() => {
                  setShowing(false);
                  AppDialogHelper.show(
                    `${t('Oops')}!`,
                    t('UnsupportFeatureOnWeb'),
                    [
                      {
                        title: t('Close'),
                        type: 'positive',
                        onClick: () => {
                          AppDialogHelper.hide();
                          setShowing(true);
                        },
                      },
                    ],
                    AppResource.images.imgDialogError,
                    AppResource.colors.error01
                  );
                }}
              />
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalVoucherDetail;
