const { createSlice } = require('@reduxjs/toolkit');

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    current: null,
    userInfo: null,
    phoneInfo: null,
    loyaltyInfo: null,
    profileList: null,
    package: null,
    registeredServiceList: null,
    // lich su thanh toan
    paymentHistoryList: null,
    // lich su giao dich
    transactionHistoryList: null,
    transactionHistoryMonth: 0,
    transactionHistoryYear: 0,
  },
  reducers: {
    setCurrentAccount: (state, action) => {
      state.current = action.payload;
    },
    setPhoneInfo: (state, action) => {
      state.phoneInfo = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setLoyaltyInfo: (state, action) => {
      state.loyaltyInfo = action.payload;
    },
    setProfileList: (state, action) => {
      state.profileList = action.payload;
    },
    setPackage: (state, action) => {
      state.package = action.payload;
    },
    setRegisteredServiceList: (state, action) => {
      state.registeredServiceList = action.payload;
    },
    setPaymentHistoryList: (state, action) => {
      state.paymentHistoryList = action.payload;
    },
    setTransactionHistoryList: (state, action) => {
      state.transactionHistoryList = action.payload;
    },
    setTransactionHistoryMonth: (state, action) => {
      state.transactionHistoryMonth = action.payload;
    },
    setTransactionHistoryYear: (state, action) => {
      state.transactionHistoryYear = action.payload;
    },

    resetAccount: (state, action) => {
      state.current = null;
      state.userInfo = null;
      state.phoneInfo = null;
      state.loyaltyInfo = null;
      state.profileList = null;
      state.package = null;
      state.registeredServiceList = null;
      state.paymentHistoryList = null;
      state.transactionHistoryList = null;
      state.transactionHistoryMonth = 0;
      state.transactionHistoryYear = 0;
    },
  },
  extraReducers: {},
});

const { actions, reducer } = accountSlice;
export const {
  setCurrentAccount,
  setPhoneInfo,
  setUserInfo,
  setLoyaltyInfo,
  resetAccount,
  setProfileList,
  setPackage,
  setRegisteredServiceList,
  setPaymentHistoryList,
  setTransactionHistoryList,
  setTransactionHistoryMonth,
  setTransactionHistoryYear,
} = actions;
export default reducer;
