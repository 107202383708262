import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import useRouter from 'hooks/useRouter';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import AppResource from 'general/constants/AppResource';
import { FastField, Formik } from 'formik';
import * as Yup from 'yup';
import AppData from 'general/constants/AppData';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import _ from 'lodash';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import { ClipLoader } from 'react-spinners';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import moment from 'moment';
import authApi from 'api/authApi';
import { AppDialogHelper } from 'general/components/AppDialog';
import Utils from 'general/utils/Utils';

ForgotPasswordScreen.propTypes = {};

function ForgotPasswordScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const [otpForgotValid, setOtpForgotValid] = useState(() => {
    const savedOtpForgotExpiredTime = localStorage.getItem(
      PreferenceKeys.savedOtpForgotExpiredTime
    );
    if (savedOtpForgotExpiredTime) {
      return moment(savedOtpForgotExpiredTime).isAfter(moment());
    }
    return false;
  });
  const [otpRemainingSeconds, setOtpRemainingSeconds] = useState(-1);

  // MARK: --- Hooks ---
  useEffect(() => {
    let intervalOTP = null;
    const savedOtpForgotExpiredTime = localStorage.getItem(
      PreferenceKeys.savedOtpForgotExpiredTime
    );
    if (savedOtpForgotExpiredTime) {
      const momentSavedOtpForgotExpiredTime = moment(savedOtpForgotExpiredTime);

      intervalOTP = setInterval(() => {
        const remainingSeconds = momentSavedOtpForgotExpiredTime.diff(moment(), 'seconds');
        // console.log({ remainingSeconds });
        setOtpRemainingSeconds(remainingSeconds);
        if (remainingSeconds <= 0) {
          localStorage.removeItem(PreferenceKeys.savedOtpForgotExpiredTime);
          setOtpForgotValid(false);
          clearInterval(intervalOTP);
          setOtpRemainingSeconds(-1);
        }
      }, 1000);
    }

    return () => {
      if (intervalOTP) {
        clearInterval(intervalOTP);
      }
    };
  }, [otpForgotValid]);

  // MARK: --- Functions ---
  async function requestRecoverPassword(password, phone, otp) {
    try {
      setLoading(true);
      const res = await authApi.recoverPassword(password, phone, otp);
      const { data, errors } = res;
      if (data) {
        // phuc hoi mat khau thanh cong
        dispatch(setCurrentAccount(data));
        AppDialogHelper.show(
          `${t('Yay')}! ${t('Success')}`,
          t('ResetPasswordSuccess'),
          [
            {
              title: t('SignIn'),
              type: 'positive',
              onClick: () => {
                AppDialogHelper.hide();
                router.navigate('/auth/sign-in');
              },
            },
          ],
          AppResource.images.imgDialogSuccess,
          AppResource.colors.success
        );
      } else if (errors) {
        const error = errors[0];
        if (error?.message) {
          // ToastHelper.showError(error?.message);
          AppDialogHelper.show(
            `${t('Oops')}! ${t('Failed')}`,
            error?.message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      }
    } catch (error) {
      console.log(`Sign in error: ${error}`);
    }
    setLoading(false);
  }

  async function requestGetForgotOTP(phone) {
    try {
      const res = await authApi.getOTP(AppData.otpPrefix.forgetOtp, phone);
      const { data, errors } = res;
      if (data) {
        // Yc ma otp quen mat khau thanh cong
        AppDialogHelper.show(
          `${t('Yay')}! ${t('Success')}`,
          t('OTPForgotCodeWasSentSuccess'),
          [
            {
              title: t('Close'),
              type: 'positive',
              onClick: () => {
                AppDialogHelper.hide();
              },
            },
          ],
          AppResource.images.imgDialogSuccess,
          AppResource.colors.success
        );
        setOtpForgotValid(true);
        const expiredTime = moment().add(1, 'minutes').toISOString();
        localStorage.setItem(PreferenceKeys.savedOtpForgotExpiredTime, expiredTime);
        setOtpRemainingSeconds(59);
      } else if (errors) {
        const error = errors[0];
        if (error?.message) {
          AppDialogHelper.show(
            `${t('Oops')}! ${t('Failed')}`,
            error?.message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      }
    } catch (error) {
      console.log(`GetOTP error: ${error}`);
    }
  }

  return (
    <>
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '#', active: false },
          { title: t('ForgotPassword'), url: '#', active: true },
        ]}
      />

      <div className="row my-6">
        <div className="col-md-6 d-none d-md-flex">
          <img
            className="w-100"
            src={AppResource.images.imgAuthForgot}
            style={{
              objectFit: 'cover',
              borderRadius: '20px',
            }}
          />
        </div>

        <div className="col-md-6">
          <Formik
            initialValues={{
              phone: '',
              password: '',
              retypePassword: '',
              otp: '',
            }}
            validationSchema={Yup.object().shape({
              phone: Yup.string()
                .trim()
                .required(t('PhoneIsRequired'))
                .matches(AppData.regexSamples.phoneRegex, t('PhoneNotValid')),
              password: Yup.string().trim().required(t('PasswordIsRequired')),
              retypePassword: Yup.string()
                .trim()
                .required(t('RetypePasswordIsRequired'))
                .oneOf([Yup.ref('password')], t('PasswordNotMatch')),
              otp: Yup.string().trim().required(t('OTPCodeIsRequired')),
            })}
            enableReinitialize
            onSubmit={(values) => {
              requestRecoverPassword(Utils.sha256(values.password), values.phone, values.otp);
            }}
          >
            {(formikProps) => (
              <>
                <div className="px-xl-10 px-lg-10 px-md-5 mt-sm-5">
                  <p
                    className="text-center mt-6 text-uppercase text-dark"
                    style={{
                      fontWeight: 700,
                      fontSize: '1.6rem',
                    }}
                  >
                    {t('ForgotPassword')}
                  </p>

                  <div className="d-flex flex-column pb-6">
                    <KTFormGroup
                      label={t('PhoneNumber')}
                      inputName="phone"
                      inputElement={
                        <FastField name="phone">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid={true}
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('InputPhoneNumber'))}`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    <KTFormGroup
                      label={t('NewPassword')}
                      inputName="password"
                      inputElement={
                        <FastField name="password">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid={true}
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.password}
                              placeholder={`${_.capitalize(t('InputPassword'))}`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    <KTFormGroup
                      label={t('RetypeNewPassword')}
                      inputName="retypePassword"
                      additionalClassName="mb-4"
                      inputElement={
                        <FastField name="retypePassword">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onFocus={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid={true}
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.password}
                              placeholder={`${_.capitalize(t('InputPassword'))}`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    <div>
                      <KTFormGroup
                        label={t('OTPCode')}
                        inputName="otp"
                        additionalClassName="mb-2"
                        inputElement={
                          <FastField name="otp">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onFocus={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid={true}
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.number}
                                placeholder={`${_.capitalize(t('InputOtpCode'))}`}
                              />
                            )}
                          </FastField>
                        }
                      />
                      <div className="d-flex align-items-center justify-content-end">
                        <a
                          href="#"
                          className={`${otpForgotValid ? 'text-dark-50' : 'saymee-link'}`}
                          style={{
                            fontWeight: 600,
                            fontSize: '0.9rem',
                            color: AppResource.colors.feature,
                            pointerEvents: otpRemainingSeconds < 0 ? '' : 'none',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            requestGetForgotOTP(formikProps.values.phone);
                          }}
                        >
                          {!otpForgotValid ? t('GetOTPCode') : t('ResendOTPCode')}
                          {otpForgotValid && (
                            <>
                              <i
                                className="far fa-undo ml-2 fa-1x"
                                style={{
                                  color:
                                    otpRemainingSeconds < 0
                                      ? AppResource.colors.feature
                                      : '#7E8299',
                                }}
                              />{' '}
                              {`(${otpRemainingSeconds}s)`}
                            </>
                          )}
                        </a>
                      </div>
                    </div>

                    <div className="d-flex flex-column align-items-center w-100 mt-6">
                      <SaymeeButton
                        text={t('Confirm')}
                        style={{
                          height: '3.6rem',
                          width: '100%',
                        }}
                        onPress={() => {
                          formikProps.handleSubmit();
                        }}
                        icon={
                          loading ? <ClipLoader color="#fff" className="mr-2" size={20} /> : <></>
                        }
                      />
                    </div>

                    <div>
                      <p
                        className="mt-10"
                        style={{
                          fontWeight: 400,
                          fontSize: '1rem',
                          color: AppResource.colors.placeholder,
                        }}
                      >
                        {t('AlreadyHaveAnAccount')}?{' '}
                        <span
                          className="cursor-pointer"
                          style={{
                            color: AppResource.colors.primary,
                            textDecorationLine: 'underline',
                            fontWeight: '600',
                          }}
                          onClick={() => {
                            router.navigate('/auth/sign-in');
                          }}
                        >
                          {t('SignIn')}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default ForgotPasswordScreen;
