import transactionApi from 'api/transactionApi';
import { AppDialogHelper } from 'general/components/AppDialog';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import SaymeeSecment from 'general/components/SaymeeOtherComponents/SaymeeSecment';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import TransactionHelper from 'general/helpers/TransactionHelper';
import Global from 'general/utils/Global';
import useRouter from 'hooks/useRouter';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CellTransactionDetailList from '../../components/CellTransactionDetailList';

TransactionDetailScreen.propTypes = {};

function TransactionDetailScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { transactionHistoryMonth, transactionHistoryYear } = useSelector((state) => state.account);
  const router = useRouter();
  const query = router.query;
  const transactionDetailType = query?.type;
  const [loading, setLoading] = useState(false);
  const serviceTabs = [t('SMS'), t('Service')];
  const [selectedServiceTab, setSelectedServiceTab] = useState(serviceTabs[0]);
  const title = useMemo(() => {
    const appendText = `${t('Month').toLowerCase()} ${_.padStart(
      transactionHistoryMonth,
      2,
      '0'
    )}/${transactionHistoryYear}`;
    switch (transactionDetailType) {
      case 'data':
        return t('DetailDataCharge') + ' ' + appendText;
      case 'package':
        return t('DetailPackageCharge') + ' ' + appendText;
      case 'call':
        return t('DetailCallCharge') + ' ' + appendText;
      case 'service':
        return t('DetailServiceCharge') + ' ' + appendText;
      default:
        return '';
    }
  }, [transactionDetailType, transactionHistoryMonth, transactionHistoryYear]);
  const [transactionList, setTransactionList] = useState([]);
  const [transactionSecondList, setTransactionSecondList] = useState([]);
  const groupedTransactionList = useMemo(() => {
    const arrList = selectedServiceTab === t('SMS') ? transactionSecondList : transactionList;
    return TransactionHelper.groupTransactionList(arrList, 'DD/MM/YYYY HH:mm:ss');
  }, [transactionList, transactionSecondList, selectedServiceTab]);
  const isTransactionService = useMemo(() => {
    return transactionDetailType === 'service';
  }, [transactionDetailType]);

  // MARK: --- Functions ---
  async function getTransactionDetail(type) {
    const otpToken =
      type === AppData.transactionBillType.call || type === AppData.transactionBillType.sms
        ? Global.g_tempChargeOtpToken
        : null;
    if (
      (type === AppData.transactionBillType.call || type === AppData.transactionBillType.sms) &&
      (_.isNull(otpToken) || _.isEmpty(otpToken))
    ) {
      AppDialogHelper.show(
        `${t('Oops')}!`,
        t('NeedOTPCode'),
        [
          {
            title: t('Close'),
            type: 'positive',
            onClick: () => {
              AppDialogHelper.hide();
              router.navigate('/saymee/account/fee-check');
            },
          },
        ],
        AppResource.images.imgDialogError,
        AppResource.colors.error01
      );
      return;
    }
    const momentDate = moment(`${transactionHistoryMonth}/${transactionHistoryYear}`, 'MM/YYYY');
    const startDate = momentDate.clone().startOf('month').format('YYYY-MM-DD');
    const endDate = momentDate.clone().endOf('month').format('YYYY-MM-DD');
    if (startDate && endDate) {
      setLoading(true);
      try {
        const res = await transactionApi.getTransactionDetail(type, startDate, endDate, otpToken);
        const { data, errors } = res;
        if (data && _.isArray(data)) {
          const filteredData = data.filter(
            (item) => !_.isNull(item?.charge) && !_.isNull(item?.volume)
          );
          setTransactionList(filteredData);
        }
      } catch (error) {
        console.log(`Get transaction detail error: ${error}`);
      }
      setLoading(false);
    }
  }

  async function getSecondTransactionDetail(type) {
    const otpToken =
      type === AppData.transactionBillType.call || type === AppData.transactionBillType.sms
        ? Global.g_tempChargeOtpToken
        : null;
    if (
      (type === AppData.transactionBillType.call || type === AppData.transactionBillType.sms) &&
      (_.isNull(otpToken) || _.isEmpty(otpToken))
    ) {
      return;
    }
    const momentDate = moment(`${transactionHistoryMonth}/${transactionHistoryYear}`, 'MM/YYYY');
    const startDate = momentDate.clone().startOf('month').format('YYYY-MM-DD');
    const endDate = momentDate.clone().endOf('month').format('YYYY-MM-DD');
    if (startDate && endDate) {
      try {
        const res = await transactionApi.getTransactionDetail(type, startDate, endDate, otpToken);
        const { data, errors } = res;
        if (data && _.isArray(data)) {
          const filteredData = data.filter(
            (item) => !_.isNull(item?.charge) && !_.isNull(item?.volume)
          );
          setTransactionSecondList(filteredData);
        }
      } catch (error) {
        console.log(`Get second transaction detail error: ${error}`);
      }
    }
  }

  function handleSelectedServiceTab(newTab) {
    setSelectedServiceTab(newTab);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    const transactionDetailTypeValue = AppData.transactionBillType[transactionDetailType];
    if (transactionDetailTypeValue) {
      getTransactionDetail(transactionDetailTypeValue);
    }
    if (transactionDetailType === 'service') {
      getSecondTransactionDetail(AppData.transactionBillType.sms);
    }
  }, [transactionDetailType]);

  return (
    <div className="pb-10 d-flex flex-column align-items-center">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account/info', active: false },
          { title: t('FeeCheck'), url: '/saymee/account/fee-check', active: false },
          { title: t('TransactionDetail'), url: '#', active: true },
        ]}
      />

      <p
        className="mb-0 mt-10 text-center"
        style={{
          fontWeight: 700,
          fontSize: '1.8rem',
          color: '##262224',
        }}
      >
        {title}
      </p>

      {isTransactionService && (
        <div className="w-100 w-md-50">
          <SaymeeSecment
            tabs={serviceTabs}
            activeTab={selectedServiceTab}
            handleClick={handleSelectedServiceTab}
            className="d-flex justify-content-center align-items-center"
          />
        </div>
      )}

      {selectedServiceTab === t('Service') && transactionList.length > 0 ? (
        <div className="row w-100 w-md-50">
          <div className="col-12">
            {Object.keys(groupedTransactionList)
              ?.sort()
              ?.map((groupItem, groupIndex) => {
                return (
                  <div className="mt-6" key={groupIndex}>
                    <span
                      className="text-remaining font-weight-boldest"
                      style={{ fontSize: '1.43rem' }}
                    >
                      {moment(groupItem)?.format('DD/MM/YYYY')}
                    </span>
                    <div className="mt-4">
                      {groupedTransactionList[groupItem]?.map((item, index) => {
                        let icon = null;
                        switch (item.type) {
                          case AppData.transactionBillType.data:
                            icon = AppResource.icons.icPhoneData;
                            break;
                          case AppData.transactionBillType.call:
                            icon = AppResource.icons.icPhone;
                            break;
                          case AppData.transactionBillType.service:
                            icon = AppResource.icons.icServicePack;
                            break;
                          case AppData.transactionBillType.package:
                            icon = AppResource.icons.icDataPack;
                            break;
                          default:
                            break;
                        }
                        return (
                          <CellTransactionDetailList
                            key={index}
                            title="Saymee-internet"
                            charge={item.charge}
                            volume={item.volume}
                            time={item.time}
                            icon={icon}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <div className="col-lg-6 d-flex align-items-start justify-content-center">
            <img className="" src={AppResource.icons.icMascot} style={{}} />
          </div> */}
        </div>
      ) : selectedServiceTab === t('SMS') && transactionSecondList.length > 0 ? (
        <div className="row w-100 w-md-50">
          <div className="col-12">
            {Object.keys(groupedTransactionList)
              ?.sort()
              ?.map((groupItem, groupIndex) => {
                return (
                  <div className="mt-6" key={groupIndex}>
                    <span
                      className="text-remaining font-weight-boldest"
                      style={{ fontSize: '1.43rem' }}
                    >
                      {moment(groupItem)?.format('DD/MM/YYYY')}
                    </span>
                    <div className="mt-4">
                      {groupedTransactionList[groupItem]?.map((item, index) => {
                        let icon = null;
                        switch (item.type) {
                          case AppData.transactionBillType.data:
                            icon = AppResource.icons.icPhoneData;
                            break;
                          case AppData.transactionBillType.call:
                            icon = AppResource.icons.icPhone;
                            break;
                          case AppData.transactionBillType.service:
                            icon = AppResource.icons.icServicePack;
                            break;
                          case AppData.transactionBillType.package:
                            icon = AppResource.icons.icDataPack;
                            break;
                          default:
                            break;
                        }
                        return (
                          <CellTransactionDetailList
                            key={index}
                            title="Saymee-internet"
                            charge={item.charge}
                            volume={item.volume}
                            time={item.time}
                            icon={icon}
                          />
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
          {/* <div className="col-lg-6 d-flex align-items-start justify-content-center">
            <img className="" src={AppResource.icons.icMascot} style={{}} />
          </div> */}
        </div>
      ) : (
        <></>
      )}

      {((selectedServiceTab === t('SMS') && _.isEmpty(transactionSecondList)) ||
        (selectedServiceTab === t('Service') && _.isEmpty(transactionList))) && (
        <div className="w-100 w-md-50">
          <SaymeeEmpty message={t('NoDataToDisplay')} loading={loading} />
        </div>
      )}
    </div>
  );
}

export default TransactionDetailScreen;
