import AppConfigs from 'general/constants/AppConfigs';
import axiosClient from './axiosClient';

const commonApi = {
  /**
   * Lay thong tin 12 cung hoang dao
   */
  getHeroscope: () => {
    const url = '/common/get-heroscope';
    return axiosClient.post(url);
  },

  /**
   * Lay configs
   * @returns
   */
  getConfigs: () => {
    const url = '/auth/getconfig';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
        },
      }
    );
  },

  /**
   * Tim kiem
   * @param {string} text Tu khoa tim kiem
   */
  universalSearch: (text) => {
    const url = '/others/universal-search';
    return axiosClient.post(
      url,
      { text },
      {
        headers: {
          apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
        },
      }
    );
  },
};

export default commonApi;
