import { AppDialogHelper } from 'general/components/AppDialog';
import SaymeeAvatar from 'general/components/SaymeeOtherComponents/SaymeeAvatar';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import AppResource from 'general/constants/AppResource';
import UserHelper from 'general/helpers/UserHelper';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

function DropdownAccountMenu(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const { displayName, phone } = useAccount();

  // MARK: --- functions ---
  function handleSignOut() {
    AppDialogHelper.show(
      t('SignOut'),
      t('TextConfirmSignOut'),
      [
        {
          title: t('SignOut'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
            UserHelper.signOut();
            router.navigate('/auth/sign-in');
          },
        },
        {
          title: t('Cancel'),
          type: 'negative',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ],
      AppResource.images.imgDialogSignOut,
      AppResource.colors.success
    );
  }

  return (
    <div className="card card-custom w-255px saymee-card">
      <div className="card-body p-0">
        {/* user */}
        <div className="mb-0 d-flex flex-column align-items-center pt-6 pb-4">
          <SaymeeAvatar
            backgroundColor={AppResource.colors.secondary}
            icon={AppResource.icons.icAvatarDefault}
            additionalClassName="w-90px h-90px"
            onPress={() => {
              router.navigate('/saymee/account');
            }}
          />

          <p
            className="mt-4 mb-0 text-center px-4"
            style={{
              fontWeight: 700,
              fontSize: '1.2rem',
              color: AppResource.colors.feature,
            }}
          >
            {displayName}
          </p>
          <p
            className="mt-2 mb-0 px-4"
            style={{
              fontWeight: 600,
              fontSize: '1rem',
              color: AppResource.colors.placeholder,
            }}
          >
            {`mee.id ${phone}`}
          </p>
        </div>

        <div
          className="p-6"
          style={{
            borderTop: '1px solid #EBEBEB',
          }}
        >
          <SaymeeButton
            text={t('SignOut')}
            className="saymee-primary-outline-button px-10 h-40px"
            style={{
              borderRadius: '10px',
            }}
            textStyle={{
              fontWeight: 400,
            }}
            onPress={handleSignOut}
          />
        </div>
      </div>
    </div>
  );
}

export default DropdownAccountMenu;
