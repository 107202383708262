import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import { useTranslation } from 'react-i18next';
import AppResource from 'general/constants/AppResource';
import useAccount from 'hooks/useAccount';
import CellPaymentHistory from '../../components/CellPaymentHistory';
import Utils from 'general/utils/Utils';

HistoryRechargeMoneyScreen.propTypes = {};

function HistoryRechargeMoneyScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { paymentHistory } = useAccount();
  const paymentHistoryDateList = useMemo(() => {
    return Object.keys(paymentHistory);
  }, [paymentHistory]);

  return (
    <div className="pb-10">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account/info', active: false },
          { title: t('FeeCheck'), url: '/saymee/account/fee-check', active: false },
          { title: t('HistoryRechargeMoney'), url: '#', active: true },
        ]}
      />

      <p
        className="mb-0 mt-6 text-center"
        style={{
          fontWeight: 700,
          fontSize: '1.8rem',
          color: '##262224',
        }}
      >
        {t('HistoryRechargeMoney')}
      </p>
      <div className="row mt-6">
        <div className="col-lg-6">
          {paymentHistoryDateList.map((item, index) => {
            return (
              <div className="mt-10" key={index}>
                <span
                  className="text-remaining font-weight-boldest"
                  style={{ fontSize: '1.43rem' }}
                >
                  {item}
                </span>
                {paymentHistory[item]?.map((paymentItem, paymentIndex) => {
                  return (
                    <CellPaymentHistory
                      key={paymentIndex}
                      content={paymentItem?.paymentMethodText}
                      fare={paymentItem?.paymentAmount}
                      time={paymentItem?.time}
                      result={paymentItem?.resultTransaction}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="col-lg-6 d-none d-lg-flex align-items-start justify-content-center">
          <img className="" src={AppResource.icons.icMascot} style={{}} />
        </div>
      </div>
    </div>
  );
}

export default HistoryRechargeMoneyScreen;
