import serviceApi from 'api/serviceApi';
import { AppDialogHelper } from 'general/components/AppDialog';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ModalServiceDetail.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  serviceItem: PropTypes.object,
};

ModalServiceDetail.defaultProps = {
  show: false,
  onClose: null,
  serviceItem: {},
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * serviceItem: object,
 * }} props
 * @returns
 */
function ModalServiceDetail(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, serviceItem } = props;
  const [detailShowing, setDetailShowing] = useState(false);
  const [serviceDetail, setServiceDetail] = useState(null);
  const serviceFeatureContentList = useMemo(() => {
    if (serviceDetail) {
      const featureContent = serviceDetail?.featureContent;
      if (featureContent) {
        const featureContentList = JSON.parse(featureContent);
        if (_.isArray(featureContentList)) {
          return featureContentList.map((item) => {
            return { name: item.key, value: item.value };
          });
        }
      }
    }
    return [];
  }, [serviceDetail]);
  const [showing, setShowing] = useState(true);
  const { registeredServiceList } = useAccount();
  // console.log({ registeredServiceList, serviceItem });
  const isRegisterService = useMemo(() => {
    if (!!registeredServiceList) {
      const foundService = _.find(
        registeredServiceList,
        (item) => item.serviceId === serviceItem?.serviceId
      );
      if (foundService) {
        return true;
      }
    }
    return false;
  }, [registeredServiceList]);
  const [loading, setLoading] = useState(false);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  async function requestServiceDetail(serviceId) {
    setLoading(true);
    try {
      const res = await serviceApi.getServiceDetail(serviceId);
      const data = res?.data;
      console.log({ res });
      if (data) {
        setServiceDetail(data);
      }
    } catch (error) {
      console.log(`Request service detail error: ${error?.message}`);
    }
    setLoading(false);
  }

  function handleCancelRegister() {
    AppDialogHelper.show(
      t('Notice'),
      t('PleaseTakeThisActionOnApp'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
            setShowing(true);
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.success
    );
    setShowing(false);
  }

  function handleRegister() {
    AppDialogHelper.show(
      t('Notice'),
      t('PleaseTakeThisActionOnApp'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
            setShowing(true);
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.success
    );
    setShowing(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (serviceItem) {
      const serviceId = serviceItem?.serviceId;
      if (serviceId) {
        requestServiceDetail(serviceId);
      }
    }
  }, [serviceItem]);

  return (
    <div>
      <Modal
        className=""
        backdrop="static"
        show={show && showing}
        size="lg"
        centered
        onHide={handleClose}
        onExit={() => {
          setDetailShowing(false);
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title></Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>

        <Modal.Body className="py-0 px-14">
          {loading ? (
            <div className="mt-4">
              <SaymeeEmpty loading={loading} />
            </div>
          ) : (
            <div className="mt-4">
              <div
                className="px-10 py-6 position-relative overflow-hidden"
                style={{
                  backgroundColor: serviceItem?.backgroundColor,
                  borderRadius: '12px',
                  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                }}
              >
                <img
                  src={serviceItem?.icon ?? serviceDetail?.thumb}
                  className="position-absolute right-0 top-0 zindex-0 w-70px h-70px"
                  style={{
                    objectFit: 'cover',
                  }}
                />

                <p
                  className="mb-0"
                  style={{
                    fontWeight: 700,
                    fontSize: '1.2rem',
                    color: serviceItem?.textColor,
                  }}
                >
                  {t('ServicePack')}:
                </p>
                <p
                  className="mb-0"
                  style={{
                    fontWeight: 700,
                    fontSize: '1.8rem',
                    color: serviceItem?.textColor ?? AppResource.colors.textPrimary,
                  }}
                >
                  {serviceItem?.title}
                </p>
              </div>

              <div
                className="mt-6"
                style={{
                  borderRadius: '12px',
                  // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-between px-10 position-relative overflow-hidden"
                  style={{
                    borderTopLeftRadius: '12px',
                    borderTopRightRadius: '12px',
                    paddingBottom: 'calc(16px + 0px)',
                    paddingTop: '16px',
                  }}
                >
                  <div
                    className="position-absolute top-0 left-0 w-100 h-100 zindex-0"
                    style={{
                      backgroundColor: serviceItem?.backgroundColor,
                      opacity: `${1 / (2 + serviceFeatureContentList.length)}`,
                    }}
                  ></div>
                  <p
                    className="mb-0 flex-grow-1 zindex-1"
                    style={{
                      fontWeight: 400,
                      fontSize: '1.2rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {t('ServicePrice')}
                  </p>

                  <p
                    className="mb-0 flex-grow-1 text-right zindex-1"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {`${Utils.formatNumber(
                      serviceItem?.price ?? serviceDetail?.packageItems[0]?.price
                    )} đ`}
                  </p>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between px-10 position-relative overflow-hidden"
                  style={{
                    // borderTopLeftRadius: '12px',
                    // borderTopRightRadius: '12px',
                    paddingBottom: 'calc(16px + 0px)',
                    paddingTop: '16px',
                    // marginTop: '-12px',
                  }}
                >
                  <div
                    className="position-absolute top-0 left-0 w-100 h-100 zindex-0"
                    style={{
                      backgroundColor: serviceItem?.backgroundColor,
                      opacity: `${2 / (2 + serviceFeatureContentList.length)}`,
                    }}
                  ></div>
                  <p
                    className="mb-0 flex-grow-1 zindex-1"
                    style={{
                      fontWeight: 400,
                      fontSize: '1.2rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {t('ExpiredDate')}
                  </p>

                  <p
                    className="mb-0 flex-grow-1 text-right zindex-1"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {serviceItem?.expiredDate}
                  </p>
                </div>

                {/* feature list */}
                {serviceFeatureContentList.map((item, index) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between px-10 position-relative overflow-hidden"
                      key={index}
                      style={
                        index < serviceFeatureContentList.length - 1
                          ? {
                              // borderTopLeftRadius: '12px',
                              // borderTopRightRadius: '12px',
                              paddingBottom: 'calc(16px + 0px)',
                              paddingTop: '16px',
                              // marginTop: '-12px',
                            }
                          : {
                              // borderRadius: '12px',
                              borderBottomLeftRadius: '12px',
                              borderBottomRightRadius: '12px',
                              paddingBottom: 'calc(16px)',
                              paddingTop: '16px',
                              // marginTop: '-12px',
                            }
                      }
                    >
                      <div
                        className="position-absolute top-0 left-0 w-100 h-100 zindex-0"
                        style={{
                          backgroundColor: serviceItem?.backgroundColor,
                          opacity: `${(3 + index) / (2 + serviceFeatureContentList.length)}`,
                        }}
                      ></div>
                      <p
                        className="mb-0 flex-grow-1 zindex-1"
                        style={{
                          fontWeight: 400,
                          fontSize: '1.2rem',
                          color: AppResource.colors.textPrimary,
                        }}
                      >
                        {item.name}
                      </p>

                      <p
                        className="mb-0 flex-grow-1 text-right zindex-1"
                        style={{
                          fontWeight: 600,
                          fontSize: '1.4rem',
                          color: AppResource.colors.textPrimary,
                        }}
                      >
                        {_.isArray(item.value) ? (
                          <>
                            {item.value.map((url, urlIndex) => {
                              return (
                                <img
                                  src={url}
                                  key={urlIndex}
                                  className="w-30px h-30px ml-2"
                                  style={{
                                    objectFit: 'contain',
                                  }}
                                />
                              );
                            })}
                          </>
                        ) : (
                          item.value
                        )}
                      </p>
                    </div>
                  );
                })}
                {/* <div
                className="d-flex align-items-center justify-content-between px-10 position-relative overflow-hidden"
                style={{
                  borderRadius: '12px',
                  paddingBottom: 'calc(16px)',
                  paddingTop: '16px',
                  marginTop: '-12px',
                }}
              >
                <div
                  className="position-absolute top-0 left-0 w-100 h-100 zindex-0"
                  style={{
                    backgroundColor: serviceItem?.backgroundColor,
                    opacity: 1,
                  }}
                ></div>
                <p
                  className="mb-0 flex-grow-1 zindex-1"
                  style={{
                    fontWeight: 400,
                    fontSize: '1.2rem',
                    color: AppResource.colors.textPrimary,
                  }}
                >
                  {t('ExclusiveOffer')}
                </p>

                <p
                  className="mb-0 flex-grow-1 text-right zindex-1"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: AppResource.colors.textPrimary,
                  }}
                >
                  {serviceItem?.iconText}
                </p>
              </div> */}
              </div>

              {/* chi tiet uu dai */}
              <div
                className="mt-6 d-flex align-items-center justify-content-between cursor-pointer hover-opacity-60"
                onClick={() => {
                  setDetailShowing(!detailShowing);
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: AppResource.colors.textPrimary,
                  }}
                >
                  {t('PromotionalDetail')}
                </p>
                <img
                  src={AppResource.icons.icArrowDown}
                  className=""
                  style={{
                    transform: `rotate(${detailShowing ? '0' : '180'}deg)`,
                    // transition: 'all 0.3s ease-out',
                  }}
                />
              </div>
              {detailShowing && (
                <div
                  className="mt-2"
                  style={{
                    transition: 'all 0.3s ease-out',
                  }}
                >
                  <div
                    className="mb-0"
                    style={{
                      fontWeight: 400,
                      fontSize: '1.1rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: serviceDetail?.content }}></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <div
            className="d-flex row"
            style={{
              width: '60%',
            }}
          >
            <SaymeeButton
              text={isRegisterService ? t('CancelRegister') : t('Register')}
              className="saymee-primary-button px-10 h-50px"
              onPress={() => {
                if (isRegisterService) {
                  handleCancelRegister();
                } else {
                  handleRegister();
                }
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalServiceDetail;
