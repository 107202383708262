import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import AppResource from 'general/constants/AppResource';
import SearchHistoryHelper from 'general/helpers/SearchHistoryHelper';
import useConfig from 'hooks/useConfig';
import useSearchHistory from 'hooks/useSearchHistory';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

DropdownSearchView.propTypes = {
  onSearch: PropTypes.func,
  searchResult: PropTypes.object,
  searching: PropTypes.bool,
  onPressService: PropTypes.func,
  onPressPackage: PropTypes.func,
};

DropdownSearchView.defaultProps = {
  onSearch: null,
  searchResult: null,
  searching: false,
  onPressService: null,
  onPressPackage: null,
};

function DropdownSearchView(props) {
  // MARK: --- Params ---
  const { onSearch, searchResult, searching, onPressService, onPressPackage } = props;
  const { t } = useTranslation();
  const { hotSearchKeywords } = useConfig();
  const { searchHistoryList } = useSearchHistory();

  const searchResultData = useMemo(() => {
    if (!!searchResult) {
      return searchResult.data;
    }
    return null;
  }, [searchResult]);

  return (
    <div
      className="bg-white px-6 py-6 position-relative overflow-hidden"
      style={{
        boxShadow: '0px 0px 2px rgba(227, 29, 147, 0.04), 0px 4px 20px rgba(227, 29, 147, 0.06)',
        borderRadius: '20px',
      }}
    >
      {searching && (
        <div className="position-absolute bg-white w-100 h-100 top-0 right-0">
          <SaymeeEmpty
            loading={true}
            loadingText={`${t('Searching')}...`}
            iconClassName="w-140px h-140px"
          />
        </div>
      )}
      {!!searchResultData ? (
        <div>
          {/* Goi */}
          <div>
            <p
              className="mb-0"
              style={{
                color: AppResource.colors.textPrimary,
                fontWeight: 600,
                fontSize: '1.1rem',
              }}
            >
              {t('Packages')}
            </p>

            {searchResultData?.packages?.length > 0 ? (
              <div className="d-flex flex-wrap mt-4">
                {searchResultData?.packages?.map((item, index) => {
                  return (
                    <KTTooltip text={''} key={index}>
                      <div
                        className="mr-4 mb-3 d-flex align-items-center cursor-pointer hover-opacity-60"
                        style={{
                          backgroundColor: item?.backgroundColor ?? '#F7F7F7',
                          height: '32px',
                          borderRadius: '16px',
                        }}
                        onClick={() => {
                          if (onPressPackage) {
                            onPressPackage({ ...item, packageId: item?.id });
                          }
                        }}
                      >
                        <p
                          className="mb-0 px-4"
                          style={{
                            color: item?.contentColor ?? '#181C32',
                          }}
                        >
                          {item?.title}
                        </p>
                      </div>
                    </KTTooltip>
                  );
                })}
              </div>
            ) : (
              <SaymeeEmpty icon={null} message={t('NotFoundPackages')} />
            )}
          </div>

          {/* Dich vu */}
          <div className="mt-4">
            <p
              className="mb-0"
              style={{
                color: AppResource.colors.textPrimary,
                fontWeight: 600,
                fontSize: '1.1rem',
              }}
            >
              {t('Services')}
            </p>
            {searchResultData?.services?.length > 0 ? (
              <div className="mt-4 row">
                {searchResultData?.services?.map((item, index) => {
                  return (
                    <div className="col-6" key={index}>
                      <KTTooltip text="">
                        <div
                          className="d-flex align-items-center cursor-pointer hover-opacity-60 px-2 py-2 rounded-lg"
                          style={{
                            backgroundColor: item?.backgroundColor ?? '#F7F7F7',
                            // height: '32px',
                            // borderRadius: '16px',
                          }}
                          onClick={() => {
                            if (onPressService) {
                              onPressService({ ...item, serviceId: item?.id });
                            }
                          }}
                        >
                          <img
                            src={item?.thumb}
                            className="w-40px h-40px"
                            style={{
                              objectFit: 'contain',
                            }}
                          />
                          <p
                            className="mb-0 px-4 font-weight-bold"
                            style={{
                              color: item?.textColor ?? '#181C32',
                              wordBreak: 'break-word',
                            }}
                          >
                            {item?.title}
                          </p>
                        </div>
                      </KTTooltip>
                    </div>
                  );
                })}
              </div>
            ) : (
              <SaymeeEmpty icon={null} message={t('NotFoundServices')} />
            )}
          </div>
        </div>
      ) : (
        <div>
          {/* tu khoa hot */}
          <div>
            <p
              className="mb-0"
              style={{
                color: AppResource.colors.textPrimary,
                fontWeight: 600,
                fontSize: '1.1rem',
              }}
            >
              {t('HotKeywords')}
            </p>
            <div className="d-flex flex-wrap mt-4">
              {hotSearchKeywords.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="mr-4 mb-3 d-flex align-items-center cursor-pointer hover-opacity-60"
                    style={{
                      backgroundColor: '#F7F7F7',
                      height: '32px',
                      borderRadius: '16px',
                    }}
                    onClick={() => {
                      if (onSearch) {
                        onSearch(item);
                      }
                    }}
                  >
                    <p className="mb-0 px-4 text-dark">{item.trim()}</p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* lich su tim kiem */}
          {searchHistoryList.length > 0 && (
            <div className="mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <p
                  className="mb-0"
                  style={{
                    color: AppResource.colors.textPrimary,
                    fontWeight: 600,
                    fontSize: '1.1rem',
                  }}
                >
                  {t('SearchHistory')}
                </p>
                <a
                  href="#"
                  className="font-weight-bolder font-size-sm"
                  style={{
                    color: AppResource.colors.feature,
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    SearchHistoryHelper.clearSearchHistory();
                  }}
                >
                  {t('Delete')}
                </a>
              </div>
              <div className="d-flex flex-wrap mt-4">
                {searchHistoryList.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="mr-4 mb-3 d-flex align-items-center cursor-pointer hover-opacity-60"
                      style={{
                        backgroundColor: '#F7F7F7',
                        height: '32px',
                        borderRadius: '16px',
                      }}
                      onClick={() => {
                        if (onSearch) {
                          onSearch(item);
                        }
                      }}
                    >
                      <p className="mb-0 px-4 text-dark">{item}</p>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {/* goi y cho ban */}
        </div>
      )}
    </div>
  );
}

export default DropdownSearchView;
