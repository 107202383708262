import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

SaymeeEmpty.propTypes = {
  icon: PropTypes.any,
  message: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.string,
  iconClassName: PropTypes.string,
};

SaymeeEmpty.defaultProps = {
  icon: AppResource.images.imgDialogSuccess,
  message: '',
  loading: false,
  loadingText: null,
  iconClassName: '',
};

/**
 *
 * @param {{
 * icon: any,
 * message: string,
 * loading: boolean,
 * loadingText: string,
 * iconClassName: string,
 * }} props
 * @returns
 */
function SaymeeEmpty(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const { icon, message, loading, loadingText, iconClassName } = props;

  return (
    <div className="py-10 d-flex flex-column align-items-center">
      {icon && <img className={`${iconClassName}`} src={icon} style={{ objectFit: 'contain' }} />}
      <p className="mb-0 mt-6 text-center">
        {loading ? loadingText ?? `${t('Loading')}...` : message}
      </p>
    </div>
  );
}

export default SaymeeEmpty;
