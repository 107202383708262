import React from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import LoyaltyHomeScreen from './screens/LoyaltyHomeScreen';
import PointHistoryScreen from './screens/PointHistoryScreen';
import MyVoucherScreen from './screens/MyVoucherScreen';

Loyalty.propTypes = {};

function Loyalty(props) {
  return (
    <Routes>
      <Route path="" element={<LoyaltyHomeScreen />} />
      <Route path="point-history" element={<PointHistoryScreen />} />
      <Route path="my-voucher" element={<MyVoucherScreen />} />
      {/* <Route path="utilities" element={<UtilitiesScreen />} />
      <Route path="fee-check" element={<FeeCheckAndHistoryScreen />} />
      <Route path="help-download-app" element={<HelpDownloadScreen />} />
      <Route path="history-recharge-money" element={<HistoryRechargeMoneyScreen />} />
      />
      <Route path="transaction-detail/:type" element={<TransactionDetailScreen />} />
      <Route path="transaction-detail/*" element={<Navigate to="/saymee/account/fee-check" />} />
      <Route path="loyalty" element={<>Loyalty</>} /> */}
    </Routes>
  );
}

export default Loyalty;
