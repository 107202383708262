import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

MenuItem.propTypes = {
  icon: PropTypes.any,
  text: PropTypes.string,
  onPress: PropTypes.func,
};

MenuItem.defaultProps = {
  icon: null,
  text: '',
  onPress: null,
};

/**
 *
 * @param {{
 * icon: any,
 * text: string,
 * onPress: function,
 * }} props
 * @returns
 */
function MenuItem(props) {
  // MARK: --- Params ---
  const { icon, text, onPress } = props;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="d-flex align-items-center px-6 py-4 w-100 cursor-pointer hover-opacity-60 h-100"
      style={{
        boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.06), 0px 12px 32px rgba(0, 0, 0, 0.04)',
        borderRadius: '16px',
      }}
      onClick={handlePress}
    >
      <img src={icon} className="w-40px" />
      <p
        className="mb-0 ml-4"
        style={{
          fontWeight: 500,
          fontSize: '1.3rem',
          color: AppResource.colors.textPrimary,
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default MenuItem;
