// icons logo
import keenLogo1 from 'assets/icons/Keens/Logo/logo-1.svg';
import keenLogo2 from 'assets/icons/Keens/Logo/logo-2.svg';
import keenLogo3 from 'assets/icons/Keens/Logo/logo-3.svg';
import keenLogo4 from 'assets/icons/Keens/Logo/logo-4.svg';
import keenLogo5 from 'assets/icons/Keens/Logo/logo-5.svg';
import keenLogo6 from 'assets/icons/Keens/Logo/logo-6.svg';
import keenLogo7 from 'assets/icons/Keens/Logo/logo-7.svg';
// icons text
import keenIcToggleRight from 'assets/icons/Keens/Text/Toggle-Right.svg';
import keenIcToggleLeft from 'assets/icons/Keens/Text/Toggle-Left.svg';
// icons general
import keenIcSearch from 'assets/icons/Keens/General/Search.svg';
import keenIcUser from 'assets/icons/Keens/General/User.svg';
import keenIcShieldCheck from 'assets/icons/Keens/General/Shield-check.svg';
// icons avatar
import keenIcAvatarBoy from 'assets/icons/Keens/Avatars/001-boy.svg';
import keenIcAvatarGirl from 'assets/icons/Keens/Avatars/002-girl.svg';
// icons flag
import keenIcFlagEn from 'assets/icons/Keens/Flags/226-united-states.svg';
import keenIcFlagVi from 'assets/icons/Keens/Flags/220-vietnam.svg';
// icons code
import keenIcCompiling from 'assets/icons/Keens/Code/Compiling.svg';
import keenIcCmd from 'assets/icons/Keens/Code/CMD.svg';
import keenIcLockOverturning from 'assets/icons/Keens/Code/Lock-overturning.svg';
// icons media
import keenIcEqualizer from 'assets/icons/Keens/Media/Equalizer.svg';
// icons layout
import keenIcLayout4Blocks from 'assets/icons/Keens/Layout/Layout-4-blocks.svg';
import keenIcLayoutPolygon from 'assets/icons/Keens/Layout/Layout-polygon.svg';
// icons communication
import keenIcCommunicationChat from 'assets/icons/Keens/Communication/Chat6.svg';
import keenIcCommunicationMailBox from 'assets/icons/Keens/Communication/Mail-box.svg';
import keenIcCommunicationSpam from 'assets/icons/Keens/Communication/Spam.svg';
import keenIcCommunicationSafeChat from 'assets/icons/Keens/Communication/Safe-chat.svg';
import keenIcCommunicationSend from 'assets/icons/Keens/Communication/Send.svg';
import keenIcCommunicationMailAt from 'assets/icons/Keens/Communication/Mail-at.svg';
import keenIcCommunicationChatLocked from 'assets/icons/Keens/Communication/Chat-locked.svg';
import keenIcCommunicationGroupChat from 'assets/icons/Keens/Communication/Group-chat.svg';
import keenIcCommunicationThumbtack from 'assets/icons/Keens/Communication/Thumbtack.svg';
import keenIcCommunicationOutgoingBox from 'assets/icons/Keens/Communication/Outgoing-box.svg';
import keenIcCommunicationClipboardCheck from 'assets/icons/Keens/Communication/Clipboard-check.svg';
import keenIcCommunicationGroup from 'assets/icons/Keens/Communication/Group.svg';
// icons clothes
import keenIcBriefcase from 'assets/icons/Keens/Clothes/Briefcase.svg';
import keenIcCrown from 'assets/icons/Keens/Clothes/Crown.svg';
// icons navigations
import keenIcNavigationUp from 'assets/icons/Keens/Navigation/Up-2.svg';
import keenIcNavigationArrowLeft from 'assets/icons/Keens/Navigation/Arrow-left.svg';
// icons tools
import keenIcToolCompass from 'assets/icons/Keens/Tools/Compass.svg';
// icons design
import keenIcDesignFlatten from 'assets/icons/Keens/Design/Flatten.svg';
import keenIcDesignSubtract from 'assets/icons/Keens/Design/Substract.svg';
// icons home
import keenIcLibrary from 'assets/icons/Keens/Home/Library.svg';
// icons files
import keenIcFilePlus from 'assets/icons/Keens/Files/File-plus.svg';
// illustrations
import keenIllustrationPayment from 'assets/icons/Keens/Illustrations/payment.svg';
import keenIllustrationAccomplishment from 'assets/icons/Keens/Illustrations/accomplishment.svg';
// socials
import keenIcSocialGoogle from 'assets/icons/Keens/SocialIcons/google.svg';
// users
import keenIcUser1 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser2 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser3 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser4 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser5 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser6 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser7 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser8 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser9 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser10 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser11 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser12 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser13 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser14 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUser15 from 'assets/icons/Keens/Users/150-1.jpg';
import keenIcUserBlank from 'assets/icons/Keens/Users/blank.png';

// Saymee icons
import icSearch from 'assets/icons/ic_search.svg';
import icAvatarDefault from 'assets/icons/ic_avatar_default.svg';
import icAppStore from 'assets/icons/ic_app_store.svg';
import icCHPlay from 'assets/icons/ic_CH_play.svg';
import icLoyaltyPoint from 'assets/icons/ic_loyalty_point.svg';
import icRechargeMoney from 'assets/icons/ic_add_wallet.svg';
import icActiveSim from 'assets/icons/ic_active_sim.svg';
import icUpdateInfo from 'assets/icons/ic_add_wallet.svg';
import icArrowRight from 'assets/icons/ic_arrow_right.svg';
import icArrowDown from 'assets/icons/ic_arrow_down.svg';
import icServiceMusic from 'assets/icons/ic_service_music.svg';
import icServiceSport from 'assets/icons/ic_service_sport.svg';
import icPhone from 'assets/icons/ic_phone.svg';
import icServicePack from 'assets/icons/ic_service_pack.svg';
import icPhoneData from 'assets/icons/ic_phone-data.svg';
import icCaretRight from 'assets/icons/ic_caret-right.svg';
import icLocked from 'assets/icons/ic_locked.svg';
import icDataPack from 'assets/icons/ic_data_pack.svg';
import icClose from 'assets/icons/ic_close.svg';
import icEyeOn from 'assets/icons/ic_eye_on.svg';
import icEyeOff from 'assets/icons/ic_eye_off.svg';
import icMascot from 'assets/icons/ic_mascot.svg';

import imgLoyaltyGa from 'assets/images/img_loyalty_ga.svg';
import imgLoyaltyTep from 'assets/images/img_loyalty_tep.svg';
import imgLoyaltyTom from 'assets/images/img_loyalty_tom.svg';
import imgLoyaltyRuoi from 'assets/images/img_loyalty_ruoi.svg';
import imgLoyaltyVoi from 'assets/images/img_loyalty_voi.svg';
import imgLoyaltyKhungLong from 'assets/images/img_loyalty_khunglong.svg';
import imgEmotionToday from 'assets/images/img_emotion_today.png';

import imgChatBoxBg from 'assets/images/img_chat_box_bg.svg';
import imgMascotGift from 'assets/images/img_mascot_gift.svg';
import imgMood from 'assets/images/img_mood.svg';
import imgCareer from 'assets/images/img_career.svg';
import imgEmotional from 'assets/images/img_emotional.svg';

import imgDefaultGift from 'assets/images/img_default_gift.jpg';

import icLoyaltyIntro from 'assets/icons/ic_loyalty_intro.svg';
import icLoyaltyDiscount from 'assets/icons/ic_loyalty_discount.svg';
import icLoyaltyGift from 'assets/icons/ic_loyalty_gift.svg';
import icLoyaltyHistory from 'assets/icons/ic_loyalty_history.svg';

import icEmotionVui from 'assets/icons/ic_emotion_vui.svg';
import icEmotionBuon from 'assets/icons/ic_emotion_buon.svg';
import icEmotionNau from 'assets/icons/ic_emotion_nau.svg';
import icEmotionOk from 'assets/icons/ic_emotion_ok.svg';
import icCheckInStatusBuon from 'assets/icons/ic_checkin_status_buon.svg';
import icCheckInStatusNau from 'assets/icons/ic_checkin_status_nau.svg';
import icCheckInStatusOff from 'assets/icons/ic_checkin_status_off.svg';
import icCheckInStatusOn from 'assets/icons/ic_checkin_status_on.svg';
import icCheckInStatusOk from 'assets/icons/ic_checkin_status_ok.svg';
import icCheckInStatusVui from 'assets/icons/ic_checkin_status_vui.svg';

// heroscope
import icXuNu from 'assets/icons/ic_xunu.svg';
import icThienBinh from 'assets/icons/ic_thienbinh.svg';
import icSuTu from 'assets/icons/ic_sutu.svg';
import icSongTu from 'assets/icons/ic_songtu.svg';
import icSongNgu from 'assets/icons/ic_songngu.svg';
import icNhanMa from 'assets/icons/ic_nhanma.svg';
import icMaKet from 'assets/icons/ic_maket.svg';
import icKimNguu from 'assets/icons/ic_kimnguu.svg';
import icCuGiai from 'assets/icons/ic_cugiai.svg';
import icBoCap from 'assets/icons/ic_bocap.svg';
import icBaoBinh from 'assets/icons/ic_baobinh.svg';
import icBachDuong from 'assets/icons/ic_bachduong.svg';

import icSms from 'assets/icons/ic_sms.svg';
import icData from 'assets/icons/ic_data.svg';
import icCall from 'assets/icons/ic_call.svg';
import icCellPhone from 'assets/icons/ic_cellphone.svg';

import icLocation from 'assets/icons/ic_location.svg';
import icUrbox from 'assets/icons/ic_urbox.svg';
import icClock from 'assets/icons/ic_clock.svg';
import icPriceTag from 'assets/icons/ic_price_tag.svg';

// App resources: icons, images, fonts...
const AppResource = {
  // icons
  icons: {
    // keens
    keens: {
      // logo
      logo1: keenLogo1,
      logo2: keenLogo2,
      logo3: keenLogo3,
      logo4: keenLogo4,
      logo5: keenLogo5,
      logo6: keenLogo6,
      logo7: keenLogo7,
      // text
      toggleRight: keenIcToggleRight,
      toggleLeft: keenIcToggleLeft,
      // general
      search: keenIcSearch,
      user: keenIcUser,
      shieldCheck: keenIcShieldCheck,
      // avatar
      avatarBoy: keenIcAvatarBoy,
      avatarGirl: keenIcAvatarGirl,
      // flag
      flagEn: keenIcFlagEn,
      flagVi: keenIcFlagVi,
      // code
      compiling: keenIcCompiling,
      cmd: keenIcCmd,
      lockOverTurning: keenIcLockOverturning,
      // media
      equalizer: keenIcEqualizer,
      // layout
      layout4Blocks: keenIcLayout4Blocks,
      layoutPolygon: keenIcLayoutPolygon,
      // communication
      chat: keenIcCommunicationChat,
      mailbox: keenIcCommunicationMailBox,
      spam: keenIcCommunicationSpam,
      safeChat: keenIcCommunicationSafeChat,
      send: keenIcCommunicationSend,
      mailAt: keenIcCommunicationMailAt,
      chatLocked: keenIcCommunicationChatLocked,
      groupChat: keenIcCommunicationGroupChat,
      thumbtack: keenIcCommunicationThumbtack,
      outgoingTask: keenIcCommunicationOutgoingBox,
      clipboardCheck: keenIcCommunicationClipboardCheck,
      group: keenIcCommunicationGroup,
      // clothes
      briefcase: keenIcBriefcase,
      crown: keenIcCrown,
      // navigation
      up: keenIcNavigationUp,
      arrowLeft: keenIcNavigationArrowLeft,
      // tools
      compass: keenIcToolCompass,
      // design
      flatten: keenIcDesignFlatten,
      subtract: keenIcDesignSubtract,
      // home
      library: keenIcLibrary,
      // files
      filePlus: keenIcFilePlus,
      // illustrations
      illustrationPayment: keenIllustrationPayment,
      illustrationAccomplishment: keenIllustrationAccomplishment,
      // socials
      socialGoogle: keenIcSocialGoogle,
      // users
      user1: keenIcUser1,
      user2: keenIcUser2,
      user3: keenIcUser3,
      user4: keenIcUser4,
      user5: keenIcUser5,
      user6: keenIcUser6,
      user7: keenIcUser7,
      user8: keenIcUser8,
      user9: keenIcUser9,
      user10: keenIcUser10,
      user11: keenIcUser11,
      user12: keenIcUser12,
      user13: keenIcUser13,
      user14: keenIcUser14,
      user15: keenIcUser15,
      userBlank: keenIcUserBlank,
    },

    // saymee
    icSearch,
    icAvatarDefault,
    icAppStore,
    icCHPlay,
    icLoyaltyPoint,
    icRechargeMoney,
    icActiveSim,
    icUpdateInfo,
    icArrowRight,
    icArrowDown,
    icServiceMusic,
    icServiceSport,
    icPhone,
    icServicePack,
    icPhoneData,
    icCaretRight,
    icLocked,
    icDataPack,
    icClose,
    icEyeOn,
    icEyeOff,
    icMascot,
    icLoyaltyDiscount,
    icLoyaltyGift,
    icLoyaltyHistory,
    icLoyaltyIntro,

    icEmotionVui,
    icEmotionBuon,
    icEmotionNau,
    icEmotionOk,

    icCheckInStatusBuon,
    icCheckInStatusNau,
    icCheckInStatusOff,
    icCheckInStatusOk,
    icCheckInStatusOn,
    icCheckInStatusVui,

    // heroscope
    icXuNu,
    icThienBinh,
    icSuTu,
    icSongTu,
    icSongNgu,
    icNhanMa,
    icMaKet,
    icKimNguu,
    icCuGiai,
    icBoCap,
    icBaoBinh,
    icBachDuong,

    icData,
    icCellPhone,
    icSms,
    icCall,

    icLocation,
    icUrbox,
    icClock,
    icPriceTag,
  },

  // images
  images: {
    // keens
    keens: {
      bg1: require('assets/images/Keens/bg1.jpg'),
      bg2: require('assets/images/Keens/bg2.jpg'),
      bg3: require('assets/images/Keens/bg3.jpg'),
    },

    // saymee
    imgCheckedGov: require('assets/images/img_checked_gov.png'),
    imgSaymeeLogo: require('assets/images/img_saymee_logo.png'),
    imgAuthSignIn: require('assets/images/img_auth_signin.jpg'),
    imgAuthForgot: require('assets/images/img_auth_forgot.jpg'),
    imgSaymee404: require('assets/images/img_saymee_404.png'),
    imgFailedNoti: require('assets/images/img_saymee_failed_noti.png'),
    imgDownloadApp: require('assets/images/img_saymee_download_app.png'),
    imgDialogSignOut: require('assets/images/img_dialog_signout.png'),
    imgDialogSuccess: require('assets/images/img_dialog_success.png'),
    imgDialogError: require('assets/images/img_dialog_error.png'),
    // loyalty levels
    imgLoyaltyGa,
    imgLoyaltyKhungLong,
    imgLoyaltyRuoi,
    imgLoyaltyTep,
    imgLoyaltyTom,
    imgLoyaltyVoi,

    imgEmotionToday,
    imgMascotGift,
    imgChatBoxBg,

    imgCareer,
    imgEmotional,
    imgMood,
    imgDefaultGift,
  },

  // colors
  colors: {
    primary: '#E31D93',
    secondary: '#19D3C5',
    secondary02: '#0071CE',
    feature: '#E31D93',
    darkGrey: '#262224',
    grey: '#5A5C6F',
    grey50: '#ACACBB',
    lightGreyStroke: '#E0E2ED',
    lineIndicator: '#E8EFF4',
    lightGrey50: '#F1F5F8',
    white: '#ffffff',
    featureBlue: '#0066C7',
    featureYellow: '#FF9427',
    featureGreen: '#18CDBD',
    red: '#CD1F13',
    textSecondary: '#6B686A',
    textPrimary: '#262224',
    placeholder: '#A8A8A8',
    black: '#000',
    error01: '#9E0038',
    success: '#0071CE',
    neutral: '#EBEBEB',
  },
};

export default AppResource;
