import authApi from 'api/authApi';
import { FastField, Form, Formik } from 'formik';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTFormGroup from 'general/components/OtherKeenComponents/Forms/KTFormGroup';
import KTFormInput, {
  KTFormInputType,
} from 'general/components/OtherKeenComponents/Forms/KTFormInput';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Utils from 'general/utils/Utils';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import { setCurrentAccount } from 'modules/Saymee/features/Account/accountSlice';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ClipLoader from 'react-spinners/ClipLoader';
import * as Yup from 'yup';

SignInView.propTypes = {};

function SignInView(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const router = useRouter();
  const [signingIn, setSigningIn] = useState(false);
  const [otpLoginValid, setOtpLoginValid] = useState(() => {
    const savedOtpLoginExpiredTime = localStorage.getItem(PreferenceKeys.savedOtpLoginExpiredTime);
    if (savedOtpLoginExpiredTime) {
      return moment(savedOtpLoginExpiredTime).isAfter(moment());
    }
    return false;
  });
  const [otpRemainingSeconds, setOtpRemainingSeconds] = useState(-1);
  // console.log({ otpRemainingSeconds });
  const refButtonSignInWithOTP = useRef();

  // MARK: --- Hooks ---
  useEffect(() => {
    let intervalOTP = null;
    const savedOtpLoginExpiredTime = localStorage.getItem(PreferenceKeys.savedOtpLoginExpiredTime);
    if (savedOtpLoginExpiredTime) {
      const momentSavedOtpLoginExpiredTime = moment(savedOtpLoginExpiredTime);

      intervalOTP = setInterval(() => {
        const remainingSeconds = momentSavedOtpLoginExpiredTime.diff(moment(), 'seconds');
        // console.log({ remainingSeconds });
        setOtpRemainingSeconds(remainingSeconds);
        if (remainingSeconds <= 0) {
          localStorage.removeItem(PreferenceKeys.savedOtpLoginExpiredTime);
          setOtpLoginValid(false);
          clearInterval(intervalOTP);
          setOtpRemainingSeconds(-1);
        }
      }, 1000);
    }

    return () => {
      if (intervalOTP) {
        clearInterval(intervalOTP);
      }
    };
  }, [otpLoginValid]);

  // MARK: --- Functions ---
  async function requestSignInWithPassword(phone, password) {
    try {
      setSigningIn(true);
      const res = await authApi.passwordLogin(phone, password);
      const { data, errors, dialog, message } = res;
      if (data && _.isObject(data)) {
        dispatch(setCurrentAccount(data));
        router.navigate('/saymee/account/info');
      } else if (errors && !_.isEmpty(errors)) {
        const error = errors[0];
        if (error?.message) {
          // ToastHelper.showError(error?.message);
          AppDialogHelper.show(
            `${t('Oops')}! ${t('Failed')}`,
            error?.message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      } else if (dialog) {
        const { title, message } = dialog;
        if (message) {
          AppDialogHelper.show(
            title ?? 'Oops!',
            message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      } else if (message) {
        AppDialogHelper.show(
          'Oops!',
          message,
          [
            {
              title: t('Close'),
              type: 'positive',
              onClick: () => {
                AppDialogHelper.hide();
              },
            },
          ],
          AppResource.images.imgDialogError,
          AppResource.colors.error01
        );
      }
    } catch (error) {
      if (error === 1001) {
        // Chuyen sang man login bang OTP
        if (refButtonSignInWithOTP.current) {
          refButtonSignInWithOTP.current.click();
        }
      }
    }
    setSigningIn(false);
  }

  async function requestSignInWithOTP(phone, otp) {
    try {
      setSigningIn(true);
      const res = await authApi.otpLogin(phone, otp);
      const { data, errors } = res;
      if (data) {
        dispatch(setCurrentAccount(data));
        router.navigate('/saymee/account/info');
      } else if (errors) {
        const error = errors[0];
        if (error?.message) {
          // ToastHelper.showError(error?.message);
          AppDialogHelper.show(
            `${t('Oops')}! ${t('Failed')}`,
            error?.message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      }
    } catch (error) {
      console.log(`Sign in error: ${error}`);
    }
    setSigningIn(false);
  }

  async function requestGetLoginOTP(phone) {
    try {
      const res = await authApi.getLoginOTP(phone);
      const { data, errors } = res;
      if (data) {
        // Yc ma otp dang nhap thanh cong
        AppDialogHelper.show(
          `${t('Yay')}! ${t('Success')}`,
          t('OTPLoginCodeWasSentSuccess'),
          [
            {
              title: t('Close'),
              type: 'positive',
              onClick: () => {
                AppDialogHelper.hide();
              },
            },
          ],
          AppResource.images.imgDialogSuccess,
          AppResource.colors.success
        );
        setOtpLoginValid(true);
        const expiredTime = moment().add(1, 'minutes').toISOString();
        localStorage.setItem(PreferenceKeys.savedOtpLoginExpiredTime, expiredTime);
        setOtpRemainingSeconds(59);
      } else if (errors) {
        const error = errors[0];
        if (error?.message) {
          AppDialogHelper.show(
            `${t('Oops')}! ${t('Failed')}`,
            error?.message,
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogError,
            AppResource.colors.error01
          );
        }
      }
    } catch (error) {
      console.log(`GetLoginOTP error: ${error}`);
    }
  }

  return (
    <>
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '#', active: false },
          { title: t('SignIn'), url: '#', active: true },
        ]}
      />
      <div className="row my-6">
        <div className="col-md-6 d-none d-md-flex">
          <img
            className="w-100"
            src={AppResource.images.imgAuthSignIn}
            style={{
              objectFit: 'cover',
              borderRadius: '20px',
            }}
          />
        </div>

        <div className="col-md-6">
          <Formik
            initialValues={{
              otpMode: false,
              phone: '',
              password: '',
              otp: '',
            }}
            validationSchema={Yup.object().shape({
              phone: Yup.string()
                .trim()
                .required(t('PhoneIsRequired'))
                .matches(AppData.regexSamples.phoneRegex, t('PhoneNotValid')),
              password: Yup.string()
                .when('otpMode', {
                  is: (otpMode) => otpMode === false,
                  then: Yup.string().trim().required(t('PasswordIsRequired')),
                })
                .when('otpMode', {
                  is: (otpMode) => otpMode === true,
                  then: Yup.string().trim().nullable(),
                }),
              otp: Yup.string()
                .when('otpMode', {
                  is: (otpMode) => otpMode === true,
                  then: Yup.string().trim().required(t('OTPCodeIsRequired')),
                })
                .when('otpMode', {
                  is: (otpMode) => otpMode === true,
                  then: Yup.string().trim().nullable(),
                }),
            })}
            enableReinitialize
            onSubmit={(values) => {
              if (values.otpMode) {
                requestSignInWithOTP(values.phone, values.otp);
              } else {
                requestSignInWithPassword(values.phone, Utils.sha256(values.password));
              }
            }}
          >
            {(formikProps) => (
              <Form>
                <div className="px-xl-10 px-lg-10 px-md-5 mt-sm-5">
                  <p
                    className="text-center mt-6 text-uppercase text-dark"
                    style={{
                      fontWeight: 700,
                      fontSize: '1.6rem',
                    }}
                  >
                    {t('SignInAccount')}
                  </p>

                  <div className="d-flex flex-column pb-6">
                    <KTFormGroup
                      label={t('PhoneNumber')}
                      inputName="phone"
                      inputElement={
                        <FastField name="phone">
                          {({ field, form, meta }) => (
                            <KTFormInput
                              name={field.name}
                              value={field.value}
                              onChange={(value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              onBlur={() => {
                                form.setFieldTouched(field.name, true);
                              }}
                              enableCheckValid={true}
                              isValid={_.isEmpty(meta.error)}
                              isTouched={meta.touched}
                              feedbackText={meta.error}
                              type={KTFormInputType.text}
                              placeholder={`${_.capitalize(t('InputPhoneNumber'))}`}
                            />
                          )}
                        </FastField>
                      }
                    />

                    {formikProps.values.otpMode && (
                      <KTFormGroup
                        label={t('OTPCode')}
                        inputName="otp"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="otp">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid={true}
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.number}
                                placeholder={`${_.capitalize(t('InputOtpCode'))}`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    )}

                    {!formikProps.values.otpMode && (
                      <KTFormGroup
                        label={t('Password')}
                        inputName="password"
                        additionalClassName="mb-4"
                        inputElement={
                          <FastField name="password">
                            {({ field, form, meta }) => (
                              <KTFormInput
                                name={field.name}
                                value={field.value}
                                onChange={(value) => {
                                  form.setFieldValue(field.name, value);
                                }}
                                onBlur={() => {
                                  form.setFieldTouched(field.name, true);
                                }}
                                enableCheckValid={true}
                                isValid={_.isEmpty(meta.error)}
                                isTouched={meta.touched}
                                feedbackText={meta.error}
                                type={KTFormInputType.password}
                                placeholder={`${_.capitalize(t('InputPassword'))}`}
                              />
                            )}
                          </FastField>
                        }
                      />
                    )}

                    {!formikProps.values.otpMode && (
                      <a
                        href="#"
                        className="saymee-link align-self-end"
                        style={{
                          fontWeight: 600,
                          fontSize: '0.9rem',
                          color: AppResource.colors.feature,
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          router.navigate('/auth/forgot-password');
                        }}
                      >
                        {t('ForgotPassword')}?
                      </a>
                    )}

                    {formikProps.values.otpMode && (
                      <div className="align-self-end">
                        <a
                          href="#"
                          className={`${otpLoginValid ? 'text-dark-50' : 'saymee-link'}`}
                          style={{
                            fontWeight: 600,
                            fontSize: '0.9rem',
                            color: AppResource.colors.feature,
                            pointerEvents: otpRemainingSeconds < 0 ? '' : 'none',
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            requestGetLoginOTP(formikProps.values.phone);
                          }}
                        >
                          {!otpLoginValid ? t('GetOTPCode') : t('ResendOTPCode')}
                          {otpLoginValid && (
                            <>
                              <i
                                className="far fa-undo ml-2 fa-1x"
                                style={{
                                  color:
                                    otpRemainingSeconds < 0
                                      ? AppResource.colors.feature
                                      : '#7E8299',
                                }}
                              />{' '}
                              {`(${otpRemainingSeconds}s)`}
                            </>
                          )}
                        </a>
                      </div>
                    )}
                  </div>

                  <div className="d-flex flex-column align-items-center w-100">
                    <button type="submit" hidden className="d-none"></button>
                    <SaymeeButton
                      text={t('SignIn')}
                      disabled={signingIn}
                      style={{
                        height: '3.6rem',
                        width: '100%',
                      }}
                      onPress={() => {
                        formikProps.handleSubmit();
                      }}
                      icon={
                        signingIn ? <ClipLoader color="#fff" className="mr-2" size={20} /> : <></>
                      }
                    />
                    <p
                      className="mb-0 py-4"
                      style={{
                        fontWeight: 400,
                        fontSize: '1rem',
                        color: AppResource.colors.placeholder,
                      }}
                    >
                      {t('Or')}
                    </p>
                    {formikProps.values.otpMode && (
                      <SaymeeButton
                        text={t('SignInWithPassword')}
                        className="saymee-primary-outline-button"
                        style={{
                          height: '3.6rem',
                          width: '100%',
                        }}
                        onPress={() => {
                          formikProps.setFieldValue('otpMode', false);
                          formikProps.setFieldTouched('phone', false);
                          formikProps.setFieldTouched('otp', false);
                          formikProps.setFieldTouched('password', false);
                        }}
                      />
                    )}

                    {!formikProps.values.otpMode && (
                      <SaymeeButton
                        refButton={refButtonSignInWithOTP}
                        text={t('SignInWithOTP')}
                        className="saymee-primary-outline-button"
                        style={{
                          height: '3.6rem',
                          width: '100%',
                        }}
                        onPress={() => {
                          formikProps.setFieldValue('otpMode', true);
                          formikProps.setFieldTouched('phone', false);
                          formikProps.setFieldTouched('otp', false);
                          formikProps.setFieldTouched('password', false);
                        }}
                      />
                    )}
                  </div>

                  <div>
                    <p
                      className="mt-10"
                      style={{
                        fontWeight: 400,
                        fontSize: '1rem',
                        color: AppResource.colors.placeholder,
                      }}
                    >
                      Bằng việc đăng nhập, quý khách đã đồng ý thực hiện mọi giao dịch theo{' '}
                      <span
                        className="cursor-pointer"
                        style={{
                          color: AppResource.colors.primary,
                          textDecorationLine: 'underline',
                          fontWeight: '600',
                        }}
                      >
                        điều kiện sử dụng và chính sách của Saymee
                      </span>
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default SignInView;
