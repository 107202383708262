import React from 'react';
import PropTypes from 'prop-types';

SaymeeButton.propTypes = {
  icon: PropTypes.element,
  text: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  textStyle: PropTypes.object,
  onPress: PropTypes.func,
  disabled: PropTypes.bool,
  refButton: PropTypes.any,
};

SaymeeButton.defaultProps = {
  icon: null,
  text: '',
  className: 'saymee-primary-button',
  style: {},
  textStyle: {},
  onPress: null,
  disabled: false,
  refButton: null,
};

function SaymeeButton(props) {
  // MARK --- Params: ---
  const { icon, text, className, style, onPress, textStyle, disabled, refButton } = props;

  // MARK --- Functions: ---
  function handlePress() {
    if (disabled) {
      return;
    }
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      ref={refButton}
      className={`d-flex justify-content-center align-items-center flex-row py-3 cursor-pointer ${className}`}
      style={{
        ...style,
        pointerEvents: disabled ? 'none' : '',
      }}
      onClick={handlePress}
    >
      {/* icon */}
      {icon}

      {/* text */}
      <span
        className={`${icon ? 'ml-2' : ''}`}
        style={{
          fontWeight: 600,
          fontSize: '1.1rem',
          whiteSpace: 'nowrap',
          ...textStyle,
        }}
      >
        {text}
      </span>
    </div>
  );
}

export default SaymeeButton;
