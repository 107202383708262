import _ from 'lodash';
import moment from 'moment';

const TransactionHelper = {
  groupTransactionList: (transactionList, timeFormat = 'YYYY-MM-DD HH:mm:ss') => {
    if (transactionList && _.isArray(transactionList)) {
      const formatTransactionList = transactionList.map((item) => {
        return {
          ...item,
          date: moment(item.time, timeFormat).format('YYYY-MM-DD'),
          time: moment(item.time, timeFormat).format('HH:mm'),
        };
      });
      const groupedTransactionList = _.groupBy(formatTransactionList, 'date');
      return groupedTransactionList;
    }

    return {};
  },

  /**
   *
   * @param {object[]} transactionList
   * @param {string} filterType all | increment | decrement
   * @returns
   */
  groupPointHistoryList: (transactionList, filterType = 'all') => {
    if (transactionList && _.isArray(transactionList)) {
      const groupedTransactionList = _.groupBy(transactionList, 'monthYear');
      Object.keys(groupedTransactionList).forEach((key) => {
        const value = groupedTransactionList[key];
        if (_.isArray(value)) {
          let items = value;
          if (filterType === 'increment') {
            items = _.filter(value, (item) => item.point > 0);
          }
          if (filterType === 'decrement') {
            items = _.filter(value, (item) => item.point < 0);
          }
          const totalIncrementPoints = _.filter(items, (item) => item.point > 0).reduce(
            (sum, number) => {
              return sum + number?.point;
            },
            0
          );
          const totalDecrementPoints = _.filter(items, (item) => item.point < 0).reduce(
            (sum, number) => {
              return sum + number?.point;
            },
            0
          );
          groupedTransactionList[key] = {
            incrementPoints: totalIncrementPoints,
            decrementPoints: totalDecrementPoints,
            items,
          };
        }
      });
      return groupedTransactionList;
    }

    return {};
  },
};

export default TransactionHelper;
