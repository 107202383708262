// All app configs: app name, email, phone, copyright...
const AppConfigs = {
  // enable show log
  enableLog: true,

  packageName: 'com.mobifone.saymee',
  appName: '',
  version: '1.0',
  build: 1,
  downloadAppLink: 'https://saymee.vn/download',
  loginApiSecret: 'UEJ34gtH345DFG45G3ht1',
  iosAppStore: 'https://apps.apple.com/vn/app/saymee/id1661512595',
  androidAppStore: 'https://play.google.com/store/apps/details?id=vn.saymee.android',
};

export default AppConfigs;
