import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeColumnChart from 'general/components/SaymeeOtherComponents/SaymeeColumnChart';
import useAccount from 'hooks/useAccount';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FeeCheck from '../../components/FeeCheck';

FeeCheckAndHistoryScreen.propTypes = {};

function FeeCheckAndHistoryScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const tabs = [t('FeeCheck'), t('PaymentHistory')];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { transactionHistoryList } = useAccount();
  const chartSeries = useMemo(() => {
    return transactionHistoryList?.map((item) => {
      return (
        parseInt(item?.call ?? 0) +
        parseInt(item?.cvqt ?? 0) +
        parseInt(item?.vas ?? 0) +
        parseInt(item?.data ?? 0) +
        parseInt(item?.package ?? 0)
      );
    });
  }, [transactionHistoryList]);
  const chartLabels = useMemo(() => {
    return transactionHistoryList?.map((item) => {
      const momentBillCycle = moment(item?.billCycle);
      return `${t('Month')} ${momentBillCycle?.get('month') + 1} / ${momentBillCycle?.get('year')}`;
    });
  }, [transactionHistoryList]);

  // MARK: --- Functions ---
  function handleSelectedTab(newTab) {
    setSelectedTab(newTab);
  }

  return (
    <div>
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account/info', active: false },
          { title: t('FeeCheck'), url: '#', active: true },
        ]}
      />
      <div
        className="rounded-4 d-flex justify-content-center pb-12 mt-6"
        style={{
          background:
            'linear-gradient(231.16deg, rgba(25, 211, 197, 0.06) 21.09%, rgba(0, 113, 206, 0.06) 84.86%)',
        }}
      >
        <div className="w-100 w-md-50 mt-6 mt-md-10 px-6 px-md-0">
          <div>
            <FeeCheck />
          </div>
          {/* <SaymeeSecment
            tabs={tabs}
            activeTab={selectedTab}
            handleClick={handleSelectedTab}
            className="d-flex justify-content-center align-items-center"
          />
          <div>
            {selectedTab === t('FeeCheck') ? (
              <FeeCheck />
            ) : selectedTab === t('PaymentHistory') ? (
              <PaymentHistory />
            ) : (
              <></>
            )}
          </div> */}
        </div>
      </div>
      {selectedTab === t('FeeCheck') ? (
        <SaymeeColumnChart
          title={t('FareHistory')}
          chartLabels={chartLabels}
          chartSeries={chartSeries}
        />
      ) : (
        <></>
      )}
    </div>
  );
}

export default FeeCheckAndHistoryScreen;
