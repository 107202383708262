import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useConfig() {
  // MARK: --- Params ---
  const configData = useSelector((state) => state.app.configData);

  return useMemo(() => {
    // tu khoa tim kiem hot
    let hotSearchKeywords = ['Sim số đẹp', 'Voucher', 'Khuyến mãi HOT', 'Gói cước', 'Bói tình yêu'];
    const textHotKeySearch = configData?.hotKeySearch;
    if (textHotKeySearch) {
      hotSearchKeywords = _.split(textHotKeySearch, ',');
    }

    return {
      hotSearchKeywords,
    };
  }, [configData]);
}

export default useConfig;
