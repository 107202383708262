import AppConfigs from 'general/constants/AppConfigs';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const authApi = {
  /**
   * Dang nhap bang mat khau
   * @param {string} phone SDT dang nhap
   * @param {string} password Mat khau (sha256)
   * @returns
   */
  passwordLogin: (phone, password) => {
    const url = '/auth/passwordlogin';
    const params = {
      phone,
      password,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * Lay lai api token
   * @param {number} userId Id user
   * @param {string} refreshKey
   */
  refreshLogin: (userId, refreshKey) => {
    const url = '/auth/refreshlogin';
    const params = {
      user_id: userId,
      refresh_key: refreshKey,
    };
    return axiosClient.post(url, params, {
      headers: {
        phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
      },
    });
  },

  /**
   * Dang nhap bang OTP
   * @param {string} phone SDT dang nhap
   * @param {string} otp Ma OTP dang nhap
   * @returns
   */
  otpLogin: (phone, otp) => {
    const url = '/auth/otplogin';
    const params = {
      phone,
      otp,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * Dang xuat
   * @returns
   */
  logout: () => {
    const url = '/auth/logout';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Yeu cau lay ma OTP de dang nhap
   * @param {string} phone SDT yeu cau
   * @returns
   */
  getLoginOTP: (phone) => {
    const url = '/auth/getloginotp';
    const params = {
      phone,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * Yeu cau lay ma OTP
   * @param {string} prefix OTP prefix
   * @param {string} phone SDT yeu cau
   * @returns
   */
  getOTP: (prefix, phone) => {
    const url = '/auth/getotp';
    const params = {
      prefix,
      phone,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * Thuc hien phuc hoi mat khau
   * @param {string} password Mat khau moi (sha256)
   * @param {string} phone SDT
   * @param {string} otp Ma OTP
   * @returns
   */
  recoverPassword: (password, phone, otp) => {
    const url = '/auth/recoverpass';
    const params = {
      password,
      phone,
      otp,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * THuc hien doi mat khau
   * @param {string} password Mat khau moi (sha256)
   * @param {*} oldPassword Mat khau cu (sha256)
   * @returns
   */
  changePassword: (password, oldPassword) => {
    const url = '/auth/changepassword2';
    const params = {
      password,
      old_password,
    };
    return axiosClient.post(url, params, {
      headers: {
        apisecret: process.env.REACT_APP_LOGIN_API_SECRET ?? AppConfigs.loginApiSecret,
      },
    });
  },

  /**
   * Get OTP Factor 2
   * @param {string} phone SDT
   * @param {string} functionName
   */
  getOTPFactor2: (phone, functionName) => {
    const url = '/auth/getotpfactor2';
    const params = {
      phone,
      function_name: functionName,
    };

    return axiosClient.post(url, params, {
      headers: {
        phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
      },
    });
  },

  /**
   * Verify OTP Factor 2
   * @param {string} otp Ma otp
   * @param {number} type 1 - STP, 2 - LTP
   */
  verifyOTPFactor2: (otp, type = 1) => {
    const url = '/auth/verifyotpfactor2';
    const params = {
      otp,
      type,
    };

    return axiosClient.post(url, params, {
      headers: {
        phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
      },
    });
  },
};

export default authApi;
