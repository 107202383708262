import AppResource from './AppResource';

const AppData = {
  // regex
  regexSamples: {
    phoneRegex:
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
    urlRegex:
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
  },

  otpPrefix: {
    smartOtp: 'smartOTP',
    otp: 'OTP',
    otp3G4G: 'OTP3G4G',
    forgetOtp: 'forgetOTP',
    addPhoneOtp: 'AddPhoneOTP',
    otpFactor2: 'OTPFactor2',
    rechargeCardOtp: 'RechargeCardOTP',
    esimOtp: 'esimOTP',
    transfer: 'Transfer',
    voucherLoyalty: 'voucherLoyalty',
    changePhysicSim: 'changePhysicSim',
    biometric: 'Biometric',
    deleteAccount: 'deleteAccount',
  },

  transactionBillType: {
    call: 1,
    sms: 2,
    data: 3,
    service: 4,
    cvqt: 5,
    package: 6,
  },

  loyaltyLevelItems: [
    { fromPoint: 0, toPoint: 999 },
    { fromPoint: 1000, toPoint: 1999 },
    { fromPoint: 2000, toPoint: 7999 },
    { fromPoint: 8000, toPoint: 17999 },
    { fromPoint: 18000, toPoint: 49999 },
    { fromPoint: 50000, toPoint: 1000000000 },
  ],
  loyaltyLevelAvatars: [
    AppResource.images.imgLoyaltyTep,
    AppResource.images.imgLoyaltyTom,
    AppResource.images.imgLoyaltyRuoi,
    AppResource.images.imgLoyaltyGa,
    AppResource.images.imgLoyaltyVoi,
    AppResource.images.imgLoyaltyKhungLong,
  ],
  loyaltyCheckInStatusReference: {
    happy: 'Happy',
    sad: 'Sad',
    angry: 'Angry',
    ukila: 'Ukila',
  },
  loyaltyCheckInStatusItems: [
    {
      name: 'vuiii',
      value: 'Happy',
      icon: AppResource.icons.icEmotionVui,
    },
    {
      name: 'pùnn',
      value: 'Sad',
      icon: AppResource.icons.icEmotionBuon,
    },
    {
      name: 'nẫuu',
      value: 'Angry',
      icon: AppResource.icons.icEmotionNau,
    },
    {
      name: 'ukila',
      value: 'Ukila',
      icon: AppResource.icons.icEmotionOk,
    },
  ],
  loyaltyHeroscopeGroup: [
    {
      name: 'Nhóm hoả nè',
      bgColor: '#FFECF9',
      value: [
        {
          icon: AppResource.icons.icBachDuong,
          name: 'Bạch Dương',
          value: '(21/06 - 22/07)',
        },
        {
          icon: AppResource.icons.icSuTu,
          name: 'Sư Tử',
          value: '(23/07 - 22/09)',
        },
        {
          icon: AppResource.icons.icNhanMa,
          name: 'Nhân Mã',
          value: '(21/11 - 21/12)',
        },
      ],
    },
    {
      name: 'Nhóm thuỷ nè',
      bgColor: '#E1F8FF',
      value: [
        {
          icon: AppResource.icons.icCuGiai,
          name: 'Cự Giải',
          value: '(21/06 - 22/07)',
        },
        {
          icon: AppResource.icons.icBoCap,
          name: 'Thiên Yết',
          value: '(23/10 - 21/11)',
        },
        {
          icon: AppResource.icons.icSongNgu,
          name: 'Song Ngư',
          value: '(19/02 - 20/03)',
        },
      ],
    },
    {
      name: 'Nhóm thổ nè',
      bgColor: '#FFEED9',
      value: [
        {
          icon: AppResource.icons.icKimNguu,
          name: 'Kim Ngưu',
          value: '(20/04 - 20/05)',
        },
        {
          icon: AppResource.icons.icXuNu,
          name: 'Xử Nữ',
          value: '(23/08 - 22/09)',
        },
        {
          icon: AppResource.icons.icMaKet,
          name: 'Ma Kết',
          value: '(22/12 - 19/01)',
        },
      ],
    },
    {
      name: 'Nhóm khí nè',
      bgColor: '#DEFCF9',
      value: [
        {
          icon: AppResource.icons.icSongTu,
          name: 'Song Tử',
          value: '(21/05 - 20/06)',
        },
        {
          icon: AppResource.icons.icThienBinh,
          name: 'Thiên Bình',
          value: '(23/09 - 22/10)',
        },
        {
          icon: AppResource.icons.icBaoBinh,
          name: 'Thủy Bình',
          value: '(20/01 - 18/02)',
        },
      ],
    },
  ],

  otpFactorFunctionName: {
    autopay: 'autopay',
    charge: 'charge',
    payment: 'payment',
    updateInfo: 'updateinfo',
    fingerprint: 'fingerprint',
    faceId: 'faceid',
  },
};

export default AppData;
