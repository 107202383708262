import PreferenceKeys from 'general/constants/PreferenceKeys';
import { Navigate } from 'react-router-dom';

// Route danh cho tai khoan chua dang nhap (Guest)
// Neu da dang nhap thi nhay ve man hinh root '/'
function GuestRoute(props) {
  // MARK: --- Params ---
  // const currentAccount = store.getState().account.current;
  // const isAuth = !_.isNull(currentAccount);
  const isAuth = !!localStorage.getItem(PreferenceKeys.apiKey);

  return !isAuth ? props.children : <Navigate to="/saymee/account" />;
}

export default GuestRoute;
