import moment from 'moment';

const PaymentHelper = {
  groupPaymentHistoryList: (paymentHistoryList) => {
    if (paymentHistoryList && _.isArray(paymentHistoryList)) {
      const formatPaymentHistoryList = paymentHistoryList.map((item) => {
        return {
          ...item,
          date: moment(item.timeReceiveFromClient * 1000).format('DD/MM/YYYY'),
          time: moment(item.timeReceiveFromClient * 1000).format('HH:mm'),
        };
      });

      const groupedPaymentHistoryList = _.groupBy(formatPaymentHistoryList, 'date');
      return groupedPaymentHistoryList;
    }

    return {};
  },
};

export default PaymentHelper;
