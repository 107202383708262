import loyaltyApi from 'api/loyaltyApi';
import { AppDialogHelper } from 'general/components/AppDialog';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import SaymeeSecment from 'general/components/SaymeeOtherComponents/SaymeeSecment';
import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import LoyaltyHelper from 'general/helpers/LoyaltyHelper';
import UserHelper from 'general/helpers/UserHelper';
import Global from 'general/utils/Global';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useLoyalty from 'hooks/useLoyalty';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ReactCardFlip from 'react-card-flip';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CellGiftGrid from '../../components/CellGiftGrid';
import ModalChooseHeroscope from '../../components/ModalChooseHeroscope';
import ModalHeroscopeDetail from '../../components/ModalHeroscopeDetail';
import ModalVoucherDetail from '../../components/ModalVoucherDetail';
import { thunkGetGiftList } from '../../loyaltySlice';

LoyaltyHomeScreen.propTypes = {};

function LoyaltyHomeScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { currentLoyalty, nextLoyalty, checkInStatuses, todayCheckInStatus, displayName } =
    useAccount();
  const loyaltyMenuList = useMemo(() => {
    return [
      {
        icon: AppResource.icons.icLoyaltyIntro,
        name: t('IntroduceLoyalty'),
        onPress: () => {},
      },
      {
        icon: AppResource.icons.icLoyaltyGift,
        name: t('RedeemGift'),
        onPress: () => {
          callbackUnsupport();
        },
      },
      {
        icon: AppResource.icons.icLoyaltyDiscount,
        name: t('YourVouchers'),
        onPress: () => {
          router.navigate('/saymee/account/loyalty/my-voucher');
        },
      },
      {
        icon: AppResource.icons.icLoyaltyHistory,
        name: t('PointHistory'),
        onPress: () => {
          router.navigate('/saymee/account/loyalty/point-history');
        },
      },
    ];
  }, []);
  const callbackUnsupport = useCallback(() => {
    AppDialogHelper.show(
      `${t('Oops')}!`,
      t('UnsupportFeatureOnWeb'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.error01
    );
  }, []);
  const exchangePointsMenuList = useMemo(() => {
    return [
      {
        icon: AppResource.icons.icData,
        name: t('Data'),
        onPress: () => {
          callbackUnsupport();
        },
      },
      {
        icon: AppResource.icons.icCall,
        name: t('CallMinute'),
        onPress: () => {
          callbackUnsupport();
        },
      },
      {
        icon: AppResource.icons.icCellPhone,
        name: t('Mobile'),
        onPress: () => {
          callbackUnsupport();
        },
      },
      {
        icon: AppResource.icons.icSms,
        name: t('SMS'),
        onPress: () => {
          callbackUnsupport();
        },
      },
    ];
  }, []);
  const dayList = useMemo(() => {
    return [
      t('Monday'),
      t('Tuesday'),
      t('Wednesday'),
      t('Thursday'),
      t('Friday'),
      t('Saturday'),
      t('Sunday'),
    ];
  }, []);
  const momentDayInWeekList = useMemo(() => {
    const today = moment();
    const fromDate = today.clone().startOf('week');
    const toDate = today.clone().endOf('week').add(1, 'days');
    return _.range(fromDate.format('DD'), toDate.format('DD'));
  }, []);
  const momentDateInWeekList = useMemo(() => {
    let dateList = [];
    const today = moment();
    let fromDate = today.clone().startOf('week');
    const toDate = today.clone().endOf('week').add(1, 'days');
    while (toDate.isAfter(fromDate)) {
      dateList.push(fromDate.format('YYYY-MM-DD'));
      fromDate = fromDate.add(1, 'days');
    }
    return dateList;
  }, []);
  const avatarCheckInStatusInWeekList = useMemo(() => {
    let arrRet = {};
    _.forEach(momentDateInWeekList, (item) => {
      _.forEach(checkInStatuses, (status) => {
        if (item === moment(status?.time).format('YYYY-MM-DD')) {
          const avatar = LoyaltyHelper.getCheckInStatusAvatarByReferenceId(status?.referenceId);
          if (avatar) {
            arrRet[item] = avatar;
          }
        }
      });
    });
    return arrRet;
  }, [checkInStatuses, momentDateInWeekList]);
  const [cardEmotionalFlipped, setCardEmotionalFlipped] = useState(false);
  const [cardCareerFlipped, setCardCareerFlipped] = useState(false);
  const [cardMoodFlipped, setCardMoodFlipped] = useState(false);
  const heroscopeList = useSelector((state) => state.app.heroscopeList);
  const [modalChooseHeroscopeShowing, setModalChooseHeroscopeShowing] = useState(false);
  const [modalHeroscopeDetailShowing, setModalHeroscopeDetailShowing] = useState(false);
  const [selectedHeroscopeItem, setSelectedHeroscopeItem] = useState(null);
  const heroscopeItem = useMemo(() => {
    if (selectedHeroscopeItem && heroscopeList && _.isArray(heroscopeList)) {
      return _.find(heroscopeList, (item) => item.tenCung === selectedHeroscopeItem?.name);
    }
    return null;
  }, [selectedHeroscopeItem, heroscopeList]);
  const heroscopeInfoObject = useMemo(() => {
    let retObj = {};
    if (heroscopeItem) {
      const arrTinhCam = _.split(heroscopeItem?.tinhCam, '-');
      if (arrTinhCam) {
        retObj.emotional = {
          name: t('Emotional'),
          value: parseInt(arrTinhCam[0]),
          text: arrTinhCam[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrTinhCam[0]); i++) {
          retObj.emotional.icons[i] = 1;
        }
      }
      const arrSuNghiep = _.split(heroscopeItem?.suNghiep, '-');
      if (arrSuNghiep) {
        retObj.career = {
          name: t('Career'),
          value: parseInt(arrSuNghiep[0]),
          text: arrSuNghiep[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrSuNghiep[0]); i++) {
          retObj.career.icons[i] = 1;
        }
      }
      const arrTamTrang = _.split(heroscopeItem?.tamTrang, '-');
      if (arrTamTrang) {
        retObj.mood = {
          name: t('Mood'),
          value: parseInt(arrTamTrang[0]),
          text: arrTamTrang[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrTamTrang[0]); i++) {
          retObj.mood.icons[i] = 1;
        }
      }

      retObj.otherInfos = [
        {
          name: t('LuckyColor'),
          value: heroscopeItem?.mauMayMan,
        },
        {
          name: t('MatchOther'),
          value: heroscopeItem?.saoHopCa,
        },
        {
          name: t('LuckyNumber'),
          value: heroscopeItem?.soMayMan,
        },
        {
          name: t('SuccessfulNegotiation'),
          value: heroscopeItem?.damPhanThanhCong,
        },
      ];
    }
    return retObj;
  }, [heroscopeItem]);
  const refNextShowHeroscopeDetail = useRef(false);
  const refNextFlippedEmotional = useRef(false);
  const refNextFlippedCareer = useRef(false);
  const refNextFlippedMood = useRef(false);

  const { voucherCategoryList, giftList } = useLoyalty();
  const voucherTabs = useMemo(() => {
    return voucherCategoryList.map((item) => item.name);
  }, [voucherCategoryList]);
  const [selectedVoucherTab, setSelectedVoucherTab] = useState(voucherTabs[0]);
  const loadingGiftList = useSelector((state) => state.loyalty.loadingGiftList);
  const [modalVoucherDetailShowing, setModalVoucherDetailShowing] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState(null);
  const [filterVoucherGift, setFilterVoucherGift] = useState(Global.g_filterVoucherGift);

  // MARK: --- Functions ---
  function handleSelectedVoucherTab(newTab) {
    setSelectedVoucherTab(newTab);
    const foundCategory = _.find(voucherCategoryList, (item) => item.name === newTab);
    if (!!foundCategory) {
      const catId = foundCategory.value;
      Global.g_filterVoucherGift = {
        ...Global.g_filterVoucherGift,
        page: 0,
        catId,
      };
      setFilterVoucherGift(Global.g_filterVoucherGift);
    }
  }

  async function requestCheckin(status) {
    try {
      const res = await loyaltyApi.loyaltyCheckin(status);
      const { result, point } = res?.data;
      if (result === 'success') {
        UserHelper.requestLoyaltyInfo();
        if (point > 0) {
          // diem danh thanh cong => cong diem
          AppDialogHelper.show(
            `${t('Yay')}! ${t('Success')}`,
            t('RewardedPoints', { point: point }),
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogSuccess,
            AppResource.colors.success
          );
        }
      }
    } catch (error) {
      console.log(`Checkin error: ${error?.message}`);
    }
  }

  async function requestLatBai() {
    try {
      const res = await loyaltyApi.loyaltyLatBai();
      const { result, point } = res?.data;
      if (result === 'success') {
        UserHelper.requestLoyaltyInfo();
        if (point > 0) {
          // lat bai thanh cong => cong diem
          AppDialogHelper.show(
            `${t('Yay')}! ${t('Success')}`,
            t('RewardedPoints', { point: point }),
            [
              {
                title: t('Close'),
                type: 'positive',
                onClick: () => {
                  AppDialogHelper.hide();
                },
              },
            ],
            AppResource.images.imgDialogSuccess,
            AppResource.colors.success
          );
        }
      } else if (result === 'failed') {
        console.log(`Loi lat bai: ${res?.data?.reason ?? ''}`);
      }
    } catch (error) {
      console.log(`Checkin error: ${error?.message}`);
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    dispatch(thunkGetGiftList(filterVoucherGift));
  }, [filterVoucherGift]);

  useEffect(() => {
    if (cardCareerFlipped || cardEmotionalFlipped || cardMoodFlipped) {
      requestLatBai();
    }
  }, [cardCareerFlipped, cardEmotionalFlipped, cardMoodFlipped]);

  return (
    <div className="pb-10">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account', active: false },
          { title: t('Loyalty'), url: '#', active: true },
        ]}
      />

      <div
        className="px-10 px-md-20 py-10 mt-6"
        style={{
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
          backgroundColor: '#F6F2F6',
          // background:
          //   'linear-gradient(90.24deg, rgba(25, 211, 197, 0.06) 4.63%, rgba(0, 113, 206, 0.06) 95.78%)',
        }}
      >
        <p
          className="text-dark mb-4 text-center text-md-left"
          style={{
            fontWeight: 700,
            fontSize: '2.2rem',
          }}
        >
          {displayName}
        </p>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-between">
          <div className="d-flex flex-column flex-md-row align-items-center">
            <KTTooltip
              text={`${Utils.formatNumber(currentLoyalty.point)}/${
                nextLoyalty?.point ? Utils.formatNumber(nextLoyalty?.point) : '+ꝏ'
              }`}
            >
              <div className="w-160px h-160px">
                <CircularProgressbarWithChildren
                  value={(currentLoyalty.point * 100) / nextLoyalty.point}
                  // text={`${10.toFixed(0)}%`}
                  strokeWidth={8}
                  styles={{
                    path: {
                      stroke: AppResource.colors.feature,
                    },
                    trail: {
                      stroke: '#fff',
                    },
                    text: {
                      fill: AppResource.colors.feature,
                      fontSize: '1.2rem',
                      fontWeight: 700,
                    },
                  }}
                >
                  <p
                    className="mb-0"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {currentLoyalty.name}
                  </p>
                  <img src={currentLoyalty.avatar} className="w-80px h-80px" />
                </CircularProgressbarWithChildren>
              </div>
            </KTTooltip>

            <div className="ml-4 mt-4 mt-md-0">
              {/* <p
              className="text-dark mb-2"
              style={{
                fontWeight: 700,
                fontSize: '2.2rem',
              }}
            >
              {displayName}
            </p> */}
              <p className="text-dark mb-2 font-size-lg">{t('YourPoint')}</p>
              <div className="d-flex align-items-center justify-content-center justify-content-md-start">
                <p
                  className="mb-0"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    color: AppResource.colors.feature,
                  }}
                >
                  {Utils.formatNumber(currentLoyalty.point)}
                </p>
                <div
                  className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
                  style={{
                    backgroundColor: AppResource.colors.feature,
                  }}
                >
                  <img src={AppResource.icons.icLoyaltyPoint} />
                </div>
              </div>
            </div>
          </div>

          <div
            className="bg-white p-6 mt-6 mt-md-0 ml-0 ml-md-6"
            style={{
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
              borderRadius: '20px',
            }}
          >
            <div className="d-flex flex-md-row flex-column align-items-center">
              <p
                className="text-dark mb-0 mr-4 mb-2 mb-md-0"
                style={{
                  fontSize: '1.1rem',
                }}
              >
                {t('AccumulateMore')}{' '}
                <span
                  style={{
                    color: AppResource.colors.feature,
                    fontWeight: 600,
                  }}
                >
                  {`${
                    nextLoyalty?.point
                      ? Utils.formatNumber(nextLoyalty.point - currentLoyalty.point)
                      : '+ꝏ'
                  }`}
                </span>{' '}
                {t('point')} <br />
                {t('ToUpNextLevel').toLowerCase()}
              </p>
              <SaymeeButton
                text={t('ScoreHunting')}
                className="saymee-primary-button px-10 h-40px"
                onPress={() => {}}
              />
            </div>
          </div>
        </div>
      </div>

      {/* menu loyalty */}
      <div className="mt-4">
        <div className="row">
          {loyaltyMenuList.map((item, index) => {
            return (
              <div className="col-md-6 col-lg-3 mt-6" key={index}>
                <div
                  className="bg-white p-4 d-flex flex-column align-items-center cursor-pointer hover-opacity-60"
                  onClick={() => {
                    item?.onPress();
                  }}
                  style={{
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.06), 0px 12px 32px rgba(0, 0, 0, 0.04)',
                    borderRadius: '16px',
                  }}
                >
                  <img src={item.icon} className="w-50px h-50px" />
                  <p
                    className="mb-0 font-size-lg mt-2"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* hom nay ban the nao */}
      <div className="mt-10 px-5 px-lg-20 py-10">
        <p
          className="mb-0 text-dark text-center"
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
          }}
        >
          {t('HowYouFeelToday')}
        </p>
        <p
          className="mb-4 mb-lg-0 text-dark text-center"
          style={{
            fontWeight: 400,
            fontSize: '1.1rem',
          }}
        >
          {t('ChooseEmotionGetGiftNow')}
        </p>
        <div className="d-flex flex-column-reverse flex-sm-row flex-sm-row">
          <div className="d-flex align-items-end">
            <img src={AppResource.images.imgEmotionToday} className="w-100" />
            <img
              src={AppResource.icons.icEmotionVui}
              className="w-60px w-md-100px"
              style={{
                marginLeft: '-70px',
                paddingBottom: '30px',
              }}
            />
          </div>
          <div className="row ml-0 ml-sm-6 w-100 pb-0 pb-sm-8 pb-md-10">
            {AppData.loyaltyCheckInStatusItems.map((item, index) => {
              let selected = false;
              if (todayCheckInStatus?.referenceId === item.value) {
                selected = true;
              }
              return (
                <div
                  key={index}
                  className="col-lg-3 col-xl-2 col-md-3 col-sm-3 col-3 mb-4 d-flex align-items-end justify-content-center"
                >
                  <div
                    className="py-10 d-flex flex-column align-items-center w-100 cursor-pointer hover-opacity-60"
                    style={{
                      backgroundColor: selected ? '#FFD7EF' : AppResource.colors.neutral,
                      borderRadius: '100px',
                    }}
                    onClick={() => {
                      requestCheckin(item?.value);
                    }}
                  >
                    <img
                      src={item.icon}
                      className="w-40px h-80px"
                      style={{
                        objectFit: 'contain',
                      }}
                    />
                    <p className="mb-0 mt-2">{item.name}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div
          className="overflow-hidden"
          style={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '15px',
            marginTop: '-30px',
          }}
        >
          <div
            className="px-0 px-lg-20 pt-6 pb-3 d-flex flex-column align-items-center"
            style={{
              background:
                'linear-gradient(90.24deg, rgba(25, 211, 197, 0.12) 4.63%, rgba(0, 113, 206, 0.12) 95.78%)',
            }}
          >
            <p
              className="mb-0 text-dark"
              style={{
                fontWeight: 700,
                fontSize: '1.2rem',
              }}
            >
              {t('EmotionThisWeek')}
            </p>
            <div className="row mt-4 w-100 flex-nowrap">
              {dayList.map((item, index) => {
                return (
                  <div key={index} className="col d-flex align-items-center justify-content-center">
                    <p
                      className="mb-0"
                      style={{
                        whiteSpace: 'nowrap',
                        fontWeight: moment().format('DD') == momentDayInWeekList[index] ? 600 : 400,
                        color:
                          moment().format('DD') == momentDayInWeekList[index]
                            ? AppResource.colors.feature
                            : AppResource.colors.textPrimary,
                      }}
                    >
                      {item}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="px-0 px-lg-20 pb-5 pb-lg-10 pt-3 d-flex flex-column align-items-center">
            <div className="row w-100 flex-nowrap">
              {dayList.map((item, index) => {
                const todayDay = parseInt(moment().format('DD'));
                let icCheckInStatus = null;
                if (todayDay < momentDayInWeekList[index]) {
                  icCheckInStatus = AppResource.icons.icCheckInStatusOff;
                } else {
                  icCheckInStatus = avatarCheckInStatusInWeekList[momentDateInWeekList[index]];
                }
                if (_.isNull(icCheckInStatus) || _.isUndefined(icCheckInStatus)) {
                  icCheckInStatus = AppResource.icons.icCheckInStatusOn;
                }
                return (
                  <div
                    key={index}
                    className="col align-items-center d-flex justify-content-center flex-column"
                  >
                    <img src={icCheckInStatus} className="" />
                    <p
                      className="mb-0 mt-2"
                      style={{
                        color:
                          todayDay === momentDayInWeekList[index]
                            ? AppResource.colors.feature
                            : AppResource.colors.textPrimary,
                      }}
                    >
                      {_.padStart(momentDayInWeekList[index], 2, '0')}
                    </p>
                    <div
                      className="rounded-circle mt-1"
                      style={{
                        backgroundColor:
                          todayDay === momentDayInWeekList[index]
                            ? AppResource.colors.feature
                            : 'transparent',
                        width: '8px',
                        height: '8px',
                      }}
                    ></div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {/* xem bai nhan qua FREE */}
      <div
        className="mt-0 px-0 py-10"
        style={
          {
            // boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
            // borderRadius: '20px',
          }
        }
      >
        <p
          className="mb-0 text-dark text-center"
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
          }}
        >
          {t('ViewCardGetGiftFree')}
        </p>
        <div className="row mt-6">
          <div className="col-md-5 d-flex align-items-center justify-content-center">
            <div
              className="position-relative d-flex"
              style={{
                width: '55%',
              }}
            >
              <img src={AppResource.images.imgChatBoxBg} className="w-100" />
              <div className="position-absolute top-0 right-0 w-100 h-100 d-flex align-items-center justify-content-center">
                <p
                  className="text-center mb-0"
                  style={{
                    width: '50%',
                  }}
                >
                  {t('TouchCardToViewInfo')}
                </p>
              </div>
            </div>
            <img
              src={AppResource.images.imgMascotGift}
              className=""
              style={{
                width: '45%',
              }}
            />
          </div>
          <div className="col-md-7">
            <div className="d-flex align-items-center justify-content-around">
              {/* tinh cam */}
              <ReactCardFlip
                isFlipped={cardEmotionalFlipped}
                flipDirection="horizontal"
                containerStyle={{
                  width: '30%',
                }}
              >
                <div
                  className="cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    if (_.isEmpty(heroscopeInfoObject)) {
                      setModalChooseHeroscopeShowing(true);
                      refNextFlippedEmotional.current = true;
                    } else {
                      setCardEmotionalFlipped(true);
                    }
                  }}
                >
                  <img src={AppResource.images.imgEmotional} className="w-100 h-100" />
                </div>
                <div
                  className="position-relative cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    backgroundColor: '#FFECF9',
                  }}
                  onClick={() => {
                    setCardEmotionalFlipped(false);
                  }}
                >
                  <img src={AppResource.images.imgEmotional} className="w-100 h-100 opacity-0" />
                  <div className="position-absolute top-0 right-0 w-100 h-100 d-flex flex-column py-6 px-4">
                    <p
                      className=""
                      style={{
                        color: AppResource.colors.primary,
                        fontWeight: 600,
                        fontSize: '1.1rem',
                      }}
                    >
                      {t('Emotional')}
                    </p>
                    <div className="flex-grow-1 d-flex align-items-center">
                      <div>
                        {heroscopeInfoObject?.emotional?.icons?.map((item, index) => {
                          return (
                            <i
                              key={index}
                              className={`${item === 1 ? 'fas' : 'fal'} fa-heart fa-2x mr-2 mb-2`}
                              style={{ color: AppResource.colors.feature }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </ReactCardFlip>
              {/* su nghiep */}
              <ReactCardFlip
                isFlipped={cardCareerFlipped}
                flipDirection="horizontal"
                containerStyle={{
                  width: '30%',
                }}
              >
                <div
                  className="cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    if (_.isEmpty(heroscopeInfoObject)) {
                      setModalChooseHeroscopeShowing(true);
                      refNextFlippedCareer.current = true;
                    } else {
                      setCardCareerFlipped(true);
                    }
                  }}
                >
                  <img src={AppResource.images.imgCareer} className="w-100 h-100" />
                </div>
                <div
                  className="position-relative cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    backgroundColor: '#E1F8FF',
                  }}
                  onClick={() => {
                    setCardCareerFlipped(false);
                  }}
                >
                  <img src={AppResource.images.imgCareer} className="w-100 h-100 opacity-0" />
                  <div className="position-absolute top-0 right-0 w-100 h-100 d-flex flex-column py-6 px-4">
                    <p
                      className=""
                      style={{
                        color: AppResource.colors.secondary02,
                        fontWeight: 600,
                        fontSize: '1.1rem',
                      }}
                    >
                      {t('Career')}
                    </p>
                    <div className="flex-grow-1 d-flex align-items-center">
                      <div>
                        {heroscopeInfoObject?.career?.icons?.map((item, index) => {
                          return (
                            <i
                              key={index}
                              className={`${item === 1 ? 'fas' : 'fal'} fa-star fa-2x mr-2 mb-2`}
                              style={{ color: AppResource.colors.secondary02 }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </ReactCardFlip>
              {/* tam trang */}
              <ReactCardFlip
                isFlipped={cardMoodFlipped}
                flipDirection="horizontal"
                containerStyle={{
                  width: '30%',
                }}
              >
                <div
                  className="cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                  }}
                  onClick={() => {
                    if (_.isEmpty(heroscopeInfoObject)) {
                      setModalChooseHeroscopeShowing(true);
                      refNextFlippedMood.current = true;
                    } else {
                      setCardMoodFlipped(true);
                    }
                  }}
                >
                  <img src={AppResource.images.imgMood} className="w-100 h-100" />
                </div>
                <div
                  className="position-relative cursor-pointer hover-opacity-60"
                  style={{
                    width: '100%',
                    borderRadius: '12px',
                    overflow: 'hidden',
                    backgroundColor: '#DEFCF9',
                  }}
                  onClick={() => {
                    setCardMoodFlipped(false);
                  }}
                >
                  <img src={AppResource.images.imgMood} className="w-100 h-100 opacity-0" />
                  <div className="position-absolute top-0 right-0 w-100 h-100 d-flex flex-column py-6 px-4">
                    <p
                      className=""
                      style={{
                        color: AppResource.colors.secondary,
                        fontWeight: 600,
                        fontSize: '1.1rem',
                      }}
                    >
                      {t('Mood')}
                    </p>
                    <div className="flex-grow-1 d-flex align-items-center">
                      <div>
                        {heroscopeInfoObject?.mood?.icons?.map((item, index) => {
                          return (
                            <i
                              key={index}
                              className={`${item === 1 ? 'fas' : 'fal'} fa-smile fa-2x mr-2 mb-2`}
                              style={{ color: AppResource.colors.secondary }}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </ReactCardFlip>
            </div>

            <SaymeeButton
              text={t('ViewMore')}
              className="saymee-primary-outline-button px-10 h-40px mt-6 mx-3"
              onPress={() => {
                if (_.isEmpty(heroscopeInfoObject)) {
                  if (
                    !refNextFlippedEmotional.current &&
                    !refNextFlippedCareer.current &&
                    !refNextFlippedMood.current
                  ) {
                    refNextShowHeroscopeDetail.current = true;
                  }
                  setModalChooseHeroscopeShowing(true);
                } else {
                  setModalHeroscopeDetailShowing(true);
                }
              }}
            />
          </div>
        </div>
      </div>

      {/* doi diem lay vien thong */}
      <div className="mt-0 px-0 py-10">
        <p
          className="mb-0 text-dark text-center"
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
          }}
        >
          {t('ExchangePointsToGetTelecommunications')}
        </p>
        <div className="row">
          {exchangePointsMenuList.map((item, index) => {
            return (
              <div className="col-md-6 col-lg-3 mt-6" key={index}>
                <div
                  className="bg-white p-4 d-flex flex-column align-items-center cursor-pointer hover-opacity-60"
                  onClick={() => {
                    item?.onPress();
                  }}
                  style={{
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.06), 0px 12px 32px rgba(0, 0, 0, 0.04)',
                    borderRadius: '16px',
                  }}
                >
                  <img src={item.icon} className="w-50px h-50px" />
                  <p
                    className="mb-0 font-size-lg mt-2"
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    {item.name}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      {/* voucher danh cho ban */}
      <div className="mt-0 px-0 pt-10 d-flex flex-column align-items-center">
        <p
          className="mb-0 text-dark text-center"
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
          }}
        >
          {t('VoucherForYou')}
        </p>

        <div className="w-100 mt-6">
          <SaymeeSecment
            tabs={voucherTabs}
            activeTab={selectedVoucherTab}
            labelSm={t('FilterByType')}
            handleClick={handleSelectedVoucherTab}
            className="d-flex justify-content-center align-items-center"
          />

          {loadingGiftList && giftList?.length === 0 ? (
            <SaymeeEmpty message={`${t('Loading')}...`} />
          ) : giftList?.length > 0 ? (
            <div className="row">
              {giftList?.map((item, index) => {
                return (
                  <div key={index} className="col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-6">
                    <CellGiftGrid
                      giftObject={item}
                      onPress={() => {
                        setSelectedVoucher(item);
                        setModalVoucherDetailShowing(true);
                      }}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <SaymeeEmpty message={t('NoDataToDisplay')} />
          )}
        </div>

        {!loadingGiftList && giftList?.length < Global.g_filterVoucherGift.total && (
          <SaymeeButton
            text={t('ViewMore')}
            className="saymee-primary-outline-button px-10 h-40px mt-2 mx-3"
            onPress={() => {
              // load more gift list
              Global.g_filterVoucherGift = {
                ...Global.g_filterVoucherGift,
                page: Global.g_filterVoucherGift.page + 1,
              };
              setFilterVoucherGift(Global.g_filterVoucherGift);
            }}
          />
        )}
      </div>

      {/* Modal choose heroscope */}
      <ModalChooseHeroscope
        show={modalChooseHeroscopeShowing}
        onClose={() => {
          setModalChooseHeroscopeShowing(false);
          if (_.isNull(selectedHeroscopeItem)) {
            refNextFlippedEmotional.current = false;
            refNextFlippedCareer.current = false;
            refNextFlippedMood.current = false;
          }
        }}
        onChoose={(item) => {
          setSelectedHeroscopeItem(item);
          setModalChooseHeroscopeShowing(false);
          if (refNextShowHeroscopeDetail.current) {
            setModalHeroscopeDetailShowing(true);
            refNextShowHeroscopeDetail.current = false;
          } else {
            if (refNextFlippedEmotional.current) {
              setCardEmotionalFlipped(true);
              refNextFlippedEmotional.current = false;
            }
            if (refNextFlippedCareer.current) {
              setCardCareerFlipped(true);
              refNextFlippedCareer.current = false;
            }
            if (refNextFlippedMood.current) {
              setCardMoodFlipped(true);
              refNextFlippedMood.current = false;
            }
          }
        }}
      />

      {/* Modal heroscope detail */}
      <ModalHeroscopeDetail
        show={modalHeroscopeDetailShowing}
        onClose={() => {
          setModalHeroscopeDetailShowing(false);
          setSelectedHeroscopeItem(null);
          setCardEmotionalFlipped(false);
          setCardCareerFlipped(false);
          setCardMoodFlipped(false);
        }}
        selectedItem={selectedHeroscopeItem}
      />

      {/* Modal voucher detail */}
      <ModalVoucherDetail
        show={modalVoucherDetailShowing}
        onClose={() => {
          setModalVoucherDetailShowing(false);
        }}
        voucherItem={selectedVoucher}
      />
    </div>
  );
}

export default LoyaltyHomeScreen;
