import React from 'react';
import PropTypes from 'prop-types';
import SaymeeBaseLayout from 'general/components/BaseLayout/SaymeeBaseLayout';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import { Route, Routes } from 'react-router-dom';
import Account from './features/Account';

Saymee.propTypes = {};

function Saymee(props) {
  return (
    <SaymeeBaseLayout>
      <Routes>
        {/* <Route path="/" element={<Navigate to="sign-in" />} /> */}
        <Route path="account/*" element={<Account />} />
      </Routes>
    </SaymeeBaseLayout>
  );
}

export default Saymee;
