import React from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import { useTranslation } from 'react-i18next';
import Saymee404 from 'general/components/SaymeeOtherComponents/Saymee404';
import useRouter from 'hooks/useRouter';

UtilitiesScreen.propTypes = {};

function UtilitiesScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <div>
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account/info', active: false },
          { title: t('Utilities'), url: '#', active: true },
        ]}
      />
      <div>
        <Saymee404
          title={t('Notify')}
          message={t('FeatureNotSupport')}
          buttonTitle={t('TutorialDownloadApp')}
          onPressButton={() => {
            router.navigate('/saymee/account/help-download-app');
          }}
        />
      </div>
    </div>
  );
}

export default UtilitiesScreen;
