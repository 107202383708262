import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const packageApi = {
  /**
   * Lay chi tiet goi
   * @param {number} packageId Id goi
   * @returns
   */
  getPackageDetail: (packageId) => {
    const url = '/package/detail';
    return axiosClient.post(
      url,
      {
        package_id: packageId,
      },
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },
};

export default packageApi;
