import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const serviceApi = {
  /**
   * Lay chi tiet goi dich vu
   * @param {number} serviceId Id goi dich vu
   * @returns
   */
  getServiceDetail: (serviceId) => {
    const url = '/service/detail-v2';
    return axiosClient.post(
      url,
      {
        service_id: serviceId,
      },
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Huy goi dich vu
   * @param {string} serviceCode Ma goi dich vu
   * @returns
   */
  cancelService: (serviceCode) => {
    const url = '/service/cancel';
    return axiosClient.post(
      url,
      {
        service_code: serviceCode,
      },
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },
};

export default serviceApi;
