import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

ModalHeroscopeDetail.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  selectedItem: PropTypes.object,
};

ModalHeroscopeDetail.defaultProps = {
  show: false,
  onClose: null,
  selectedItem: null,
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * selectedItem: object,
 * }} props
 * @returns
 */
function ModalHeroscopeDetail(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, selectedItem } = props;
  const heroscopeList = useSelector((state) => state.app.heroscopeList);
  const selectedHeroscopeItem = useMemo(() => {
    if (selectedItem && heroscopeList && _.isArray(heroscopeList)) {
      return _.find(heroscopeList, (item) => item.tenCung === selectedItem?.name);
    }
    return null;
  }, [selectedItem, heroscopeList]);
  const heroscopeInfoObject = useMemo(() => {
    let retObj = {};
    if (selectedHeroscopeItem) {
      const arrTinhCam = _.split(selectedHeroscopeItem?.tinhCam, '-');
      if (arrTinhCam) {
        retObj.emotional = {
          name: t('Emotional'),
          value: parseInt(arrTinhCam[0]),
          text: arrTinhCam[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrTinhCam[0]); i++) {
          retObj.emotional.icons[i] = 1;
        }
      }
      const arrSuNghiep = _.split(selectedHeroscopeItem?.suNghiep, '-');
      if (arrSuNghiep) {
        retObj.career = {
          name: t('Career'),
          value: parseInt(arrSuNghiep[0]),
          text: arrSuNghiep[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrSuNghiep[0]); i++) {
          retObj.career.icons[i] = 1;
        }
      }
      const arrTamTrang = _.split(selectedHeroscopeItem?.tamTrang, '-');
      if (arrTamTrang) {
        retObj.mood = {
          name: t('Mood'),
          value: parseInt(arrTamTrang[0]),
          text: arrTamTrang[1],
          icons: [0, 0, 0, 0, 0],
        };
        for (let i = 0; i < parseInt(arrTamTrang[0]); i++) {
          retObj.mood.icons[i] = 1;
        }
      }

      retObj.otherInfos = [
        {
          name: t('LuckyColor'),
          value: selectedHeroscopeItem?.mauMayMan,
        },
        {
          name: t('MatchOther'),
          value: selectedHeroscopeItem?.saoHopCa,
        },
        {
          name: t('LuckyNumber'),
          value: selectedHeroscopeItem?.soMayMan,
        },
        {
          name: t('SuccessfulNegotiation'),
          value: selectedHeroscopeItem?.damPhanThanhCong,
        },
      ];
    }
    return retObj;
  }, [selectedHeroscopeItem]);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        backdrop="static"
        centered
        size="lg"
        onHide={handleClose}
        onExit={() => {
          Utils.unlockedBodyScroll();
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title></Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>
        <Modal.Body
          className="py-0 px-14 mt-6 overflow-auto"
          style={{
            maxHeight: '70vh',
          }}
        >
          <div className="d-flex flex-column align-items-center">
            <div
              className="rounded-circle d-flex align-items-center justify-content-center overflow-hidden mb-2"
              style={{
                width: '20%',
                backgroundColor: selectedItem?.bgColor,
                aspectRatio: 1,
              }}
            >
              <img
                src={selectedItem?.icon}
                className=""
                style={{
                  width: '70%',
                  height: '70%',
                  objectFit: 'contain',
                }}
              />
            </div>
            <div className="d-flex flex-column align-items-center mt-2">
              <p
                className="mb-0 text-dark"
                style={{
                  fontWeight: 600,
                  fontSize: '1.2rem',
                }}
              >
                {selectedItem?.name}
              </p>
              <p
                className="mb-0 text-dark"
                style={{
                  fontWeight: 400,
                  fontSize: '1rem',
                }}
              >
                {selectedItem?.value}
              </p>
            </div>

            {/* tinh cam */}
            <div className="d-flex flex-column w-100 mt-6">
              <div>
                <div className="mb-1 d-flex align-items-center justify-content-between">
                  <p
                    className="mb-0 text-dark"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.2rem',
                    }}
                  >
                    {heroscopeInfoObject?.emotional?.name}
                  </p>
                  <div>
                    {heroscopeInfoObject?.emotional?.icons?.map((item, index) => {
                      return (
                        <i
                          key={index}
                          className={`${item === 1 ? 'fas fa-heart' : 'fal fa-heart'} mr-2 mb-2`}
                          style={{ color: AppResource.colors.primary }}
                        />
                      );
                    })}
                  </div>
                </div>
                <p
                  className="mb-0 text-dark"
                  style={{
                    fontWeight: 400,
                    fontSize: '1rem',
                  }}
                >
                  {heroscopeInfoObject?.emotional?.text}
                </p>
              </div>
            </div>

            {/* su nghiep */}
            <div className="d-flex flex-column w-100 mt-6">
              <div>
                <div className="mb-1 d-flex align-items-center justify-content-between">
                  <p
                    className="mb-0 text-dark"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.2rem',
                    }}
                  >
                    {heroscopeInfoObject?.career?.name}
                  </p>
                  <div>
                    {heroscopeInfoObject?.career?.icons?.map((item, index) => {
                      return (
                        <i
                          key={index}
                          className={`${item === 1 ? 'fas fa-star' : 'fal fa-star'} mr-2 mb-2`}
                          style={{ color: AppResource.colors.secondary02 }}
                        />
                      );
                    })}
                  </div>
                </div>
                <p
                  className="mb-0 text-dark"
                  style={{
                    fontWeight: 400,
                    fontSize: '1rem',
                  }}
                >
                  {heroscopeInfoObject?.career?.text}
                </p>
              </div>
            </div>

            {/* tam trang */}
            <div className="d-flex flex-column w-100 mt-6">
              <div>
                <div className="mb-1 d-flex align-items-center justify-content-between">
                  <p
                    className="mb-0 text-dark"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.2rem',
                    }}
                  >
                    {heroscopeInfoObject?.mood?.name}
                  </p>
                  <div>
                    {heroscopeInfoObject?.mood?.icons?.map((item, index) => {
                      return (
                        <i
                          key={index}
                          className={`${item === 1 ? 'fas fa-smile' : 'fal fa-smile'} mr-2 mb-2`}
                          style={{ color: AppResource.colors.secondary }}
                        />
                      );
                    })}
                  </div>
                </div>
                <p
                  className="mb-0 text-dark"
                  style={{
                    fontWeight: 400,
                    fontSize: '1rem',
                  }}
                >
                  {heroscopeInfoObject?.mood?.text}
                </p>
              </div>
            </div>

            {/* khac */}
            <div className="row w-100 mt-6">
              {heroscopeInfoObject?.otherInfos?.map((item, index) => {
                return (
                  <div className="col-lg-3 col-sm-6 px-0 mb-4" key={index}>
                    <div
                      className="mr-4 px-4 py-3 h-100"
                      style={{
                        backgroundColor: '#F7F7F7',
                        borderRadius: '12px',
                      }}
                    >
                      <p
                        className="mb-0"
                        style={{
                          fontWeight: 500,
                          fontSize: '1.2rem',
                          color: '#262224',
                        }}
                      >
                        {item?.value}
                      </p>
                      <p
                        className="mb-0 text-dark"
                        style={{
                          fontWeight: 400,
                          fontSize: '0.9rem',
                          color: '#6B686A',
                        }}
                      >
                        {item?.name}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center"></Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalHeroscopeDetail;
