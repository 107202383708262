import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

BreadCrumb.propTypes = {
  breadCrumbItemList: PropTypes.array,
};

BreadCrumb.defaultProps = {
  breadCrumbItemList: [],
};

/**
 *
 * @param {{
 * breadCrumbItemList: {
 * title: string,
 * url: string,
 * active: boolean,
 * }[]
 * }} props
 * @returns
 */
function BreadCrumb(props) {
  // MARK: --- Params ---
  const { breadCrumbItemList } = props;
  // const breadcrumbItems = useSelector((state) => state.app.breadcrumbItems);

  return (
    <div className="">
      <ul className="breadcrumb breadcrumb-transparent font-weight-bold p-0 my-2 font-size-sm">
        {breadCrumbItemList?.map((item, index) => {
          return (
            <li
              key={index}
              className={`breadcrumb-item ${
                item?.active ? 'text-dark font-weight-boldest' : 'text-muted'
              }`}
            >
              {!item?.active ? (
                <Link to={item?.url} className="saymee-link text-muted">
                  {item?.title}
                </Link>
              ) : (
                // <a className="saymee-link text-muted" href={item?.url}>
                //   {item?.title}
                // </a>
                item?.title
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default BreadCrumb;
