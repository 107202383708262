import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const transactionApi = {
  /**
   * Lay lich su giao dich
   * @returns
   */
  getTransactionHistory: () => {
    const url = '/transaction/history';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  // Lay chi tiet giao dich
  getTransactionDetail: (billType, startDate, endDate, tokenOtp = null) => {
    const url = '/transaction/detail';
    let params = {
      bill_type: billType,
      start_date: startDate,
      end_date: endDate,
    };
    if (tokenOtp) {
      params['token_otp'] = tokenOtp;
    }
    return axiosClient.post(url, params, {
      headers: {
        phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
      },
    });
  },

  /**
   * Lay lich su diem
   * @param {string} type Loai
   * @param {string} fromDate Tu ngay
   * @param {string} toDate Den ngay
   */
  getLoyaltyTransactions: (type = null, fromDate = '', toDate = null) => {
    const url = `${process.env.REACT_APP_CHAT_API_URL}/loyalty/transactions`;
    let params = {};
    if (!_.isNull(type)) {
      params.type = type;
    }
    if (!_.isNull(fromDate)) {
      params.from = fromDate;
    }
    if (!_.isNull(toDate)) {
      params.to = toDate;
    }
    return axiosClient.get(url, { params });
  },
};

export default transactionApi;
