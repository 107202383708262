import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import SaymeeSecment from 'general/components/SaymeeOtherComponents/SaymeeSecment';
import AppResource from 'general/constants/AppResource';
import TransactionHelper from 'general/helpers/TransactionHelper';
import Utils from 'general/utils/Utils';
import useLoyalty from 'hooks/useLoyalty';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CellPointHistory from '../../components/CellPointHistory';

PointHistoryScreen.propTypes = {};

function PointHistoryScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const tabs = [t('All'), t('AccumulatePoints'), t('ExchangePoints')];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const { pointHistoryList } = useLoyalty();
  const groupedPointHistoryList = useMemo(() => {
    const filterType =
      selectedTab === t('AccumulatePoints')
        ? 'increment'
        : selectedTab === t('ExchangePoints')
        ? 'decrement'
        : 'all';
    return TransactionHelper.groupPointHistoryList(pointHistoryList, filterType);
  }, [pointHistoryList, selectedTab]);

  // MARK: --- Functions ---
  function handleSelectedTab(newTab) {
    setSelectedTab(newTab);
  }

  return (
    <div className="pb-4 pb-md-10">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '/saymee/account', active: false },
          { title: t('Loyalty'), url: '/saymee/account/loyalty', active: false },
          { title: t('PointHistory'), url: '#', active: true },
        ]}
      />

      <div
        className="d-flex justify-content-center mt-6 pt-2 pb-6"
        style={{
          // background:
          //   'linear-gradient(231.16deg, rgba(25, 211, 197, 0.06) 21.09%, rgba(0, 113, 206, 0.06) 84.86%)',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <div className="w-100 w-md-50 px-6 px-md-0">
          <SaymeeSecment
            tabs={tabs}
            activeTab={selectedTab}
            handleClick={handleSelectedTab}
            className="d-flex justify-content-center align-items-center"
          />

          <div>
            {Object.keys(groupedPointHistoryList).map((item, index) => {
              const incrementPoints = groupedPointHistoryList[item].incrementPoints;
              const decrementPoints = groupedPointHistoryList[item].decrementPoints;
              const items = groupedPointHistoryList[item].items;

              return (
                <div key={index}>
                  {/* section header - thang / nam */}
                  <div
                    className="px-6 py-4 d-flex align-items-center justify-content-between"
                    style={{
                      backgroundColor: '#FFECF9',
                      boxShadow:
                        '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                      borderRadius: '12px',
                    }}
                  >
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: 700,
                        fontSize: '1.2rem',
                        color: AppResource.colors.textPrimary,
                      }}
                    >{`${t('Month')} ${item}`}</p>
                    <div className="d-flex align-items-center">
                      {/* tong diem tang */}
                      {incrementPoints > 0 && (
                        <div className="d-flex align-items-center">
                          <p
                            className="mb-0"
                            style={{
                              fontWeight: 600,
                              fontSize: '1.2rem',
                              color: '#17BEB1',
                            }}
                          >{`+${Utils.formatNumber(incrementPoints)}`}</p>
                          <div
                            className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: AppResource.colors.feature,
                            }}
                          >
                            <img src={AppResource.icons.icLoyaltyPoint} />
                          </div>
                        </div>
                      )}

                      {/* tong diem giam */}
                      {decrementPoints < 0 && (
                        <div className="d-flex align-items-center pl-2 ml-3 border-left border-secondary">
                          <p
                            className="mb-0"
                            style={{
                              fontWeight: 600,
                              fontSize: '1.2rem',
                              color: '#E31D93',
                            }}
                          >{`${decrementPoints}`}</p>
                          <div
                            className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
                            style={{
                              backgroundColor: AppResource.colors.feature,
                            }}
                          >
                            <img src={AppResource.icons.icLoyaltyPoint} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  {/* danh sach */}
                  <div className="mt-4">
                    {_.isArray(items) && items.length > 0 ? (
                      <>
                        {_.isArray(items) &&
                          items.map((subItem, subIndex) => {
                            return (
                              <CellPointHistory
                                key={subIndex}
                                title={subItem.description}
                                textTime={subItem.textTime}
                                textPoint={subItem.textPoint}
                                pointColor={subItem.pointColor}
                              />
                            );
                          })}
                      </>
                    ) : (
                      <SaymeeEmpty message={t('NoDataToDisplay')} />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PointHistoryScreen;
