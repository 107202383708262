import AppConfigs from './AppConfigs';

// All app preference keys
const PreferenceKeys = {
  // api key
  apiKey: `${AppConfigs.packageName}_apiKey`,
  // refresh key
  refreshKey: `${AppConfigs.packageName}_refreshKey`,
  // user id
  userId: `${AppConfigs.packageName}_userId`,
  // login info
  savedLoginPhone: `${AppConfigs.packageName}_savedLoginPhone`,
  // thoi gian het han ma OTP dang nhap
  savedOtpLoginExpiredTime: `${AppConfigs.packageName}_savedOtpLoginExpiredTime`,
  // thoi gian het han ma OTP quen mat khau
  savedOtpForgotExpiredTime: `${AppConfigs.packageName}_savedOtpForgotExpiredTime`,
  // thoi gian het han ma OTP tra cuoc
  savedOtpChargeExpiredTime: `${AppConfigs.packageName}_savedOtpChargeExpiredTime`,
  // lich su tim kiem
  savedSearchHistory: `${AppConfigs.packageName}_savedSearchHistory`,
};

export default PreferenceKeys;
