import 'react-toastify/dist/ReactToastify.css';
import GuestRoute from 'general/components/AppRoutes/GuestRoute';
import PrivateRoute from 'general/components/AppRoutes/PrivateRoute';
import AppToast from 'general/components/AppToast';
import KTPageError01 from 'general/components/OtherKeenComponents/KTPageError01';
import AccountListener from 'general/listeners/AccountListener';
import DataCommonListener from 'general/listeners/DataCommonListener';
import Auth from 'modules/Auth';
import Saymee from 'modules/Saymee';
import { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import AppDialog from 'general/components/AppDialog';

// Load BS
require('bootstrap/dist/js/bootstrap.min');
// Load KT plugins
require('assets/plugins/ktutil');
require('assets/plugins/ktmenu');
require('assets/plugins/ktoffcanvas');
require('assets/plugins/ktcookie');
require('assets/plugins/kttoggle');
// aside
require('assets/plugins/aside/aside');
require('assets/plugins/aside/aside-menu');
require('assets/plugins/aside/aside-toggle');
// header
require('assets/plugins/header/ktheader-mobile');
require('assets/plugins/header/ktheader-topbar');

window.$ = window.jQuery = require('jquery');
window.moment = require('moment');

const sTag = '[App]';

function App() {
  // MARK: --- Hooks ---
  useEffect(() => {
    // console.log(`${sTag} did load`);

    return () => {
      // console.log(`${sTag} will dismiss`);
    };
  }, []);

  return (
    <>
      {/* Router */}
      <BrowserRouter>
        {/* Suspense */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/*" element={<Navigate to="/saymee" />} />

            <Route
              path="/saymee/*"
              element={
                <PrivateRoute>
                  <Saymee />
                </PrivateRoute>
              }
            />

            <Route
              path="/auth/*"
              element={
                <GuestRoute>
                  <Auth />
                </GuestRoute>
              }
            />

            <Route path="*" element={<KTPageError01 />} />
          </Routes>
        </Suspense>
      </BrowserRouter>

      {/* Toast */}
      <AppToast />
      <AppDialog />
      {/* Listener */}
      <DataCommonListener />
      <AccountListener />
    </>
  );
}

export default App;
