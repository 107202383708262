import packageApi from 'api/packageApi';
import { AppDialogHelper } from 'general/components/AppDialog';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ModalPackageDetail.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  packageItem: PropTypes.object,
};

ModalPackageDetail.defaultProps = {
  show: false,
  onClose: null,
  packageItem: {},
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * packageItem: object,
 * }} props
 * @returns
 */
function ModalPackageDetail(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, packageItem } = props;
  const [showing, setShowing] = useState(true);
  const isRegisterPackage = false;
  const [packageDetail, setPackageDetail] = useState(null);
  const packageContentList = useMemo(() => {
    if (packageDetail) {
      return [
        {
          name: t('PackageCode'),
          value: packageDetail?.code,
        },
        {
          name: t('Capacity'),
          value: packageDetail?.data,
        },
        {
          name: t('CallMinute'),
          value: packageDetail?.call,
        },
        {
          name: t('Message'),
          value: packageDetail?.sms,
        },
        {
          name: t('FreeData'),
          value: _.split(packageDetail?.promotionIcons, ','),
        },
      ];
    }
    return [];
  }, [packageDetail]);
  const [detailShowing, setDetailShowing] = useState(false);
  const [loading, setLoading] = useState(false);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
      setPackageDetail(null);
    }
  }

  async function requestPackageDetail(packageId) {
    setLoading(true);
    try {
      const res = await packageApi.getPackageDetail(packageId);
      const data = res?.data;
      // console.log({ data });
      if (data) {
        setPackageDetail(data);
      }
    } catch (error) {
      console.log(`Request package detail error: ${error?.message}`);
    }
    setLoading(false);
  }

  function handleCancelRegister() {
    AppDialogHelper.show(
      t('Notice'),
      t('PleaseTakeThisActionOnApp'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
            setShowing(true);
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.success
    );
    setShowing(false);
  }

  function handleRegister() {
    AppDialogHelper.show(
      t('Notice'),
      t('PleaseTakeThisActionOnApp'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
            setShowing(true);
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.success
    );
    setShowing(false);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (packageItem) {
      const packageId = packageItem?.packageId;
      if (packageId) {
        requestPackageDetail(packageId);
      }
    }
  }, [packageItem]);

  return (
    <div>
      <Modal
        className=""
        backdrop="static"
        centered
        show={show && showing}
        size="lg"
        onHide={handleClose}
        onExit={() => {
          Utils.unlockedBodyScroll();
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title></Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>
        <Modal.Body
          className="py-0 px-14 overflow-auto"
          style={{
            maxHeight: '75vh',
          }}
        >
          {loading ? (
            <div className="mt-4">
              <SaymeeEmpty loading={loading} />
            </div>
          ) : (
            <div className="mt-4">
              <div
                className="px-10 py-6 position-relative overflow-hidden"
                style={{
                  // backgroundColor: packageDetail?.backgroundColor,
                  borderRadius: '12px',
                  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                  aspectRatio: 1005 / 321,
                }}
              >
                <img
                  src={packageDetail?.banner}
                  className="position-absolute right-0 top-0 zindex-0 w-100 h-100"
                  style={{
                    objectFit: 'cover',
                  }}
                />
                {/* <img
                src={packageDetail?.banner}
                className="position-absolute right-0 top-0 zindex-0 w-70px h-70px"
                style={{
                  objectFit: 'cover',
                }}
              /> */}

                {/* <p
                className="mb-0"
                style={{
                  fontWeight: 700,
                  fontSize: '1.2rem',
                  color: packageDetail?.contentColor,
                }}
              >
                {t('PackageName')}:
              </p>
              <p
                className="mb-0"
                style={{
                  fontWeight: 700,
                  fontSize: '1.8rem',
                  color: packageDetail?.contentColor ?? AppResource.colors.textPrimary,
                }}
              >
                {packageDetail?.title}
              </p> */}
              </div>

              <div
                className="px-6 py-6 overflow-hidden mt-6 d-flex align-items-center justify-content-around flex-wrap"
                style={{
                  borderRadius: '12px',
                  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
                }}
              >
                <div className="d-flex align-items-center my-2">
                  <img src={AppResource.icons.icPriceTag} className="w-40px h-40px" />
                  <p
                    className="mb-0 ml-2"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >{`${Utils.formatNumber(packageDetail?.price)} đ`}</p>
                </div>

                <div className="d-flex align-items-center my-2">
                  <img src={AppResource.icons.icClock} className="w-40px h-40px" />
                  <p
                    className="mb-0 ml-2"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {packageDetail?.efficiency_text}
                  </p>
                </div>

                <div className="d-flex align-items-center my-2">
                  <div
                    className="w-35px h-35px rounded-circle d-flex align-items-center justify-content-center"
                    style={{
                      backgroundColor: AppResource.colors.feature,
                    }}
                  >
                    <img src={AppResource.icons.icLoyaltyPoint} className="w-20px h-20px" />
                  </div>
                  <p
                    className="mb-0 ml-2"
                    style={{
                      fontWeight: 600,
                      fontSize: '1.4rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    {`+${Utils.formatNumber(packageDetail?.point)}`}
                  </p>
                </div>
              </div>

              {/* detail list */}
              <div
                className="mt-6 mx-4 shadow"
                style={{
                  borderRadius: '12px',
                }}
              >
                {packageContentList.map((item, index) => {
                  return (
                    <div
                      className="d-flex align-items-center justify-content-between px-10 position-relative overflow-hidden bg-white row"
                      key={index}
                      style={
                        index === 0
                          ? {
                              borderTopLeftRadius: '12px',
                              borderTopRightRadius: '12px',
                              paddingBottom: 'calc(16px + 12px)',
                              paddingTop: '16px',
                            }
                          : index < packageContentList.length - 1
                          ? {
                              borderTopLeftRadius: '12px',
                              borderTopRightRadius: '12px',
                              paddingBottom: 'calc(16px + 12px)',
                              paddingTop: '16px',
                              marginTop: '-12px',
                            }
                          : {
                              borderTopLeftRadius: '12px',
                              borderTopRightRadius: '12px',
                              borderBottomLeftRadius: '12px',
                              borderBottomRightRadius: '12px',
                              paddingBottom: 'calc(16px)',
                              paddingTop: '16px',
                              marginTop: '-12px',
                            }
                      }
                    >
                      <div
                        className="position-absolute top-0 left-0 w-100 h-100 zindex-0"
                        style={{
                          backgroundColor: packageDetail?.backgroundColorWeb,
                          opacity: `${(1 + index) / (packageContentList.length + 1)}`,
                        }}
                      ></div>
                      <div
                        className="col-4"
                        style={{
                          borderRight: '1px solid',
                          borderRightColor: `${packageDetail?.backgroundColorWeb}${
                            ((1 + index) * 100) / packageContentList.length
                          }`,
                        }}
                      >
                        <p
                          className="mb-0 flex-grow-1 zindex-1 py-2"
                          style={{
                            fontWeight: 400,
                            fontSize: '1.2rem',
                            color: AppResource.colors.textPrimary,
                          }}
                        >
                          {item.name}
                        </p>
                      </div>

                      <div className="col-8">
                        <p
                          className="mb-0 flex-grow-1 text-right zindex-1"
                          style={{
                            fontWeight: 600,
                            fontSize: '1.4rem',
                            color: AppResource.colors.textPrimary,
                          }}
                        >
                          {!_.isArray(item.value) ? (
                            item.value
                          ) : (
                            <div>
                              {item.value.map((subItem, subIndex) => {
                                return (
                                  <img
                                    src={subItem}
                                    key={subIndex}
                                    className="w-30px h-30px ml-3"
                                  />
                                );
                              })}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>

              {/* guide */}
              <div className="mt-4">
                <p
                  className="mb-0"
                  style={{
                    color: '#6B686A',
                  }}
                >
                  {packageDetail?.guide}
                </p>
              </div>

              {/* detail */}
              <div
                className="mt-6 d-flex align-items-center justify-content-between cursor-pointer hover-opacity-60"
                onClick={() => {
                  setDetailShowing(!detailShowing);
                }}
              >
                <p
                  className="mb-0"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.4rem',
                    color: AppResource.colors.textPrimary,
                  }}
                >
                  {t('Description')}
                </p>
                <img
                  src={AppResource.icons.icArrowDown}
                  className=""
                  style={{
                    transform: `rotate(${detailShowing ? '0' : '180'}deg)`,
                  }}
                />
              </div>
              {detailShowing && (
                <div
                  className="mt-2"
                  style={{
                    transition: 'all 0.3s ease-out',
                  }}
                >
                  <div
                    className="mb-0"
                    style={{
                      fontWeight: 400,
                      fontSize: '1.1rem',
                      color: AppResource.colors.textPrimary,
                    }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: packageDetail?.newDescription }}></div>
                  </div>
                </div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center">
          <div
            className="d-flex row"
            style={{
              width: '60%',
            }}
          >
            <SaymeeButton
              text={isRegisterPackage ? t('CancelRegister') : t('Register')}
              className="saymee-primary-button px-10 h-50px"
              disabled={loading}
              onPress={() => {
                if (isRegisterPackage) {
                  handleCancelRegister();
                } else {
                  handleRegister();
                }
              }}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalPackageDetail;
