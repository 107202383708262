import { updateAxiosApiKey, updateAxiosUserId } from 'api/axiosClient';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import UserHelper from 'general/helpers/UserHelper';
import Global from 'general/utils/Global';
import {
  setTransactionHistoryMonth,
  setTransactionHistoryYear,
} from 'modules/Saymee/features/Account/accountSlice';
import {
  thunkGetLoyaltyCategoryList,
  thunkGetLoyaltyLevelList,
  thunkGetMyGiftList,
  thunkGetPointHistory,
} from 'modules/Saymee/features/Loyalty/loyaltySlice';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

AccountListener.propTypes = {};

const sTag = '[AccountListener]';

function AccountListener(props) {
  // MARK: --- Params ---
  const dispatch = useDispatch();
  const currentAccount = useSelector((state) => state.account.current);
  const currentPhoneInfo = useSelector((state) => state.account.phoneInfo);
  const { transactionHistoryMonth, transactionHistoryYear, transactionHistoryList } = useSelector(
    (state) => state.account
  );
  const loading = useRef(false);

  // MARK: --- Functions ---
  async function getAccountData() {
    if (loading.current) {
      return;
    }
    if (UserHelper.checkApiKeyValid()) {
      loading.current = true;
      console.log(localStorage.getItem(PreferenceKeys.apiKey));
      await UserHelper.requestCurrentAccountInfo();
      await UserHelper.requestProfile();
      await UserHelper.requestUserInfo();
      await UserHelper.requestPackageAndData();
      await UserHelper.requestRegisteredServiceList();
      await UserHelper.requestPaymentHistoryList();
      await UserHelper.requestTransactionHistoryList();

      // Lay danh sach level loyalty
      dispatch(thunkGetLoyaltyLevelList());
      // Lay danh sach lich su diem
      dispatch(thunkGetPointHistory(Global.g_filterPointHistory));
      // Lay danh sach the loai qua
      dispatch(thunkGetLoyaltyCategoryList());
      // Lay danh sach qua cua toi
      dispatch(thunkGetMyGiftList());

      loading.current = false;
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (currentAccount) {
      if (currentAccount?.apiSecret) {
        localStorage.setItem(PreferenceKeys.apiKey, currentAccount?.apiSecret);
        updateAxiosApiKey(currentAccount?.apiSecret);
      }
      if (currentAccount?.userId) {
        localStorage.setItem(PreferenceKeys.userId, currentAccount?.userId);
        updateAxiosUserId(currentAccount?.userId);
      }
      console.log({ currentAccount });
      if (currentAccount?.phone[0]?.phone) {
        localStorage.setItem(PreferenceKeys.savedLoginPhone, currentAccount?.phone[0]?.phone);
      }
      if (currentAccount?.refreshKey) {
        localStorage.setItem(PreferenceKeys.refreshKey, currentAccount?.refreshKey);
      }
    }

    getAccountData();
  }, [currentAccount]);

  useEffect(() => {
    if (currentPhoneInfo) {
      UserHelper.requestLoyaltyInfo();
    }
  }, [currentPhoneInfo]);

  useEffect(() => {
    if (
      transactionHistoryList &&
      _.isArray(transactionHistoryList) &&
      transactionHistoryList.length > 0 &&
      transactionHistoryMonth === 0 &&
      transactionHistoryYear === 0
    ) {
      const newestTransactionHistory = transactionHistoryList[0];
      const billCycle = newestTransactionHistory?.billCycle;
      if (billCycle) {
        const month = moment(billCycle).get('month') + 1;
        const year = moment(billCycle).get('year');
        dispatch(setTransactionHistoryMonth(month));
        dispatch(setTransactionHistoryYear(year));
      }
    }
  }, [transactionHistoryList]);

  return <></>;
}

export default AccountListener;
