// Import reducers
import appReducer from './appSlice';
import accountReducer from 'modules/Saymee/features/Account/accountSlice';
import loyaltyReducer from 'modules/Saymee/features/Loyalty/loyaltySlice';

const { configureStore } = require('@reduxjs/toolkit');

// root reducer
const rootReducer = {
  app: appReducer,
  account: accountReducer,
  loyalty: loyaltyReducer,
};

// app store
const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_DEV_TOOLS_ENABLE == 1 ? true : false,
});

export default store;
