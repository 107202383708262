import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import ClipLoader from 'react-spinners/ClipLoader';

CellFeeCheck.propTypes = {
  beforeIcon: PropTypes.string,
  label: PropTypes.string,
  fare: PropTypes.number,
  afterIcon: PropTypes.string,
  onPress: PropTypes.func,
  loading: PropTypes.bool,
};

CellFeeCheck.defaultProps = {
  beforeIcon: '',
  label: '',
  fare: null,
  afterIcon: '',
  onPress: null,
  loading: false,
};

function CellFeeCheck(props) {
  // MARK: --- Params ---
  const { beforeIcon, label, fare, afterIcon, onPress, loading } = props;

  // MARK: --- Functions ---
  function handlePress() {
    if (onPress) {
      onPress();
    }
  }

  return (
    <div
      className="bg-white d-flex flex-row justify-content-between px-5 align-items-center py-8 mt-6 cursor-pointer hover-opacity-60 px-10"
      onClick={handlePress}
      style={{
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
        borderRadius: '20px',
      }}
    >
      <div className="d-flex flex-row align-items-center">
        <img src={beforeIcon} alt="icon" />
        <div className="ml-4">
          <span className="text-uppercase text-dark-50" style={{ fontSize: '1.14rem' }}>
            {label}
          </span>{' '}
          <br></br>
          <span className="text-remaining font-weight-boldest" style={{ fontSize: '1.43rem' }}>
            {Utils.formatNumber(fare)} đ
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        {loading ? (
          <ClipLoader color={AppResource.colors.feature} className="" size={20} />
        ) : (
          afterIcon && <img src={afterIcon} alt="icon" />
        )}
        {/* {afterIcon && <img src={afterIcon} alt="icon" />} */}
        <img src={AppResource.icons.icArrowRight} className="ml-2" />
      </div>
    </div>
  );
}

export default CellFeeCheck;
