import commonApi from 'api/commonApi';
import KTBSDropdown, {
  KTBSDropdowAutoCloseBehavior,
  KTBSDropdownAlignments,
} from 'general/components/OtherKeenComponents/KTBSDropdown';
import SearchField from 'general/components/SaymeeFormInputs/SearchField';
import SaymeeAvatar from 'general/components/SaymeeOtherComponents/SaymeeAvatar';
import AppResource from 'general/constants/AppResource';
import SearchHistoryHelper from 'general/helpers/SearchHistoryHelper';
import useAccount from 'hooks/useAccount';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useRouter from 'hooks/useRouter';
import DropdownAccountMenu from 'modules/Saymee/features/Account/components/DropdownAccountMenu';
import ModalPackageDetail from 'modules/Saymee/features/Account/components/ModalPackageDetail';
import ModalServiceDetail from 'modules/Saymee/features/Account/components/ModalServiceDetail';
import DropdownMobileMenu from 'modules/Saymee/features/Others/components/DropdownMobileMenu';
import DropdownSearchView from 'modules/Saymee/features/Others/components/DropdownSearchView';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './style.scss';

SaymeeHeader.propTypes = {};

const menuItemList = [
  {
    title: 'Home',
    url: '#',
  },
  {
    title: 'Introduce',
    url: '#',
  },
  {
    title: 'Service',
    url: '#',
  },
  {
    title: 'SaymeeLoyalty',
    url: '#',
  },
  {
    title: 'Support',
    url: '#',
  },
  {
    title: 'News',
    url: '#',
  },
];

function SaymeeHeader(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const router = useRouter();
  const { isLoggedIn } = useAccount();
  const [showDropdownSearch, setShowDropdownSearch] = useState(false);
  const refDropdownSearch = useRef();
  const [searchValue, setSearchValue] = useState('');
  const [searchResult, setSearchResult] = useState(null);
  const [searching, setSearching] = useState(false);
  const [modalServiceDetailShowing, setModalServiceDetailShowing] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [modalPackageDetailShowing, setModalPackageDetailShowing] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  // MARK: --- Functions ---
  async function handleSearch(text) {
    setSearching(true);
    try {
      const res = await commonApi.universalSearch(text);
      setSearchResult(res);
    } catch (error) {
      console.log(`Search error: ${error.message}`);
    }
    setSearching(false);
  }

  // MARK: --- Hooks ---
  const callbackClickOutsideDropdownSearch = useCallback(() => {
    setShowDropdownSearch(false);
  }, []);
  useOnClickOutside(refDropdownSearch, callbackClickOutsideDropdownSearch);

  return (
    <div
      id="kt_header"
      className="SaymeeHeader header header-fixed bg-white shadow-sm pt-0 position-sticky sticky-top h-70px"
    >
      <div className="container-xl d-flex align-items-stretch justify-content-between h-100">
        {/* logo & menu */}
        <div className="d-flex align-items-center">
          {/* logo */}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              router.navigate('/saymee/account');
            }}
          >
            <img className="pr-lg-10 pr-4 py-2" src={AppResource.images.imgSaymeeLogo} />
          </a>

          <div className="d-flex d-lg-none">
            <KTBSDropdown
              toggleElement={
                <img
                  src={AppResource.icons.keens.toggleRight}
                  className="w-30px h-30px cursor-pointer hover-opacity-60"
                  style={{
                    filter: 'invert(0.6)',
                  }}
                />
              }
              alignment={KTBSDropdownAlignments.start}
              dropdownMenuClassName="py-0"
              contentEl={<DropdownMobileMenu menuItemList={menuItemList} />}
              // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
            />
          </div>

          {/* menu */}
          <ul className="nav nav-tabs nav-tabs-line nav-tabs-line-3x h-100 d-none d-lg-flex">
            {menuItemList.map((item, index) => {
              return (
                <li key={index} className="nav-item">
                  <a
                    className={`nav-link ${index === -1 ? 'active' : ''}`}
                    style={{
                      height: 'calc(100% - 3px)',
                      fontWeight: 400,
                      fontSize: '1.1rem',
                      color:
                        index === 0
                          ? AppResource.colors.textPrimary
                          : AppResource.colors.textSecondary,
                    }}
                    href={item.url}
                  >
                    {t(item.title)}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>

        {/* search */}
        <div className="align-self-center d-flex align-items-center h-100">
          <div className="position-relative" ref={refDropdownSearch}>
            <SearchField
              searchFieldId="saymeeSearchField"
              placeholder={t('WhatDoesSaymeeHave')}
              onFocus={() => {
                setShowDropdownSearch(true);
              }}
              onSubmit={(text) => {
                SearchHistoryHelper.appendTextSearch(text);
                // thuc hien tim kiem
                handleSearch(text);
              }}
              value={searchValue}
              onChange={(value) => {
                setSearchValue(value);
                if (_.isEmpty(value)) {
                  setSearchResult(null);
                }
              }}
            />
            {showDropdownSearch && (
              <div
                className="position-absolute mt-1 right-0"
                style={{
                  width: '150%',
                }}
              >
                <DropdownSearchView
                  onSearch={(text) => {
                    setSearchValue(text);
                    SearchHistoryHelper.appendTextSearch(text);
                    // thuc hien tim kiem
                    handleSearch(text);
                  }}
                  searchResult={searchResult}
                  searching={searching}
                  onPressService={(item) => {
                    setSelectedService(item);
                    setModalServiceDetailShowing(true);
                  }}
                  onPressPackage={(item) => {
                    setSelectedPackage(item);
                    setModalPackageDetailShowing(true);
                  }}
                />
              </div>
            )}
          </div>

          {!router?.pathname?.includes('/auth') && (
            <div className="ml-4 h-100 position-relative d-flex align-items-center justify-content-center">
              <KTBSDropdown
                toggleElement={
                  <SaymeeAvatar
                    backgroundColor={AppResource.colors.secondary}
                    icon={AppResource.icons.icAvatarDefault}
                    onPress={() => {
                      // router.navigate('/saymee/account');
                    }}
                  />
                }
                alignment={KTBSDropdownAlignments.end}
                dropdownMenuClassName="py-0"
                contentEl={<DropdownAccountMenu />}
                // autoCloseBehavior={KTBSDropdowAutoCloseBehavior.outside}
              />

              <div
                className="h-3px w-100 position-absolute bottom-0"
                style={{
                  backgroundColor: AppResource.colors.primary,
                }}
              ></div>
            </div>
          )}
        </div>
      </div>

      {/* Modal service detail */}
      <ModalServiceDetail
        serviceItem={selectedService}
        show={modalServiceDetailShowing}
        onClose={() => {
          setModalServiceDetailShowing(false);
        }}
      />

      {/* Modal package detail */}
      <ModalPackageDetail
        packageItem={selectedPackage}
        show={modalPackageDetailShowing}
        onClose={() => {
          setModalPackageDetailShowing(false);
        }}
      />
    </div>
  );
}

export default SaymeeHeader;
