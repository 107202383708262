import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

DropdownMobileMenu.propTypes = {
  menuItemList: PropTypes.array,
};

DropdownMobileMenu.defaultProps = {
  menuItemList: [],
};

/**
 *
 * @param {{
 * menuItemList: object[]
 * }} props
 * @returns
 */
function DropdownMobileMenu(props) {
  // MARK: --- Params ---
  const { menuItemList } = props;
  const { t } = useTranslation();

  return (
    <div
      className="bg-white px-6 py-6 position-relative overflow-hidden w-250px"
      style={{
        boxShadow: '0px 0px 2px rgba(227, 29, 147, 0.04), 0px 4px 20px rgba(227, 29, 147, 0.06)',
        borderRadius: '20px',
      }}
    >
      <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
        {menuItemList.map((item, index) => {
          return (
            <div
              className={`navi-item ${index < menuItemList.length - 1 ? 'mb-2' : ''}`}
              key={index}
            >
              <a href={item?.url} className="navi-link py-4">
                <span className="navi-text font-size-lg">{t(item?.title)}</span>
              </a>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default DropdownMobileMenu;
