import React from 'react';
import PropTypes from 'prop-types';

SaymeeContent.propTypes = {};

function SaymeeContent(props) {
  return (
    <div id="kt_content" className="content d-flex flex-column flex-column-fluid bg-white">
      <div className="container min-h-100">{props.children}</div>
    </div>
  );
}

export default SaymeeContent;
