import PreferenceKeys from 'general/constants/PreferenceKeys';
import axiosClient from './axiosClient';

const accountApi = {
  /**
   * Lay thong tin SDT
   * @returns
   */
  getPhoneInfo: () => {
    const url = '/user/getphoneinfo';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Lay thong tin user
   * @returns
   */
  getUserInfo: () => {
    const url = '/user/userinfov2';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Lay thong tin tai khoan (chinh + khuyen mai)
   * @returns
   */
  getProfile: () => {
    const url = '/user/getprofile';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Lay thong tin goi cuoc va data
   * @returns
   */
  getPackageAndData: () => {
    const url = '/user/getpackageanddata';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },

  /**
   * Lay ds dich vu da dang ky
   * @returns
   */
  getRegisteredServiceList: () => {
    const url = '/user/getservice';
    return axiosClient.post(
      url,
      {},
      {
        headers: {
          phone: localStorage.getItem(PreferenceKeys.savedLoginPhone),
        },
      }
    );
  },
};

export default accountApi;
