import React from 'react';
import PropTypes from 'prop-types';
import AuthBaseLayout from 'general/components/BaseLayout/AuthBaseLayout';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import AppResource from 'general/constants/AppResource';
import { Navigate, Route, Routes } from 'react-router-dom';
import SignInView from './components/SignInView';
import { useTranslation } from 'react-i18next';
import ForgotPasswordScreen from './screens/ForgotPasswordScreen';

Auth.propTypes = {};

function Auth(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();

  return (
    <AuthBaseLayout>
      <Routes>
        <Route path="/" element={<Navigate to="sign-in" />} />
        <Route path="sign-in" element={<SignInView />} />
        <Route path="forgot-password" element={<ForgotPasswordScreen />} />
      </Routes>
    </AuthBaseLayout>
  );
}

export default Auth;
