import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

TrafficItem.propTypes = {
  total: PropTypes.number,
  remaining: PropTypes.number,
  unit: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
};

TrafficItem.defaultProps = {
  total: 0,
  remaining: 0,
  unit: '',
  text: '',
  color: '',
};

function TrafficItem(props) {
  // MARK: --- Params ---
  const { total, remaining, unit, text, color } = props;
  const percent = useMemo(() => {
    if (total === 0) {
      return 0;
    }
    return ((total - remaining) / total) * 100;
  }, [total, remaining]);

  return (
    <div className="d-flex flex-column align-items-center" style={{}}>
      <div
        className=""
        style={{
          width: '70%',
        }}
      >
        <CircularProgressbar
          value={percent}
          text={`${percent.toFixed(0)}%`}
          strokeWidth={6}
          styles={{
            path: {
              stroke: color,
            },
            trail: {
              stroke: '#CDD2FD',
            },
            text: {
              fill: AppResource.colors.feature,
              fontSize: '1.6rem',
              fontWeight: 700,
            },
          }}
        />
      </div>

      <p
        className="mb-2 mt-4"
        style={{
          fontWeight: 700,
          color: AppResource.colors.feature,
          fontSize: '1.2rem',
        }}
      >
        {Utils.formatNumber(remaining)}
        <span style={{}}>
          /{Utils.formatNumber(total)} {unit}
        </span>
      </p>
      <p
        className="text-uppercase text-dark-50"
        style={{
          fontWeight: 500,
          fontSize: '1rem',
        }}
      >
        {text}
      </p>
    </div>
  );
}

export default TrafficItem;
