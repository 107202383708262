import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import Utils from 'general/utils/Utils';
import AppResource from 'general/constants/AppResource';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment from 'moment';
import PreferenceKeys from 'general/constants/PreferenceKeys';

ModalOTPInput.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  phone: PropTypes.string,
  onPressResendOTP: PropTypes.func,
  flagResendOTPCodeSuccess: PropTypes.object,
  preferenceKey: PropTypes.string,
  onPressSubmit: PropTypes.func,
};

ModalOTPInput.defaultProps = {
  show: false,
  onClose: null,
  phone: '',
  onPressResendOTP: null,
  flagResendOTPCodeSuccess: {},
  preferenceKey: '',
  onPressSubmit: null,
};

function ModalOTPInput(props) {
  // MARK: --- Params ---
  const {
    show,
    onClose,
    phone,
    onPressResendOTP,
    flagResendOTPCodeSuccess,
    preferenceKey,
    onPressSubmit,
  } = props;
  const { t } = useTranslation();
  const [otpCodeValid, setOtpCodeValid] = useState(() => {
    const savedOtpCodeExpiredTime = localStorage.getItem(preferenceKey);
    if (savedOtpCodeExpiredTime) {
      return moment(savedOtpCodeExpiredTime).isAfter(moment());
    }
    return false;
  });
  const [otpRemainingSeconds, setOtpRemainingSeconds] = useState(59);
  const refIntervalOTP = useRef(null);
  const refInput1 = useRef();
  const refInput2 = useRef();
  const refInput3 = useRef();
  const refInput4 = useRef();
  const [inputCode1, setInputCode1] = useState('');
  const [inputCode2, setInputCode2] = useState('');
  const [inputCode3, setInputCode3] = useState('');
  const [inputCode4, setInputCode4] = useState('');
  const inputOTPCode = useMemo(() => {
    return inputCode1 + inputCode2 + inputCode3 + inputCode4;
  }, [inputCode1, inputCode2, inputCode3, inputCode4]);

  // MARK: --- Hooks ---
  useEffect(() => {
    const savedOtpCodeExpiredTime = localStorage.getItem(preferenceKey);
    if (savedOtpCodeExpiredTime && show) {
      const momentSavedOtpCodeExpiredTime = moment(savedOtpCodeExpiredTime);

      refIntervalOTP.current = setInterval(() => {
        const remainingSeconds = momentSavedOtpCodeExpiredTime.diff(moment(), 'seconds');
        // console.log({ remainingSeconds });
        setOtpRemainingSeconds(remainingSeconds);
        if (remainingSeconds <= 0) {
          localStorage.removeItem(preferenceKey);
          setOtpCodeValid(false);
          clearInterval(refIntervalOTP.current);
          setOtpRemainingSeconds(0);
        }
      }, 1000);
    }

    return () => {
      if (refIntervalOTP.current) {
        clearInterval(refIntervalOTP.current);
      }
    };
  }, [otpCodeValid, show, flagResendOTPCodeSuccess]);

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
    if (refIntervalOTP.current) {
      clearInterval(refIntervalOTP.current);
    }
  }

  function handleResendOTP() {
    if (onPressResendOTP) {
      onPressResendOTP();
    }
  }

  function handleKeyboardPress(event) {
    const targetName = event?.target?.name;
    // If the user presses the "Enter" key on the keyboard
    if (event.key === 'Backspace' || event.key === 'Delete') {
      // Cancel the default action, if needed
      event.preventDefault();
      // Trigger the button element with a click
      switch (targetName) {
        case 'input1':
          setInputCode1('');
          break;
        case 'input2':
          setInputCode2('');
          // refInput1.current.focus();
          // setInputCode1('');
          break;
        case 'input3':
          setInputCode3('');
          // refInput2.current.focus();
          // setInputCode2('');
          break;
        case 'input4':
          setInputCode4('');
          // refInput3.current.focus();
          // setInputCode3('');
          break;
        default:
          break;
      }
    }
  }

  function handleInputChange(name, value) {
    const newValue = value?.slice(-1);
    switch (name) {
      case 'input1':
        setInputCode1(newValue);
        if (value.trim().length > 0) {
          refInput2.current.focus();
          // setInputCode2('');
        }
        break;
      case 'input2':
        setInputCode2(newValue);
        if (value.trim().length > 0) {
          refInput3.current.focus();
          // setInputCode3('');
        } else {
          // refInput1.current.focus();
          // setInputCode1('');
        }
        break;
      case 'input3':
        setInputCode3(newValue);
        if (value.trim().length > 0) {
          refInput4.current.focus();
          // setInputCode4('');
        } else {
          // refInput2.current.focus();
          // setInputCode2('');
        }
        break;
      case 'input4':
        setInputCode4(newValue);
        if (value.trim().length > 0) {
          refInput4.current.blur();
        } else {
          // refInput3.current.focus();
          // setInputCode3('');
        }
        break;
      default:
        break;
    }
  }

  function handleSubmit() {
    if (onPressSubmit) {
      onPressSubmit(inputOTPCode);
    }
  }

  return (
    <div>
      <Modal
        className=""
        show={show}
        centered
        backdrop="static"
        onHide={handleClose}
        onExit={() => {
          Utils.unlockedBodyScroll();
          refInput1.current?.removeEventListener('keydown', handleKeyboardPress);
          refInput2.current?.removeEventListener('keydown', handleKeyboardPress);
          refInput3.current?.removeEventListener('keydown', handleKeyboardPress);
          refInput4.current?.removeEventListener('keydown', handleKeyboardPress);
          setInputCode1('');
          setInputCode2('');
          setInputCode3('');
          setInputCode4('');
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
          refInput1.current?.addEventListener('keydown', handleKeyboardPress);
          refInput2.current?.addEventListener('keydown', handleKeyboardPress);
          refInput3.current?.addEventListener('keydown', handleKeyboardPress);
          refInput4.current?.addEventListener('keydown', handleKeyboardPress);
          refInput1.current?.focus();
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title className="w-100 text-center font-weight-bolder font-size-lg">
            {t('ConfirmOTP')}
          </Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>

        <Modal.Body className="pb-0">
          <div className="px-4">
            <p className="mb-0 text-dark text-center">
              {t('TextConfirmOTPWithPhone', {
                phone: _.padStart(phone?.slice(-3), phone?.length, '*'),
              })}
            </p>
            <div className="my-6 d-flex align-items-center justify-content-center">
              {/* input 1 */}
              <div className="w-50px h-55px mr-4">
                <input
                  type="number"
                  ref={refInput1}
                  name="input1"
                  className="saymee-input-otp w-100 h-100"
                  value={inputCode1}
                  onChange={(e) => {
                    handleInputChange('input1', e?.target?.value);
                  }}
                />
              </div>
              {/* input 2 */}
              <div className="w-50px h-55px mr-4">
                <input
                  type="number"
                  ref={refInput2}
                  name="input2"
                  className="saymee-input-otp w-100 h-100"
                  value={inputCode2}
                  onChange={(e) => {
                    handleInputChange('input2', e?.target?.value);
                  }}
                />
              </div>
              {/* input 3 */}
              <div className="w-50px h-55px mr-4">
                <input
                  type="number"
                  ref={refInput3}
                  name="input3"
                  className="saymee-input-otp w-100 h-100"
                  value={inputCode3}
                  onChange={(e) => {
                    handleInputChange('input3', e?.target?.value);
                  }}
                />
              </div>
              {/* input 4 */}
              <div className="w-50px h-55px mr-4">
                <input
                  type="number"
                  ref={refInput4}
                  name="input4"
                  className="saymee-input-otp w-100 h-100"
                  value={inputCode4}
                  onChange={(e) => {
                    handleInputChange('input4', e?.target?.value);
                  }}
                />
              </div>
            </div>
            <p className="mb-0 text-dark text-center">
              {t('OTPCodeExpireAfter', { second: otpRemainingSeconds })}
            </p>

            <div
              className="cursor-pointer hover-opacity-60 mt-4"
              style={{
                pointerEvents: otpRemainingSeconds > 0 ? 'none' : '',
              }}
              onClick={handleResendOTP}
            >
              <p
                className="mb-0 text-center"
                style={{
                  color: AppResource.colors.feature,
                  opacity: otpRemainingSeconds > 0 ? 0.6 : 1,
                }}
              >
                {t('ResendOTPCode')}?{' '}
                <i
                  className="fal fa-undo ml-1"
                  style={{
                    color: AppResource.colors.feature,
                    opacity: otpRemainingSeconds > 0 ? 0.6 : 1,
                  }}
                />
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100">
            <SaymeeButton
              text={t('Confirm')}
              className="saymee-primary-button px-10 h-40px"
              onPress={handleSubmit}
            />

            <SaymeeButton
              text={t('Close')}
              className="saymee-primary-outline-button px-10 h-40px mt-4"
              onPress={handleClose}
            />
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalOTPInput;
