import { setFlagSearchHistoryChange } from 'app/appSlice';
import store from 'app/store';
import PreferenceKeys from 'general/constants/PreferenceKeys';

const SearchHistoryHelper = {
  /**
   * Them tu khoa tim kiem
   * @param {string} text Tu khoa tim kiem
   */
  appendTextSearch: (text) => {
    if (_.isEmpty(text.trim())) {
      return;
    }
    let savedSearchHistoryText = localStorage.getItem(PreferenceKeys.savedSearchHistory);
    let savedSearchHistoryList = [];
    savedSearchHistoryList = _.split(savedSearchHistoryText, ',').filter(
      (item) => item.trim() !== text.trim()
    );
    savedSearchHistoryList.push(text.trim());
    savedSearchHistoryList = _.filter(savedSearchHistoryList, (item) => !_.isEmpty(item.trim()));
    savedSearchHistoryText = savedSearchHistoryList.join(',');
    localStorage.setItem(PreferenceKeys.savedSearchHistory, savedSearchHistoryText);
    store.dispatch(setFlagSearchHistoryChange({}));
  },

  /**
   * Xoa lich su tim kiem
   */
  clearSearchHistory: () => {
    localStorage.removeItem(PreferenceKeys.savedSearchHistory);
    store.dispatch(setFlagSearchHistoryChange({}));
  },
};

export default SearchHistoryHelper;
