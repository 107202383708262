import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import AppResource from 'general/constants/AppResource';
import AppConfigs from 'general/constants/AppConfigs';
import Utils from 'general/utils/Utils';

HelpDownloadScreen.propTypes = {};

function HelpDownloadScreen(props) {
  const { t } = useTranslation();
  return (
    <div className="row text-remaining">
      <div className="col-lg-6">
        <p className="text-uppercase font-weight-boldest" style={{ fontSize: '2rem' }}>
          {t('DownloadAppGuide')}
        </p>
        <p className="font-weight-boldest">{t('SaymeeAppCanBeDownloadByTwoWay')}</p>
        <p>
          <b>{t('Way')} 1: </b>
          {t('AccessToAppStoreOrCHPlay,')} <span className="text-lowercase">{t('Search')}</span>{' '}
          <b className="text-primary">Saymee</b> <span>{t('AndDownloadApplication')}</span>
        </p>
        <p>
          {' '}
          <b>{t('Way')} 2: </b>
          {t('UsingYourPhoneToScanQROrDownloadApplication')}
        </p>

        {/* way to download */}
        <div>
          <div className="w-100 d-flex flex-row">
            <div className="d-flex flex-column align-items-start">
              {/* download on app store */}
              <div
                className="cursor-pointer hover-opacity-80"
                onClick={() => {
                  Utils.openInNewTab(AppConfigs.iosAppStore);
                }}
              >
                <img src={AppResource.icons.icAppStore} />
              </div>

              {/* download on ch play */}
              <div
                className="mt-5 cursor-pointer hover-opacity-80"
                onClick={() => {
                  Utils.openInNewTab(AppConfigs.androidAppStore);
                }}
              >
                <img src={AppResource.icons.icCHPlay} />
              </div>

              <div
                className="mr-4 p-4 bg-white mt-5"
                style={{
                  filter:
                    'drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.06)) drop-shadow(0px 4px 32px rgba(0, 0, 0, 0.08)) drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.04)) drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06))',
                  borderRadius: '12px',
                  width: '241px',
                }}
              >
                <QRCode
                  // size={256}
                  style={{ height: 'auto', width: '100%' }}
                  value={AppConfigs.downloadAppLink}
                  // viewBox={`0 0 256 256`}
                />
              </div>
            </div>

            <div className="d-flex flex-column align-items-center ml-6 mt-6">
              <p className="font-weight-boldest w-100">
                {t('DownloadApp')} <span className="text-primary">Saymee</span>{' '}
                {t('InMobileApplicationStore')}
              </p>
              <p>{t('SaymeeAppWasPublishedOnBothIOSAndAndroid')}</p>
              <div className="mt-5">
                <p className="font-weight-boldest">{t('ScanQRToDownloadApp')}</p>
                <p>{t('UseYourPhoneToScanThisQRToDownloadApp')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 d-lg-block d-none">
        <img src={AppResource.images.imgDownloadApp} alt="icon" />
      </div>
    </div>
  );
}

export default HelpDownloadScreen;
