import AppResource from 'general/constants/AppResource';
import PropTypes from 'prop-types';

Saymee404.propTypes = {
  image: PropTypes.any,
  title: PropTypes.string,
  message: PropTypes.string,
  buttonTitle: PropTypes.string,
  onPressButton: PropTypes.func,
};

Saymee404.defaultProps = {
  image: AppResource.images.imgSaymee404,
  title: '',
  message: '',
  buttonTitle: '',
  onPressButton: null,
};

function Saymee404(props) {
  // MARK: --- Params ---
  const { image, message, title, buttonTitle, onPressButton } = props;

  return (
    <div className="d-flex flex-column align-items-center justify-content-center h-100 pb-10 overflow-hidden">
      <p
        className="text-center mt-6 text-uppercase text-dark"
        style={{
          fontWeight: 700,
          fontSize: '1.6rem',
        }}
      >
        {title}
      </p>

      <img className="" src={image} />

      <p
        className="text-center mt-6 text-dark"
        style={{
          fontWeight: 400,
          fontSize: '1.08rem',
        }}
      >
        {message}
      </p>
      <a
        className=""
        href="#"
        style={{
          fontWeight: 600,
          fontSize: '1.08rem',
          color: AppResource.colors.feature,
        }}
        onClick={(e) => {
          e.preventDefault();
          if (onPressButton) {
            onPressButton();
          }
        }}
      >
        {buttonTitle}
      </a>
    </div>
  );
}

export default Saymee404;
