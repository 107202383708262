import React from 'react';
import PropTypes from 'prop-types';
import Utils from 'general/utils/Utils';
import AppResource from 'general/constants/AppResource';

CellPaymentHistory.propTypes = {
  content: PropTypes.string,
  time: PropTypes.string,
  fare: PropTypes.number,
  result: PropTypes.string,
};

CellPaymentHistory.defaultProps = {
  content: '',
  time: '',
  fare: null,
  result: null,
};

function CellPaymentHistory(props) {
  // MARK --- Params: ---
  const { content, time, fare, result } = props;
  return (
    <div
      className="bg-white d-flex align-items-center justify-content-between py-5 px-6 mt-6"
      style={{
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 12px rgba(0, 0, 0, 0.04)',
        borderRadius: '12px',
      }}
    >
      <div className="d-flex align-items-center">
        <img src={AppResource.icons.icRechargeMoney} />
        <div className="ml-4">
          <p
            className="text-remaining mb-1"
            style={{ fontSize: '1.4rem', fontWeight: 400, color: '#262224' }}
          >
            {content}
          </p>
          <span style={{ fontSize: '1rem' }}>{time}</span>
          {result && <span>- {result}</span>}
        </div>
      </div>
      <p className="font-weight-boldest mb-0" style={{ fontSize: '1.43rem' }}>
        {Utils.formatNumber(fare)} đ
      </p>
    </div>
  );
}

export default CellPaymentHistory;
