import AppData from 'general/constants/AppData';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

ModalChooseHeroscope.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onChoose: PropTypes.func,
};

ModalChooseHeroscope.defaultProps = {
  show: false,
  onClose: null,
  onChoose: null,
};

/**
 *
 * @param {{
 * show: boolean,
 * onClose: function,
 * onChoose: function,
 * }} props
 * @returns
 */
function ModalChooseHeroscope(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { show, onClose, onChoose } = props;

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  function handleConfirm() {}

  return (
    <div>
      <Modal
        className=""
        show={show}
        centered
        backdrop="static"
        size=""
        onHide={handleClose}
        onExit={() => {
          Utils.unlockedBodyScroll();
        }}
        onEntered={() => {
          Utils.lockedBodyScroll();
        }}
      >
        <Modal.Header className="px-5 pt-5 pb-0">
          <Modal.Title className="w-100">
            <p
              className="mb-0 text-dark text-center"
              style={{
                fontWeight: 600,
                fontSize: '1.4rem',
              }}
            >
              {t('ChooseHeroScope')}
            </p>
          </Modal.Title>
          <div
            className="cursor-pointer hover-opacity-60"
            onClick={() => {
              handleClose();
            }}
          >
            <img src={AppResource.icons.icClose} />
          </div>
        </Modal.Header>
        <Modal.Body
          className="py-0 px-14 mt-6 overflow-auto"
          style={{
            maxHeight: '60vh',
          }}
        >
          {AppData.loyaltyHeroscopeGroup.map((item, index) => {
            return (
              <div key={index} className="mt-4 mb-6">
                <p
                  className="mb-2 text-dark px-2"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.2rem',
                  }}
                >
                  {item.name}
                </p>
                <div className="d-flex justify-content-around">
                  {item?.value?.map((subItem, subIndex) => {
                    return (
                      <div
                        key={subIndex}
                        className="d-flex flex-column align-items-center"
                        style={{
                          width: '30%',
                        }}
                      >
                        <div
                          className="w-100 rounded-circle d-flex align-items-center justify-content-center overflow-hidden cursor-pointer hover-opacity-60 mb-2"
                          style={{
                            backgroundColor: item.bgColor,
                            aspectRatio: 1,
                          }}
                          onClick={() => {
                            if (onChoose) {
                              onChoose({ ...subItem, bgColor: item?.bgColor });
                            }
                          }}
                        >
                          <img
                            src={subItem.icon}
                            className=""
                            style={{
                              width: '70%',
                              height: '70%',
                              objectFit: 'contain',
                            }}
                          />
                        </div>
                        <div className="d-flex flex-column align-items-center">
                          <p
                            className="mb-0 text-dark"
                            style={{
                              fontWeight: 600,
                              fontSize: '1rem',
                            }}
                          >
                            {subItem.name}
                          </p>
                          <p
                            className="mb-0 text-dark"
                            style={{
                              fontWeight: 400,
                              fontSize: '1rem',
                            }}
                          >
                            {subItem.value}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </Modal.Body>
        <Modal.Footer className="d-flex align-items-center justify-content-center py-2">
          {/* <div
            className="d-flex row"
            style={{
              width: '60%',
            }}
          >
            <SaymeeButton
              text={t('Confirm')}
              className="saymee-primary-button px-10 h-50px"
              onPress={handleConfirm}
            />
          </div> */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalChooseHeroscope;
