import PreferenceKeys from 'general/constants/PreferenceKeys';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useSearchHistory() {
  // MARK: --- Params ---
  const { flagSearchHistoryChange } = useSelector((state) => state.app);

  return useMemo(() => {
    let searchHistoryList = [];
    const textSearchHistory = localStorage.getItem(PreferenceKeys.savedSearchHistory);
    if (textSearchHistory) {
      searchHistoryList = _.split(textSearchHistory, ',');
    }

    return {
      searchHistoryList,
    };
  }, [flagSearchHistoryChange]);
}

export default useSearchHistory;
