import React from 'react';
import PropTypes from 'prop-types';
import AppResource from 'general/constants/AppResource';

CellPointHistory.propTypes = {
  title: PropTypes.string,
  textTime: PropTypes.string,
  textPoint: PropTypes.string,
  pointColor: PropTypes.string,
};

CellPointHistory.defaultProps = {
  title: '',
  textTime: '',
  textPoint: '',
  pointColor: '',
};

/**
 *
 * @param {{
 * title: string,
 * textTime: string,
 * textPoint: string,
 * pointColor: string,
 * }} props
 * @returns
 */
function CellPointHistory(props) {
  // MARK: --- Params ---
  const { title, textPoint, textTime, pointColor } = props;

  return (
    <div className="py-4 d-flex align-align-items-center justify-content-between">
      <div>
        <p
          className="mb-0"
          style={{
            fontWeight: 600,
            fontSize: '1.2rem',
            color: '#262224',
          }}
        >
          {title}
        </p>

        <div className="d-flex align-items-center mt-2">
          <i className="far fa-clock mr-2" />
          <p
            className="mb-0"
            style={{
              fontWeight: 400,
              fontSize: '1rem',
              color: '#A8A8A8',
            }}
          >
            {textTime}
          </p>
        </div>
      </div>

      <div className="d-flex align-items-center">
        <p
          className="mb-0"
          style={{
            fontWeight: 600,
            fontSize: '1.2rem',
            color: pointColor,
          }}
        >
          {textPoint}
        </p>
        <div
          className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
          style={{
            backgroundColor: AppResource.colors.feature,
          }}
        >
          <img src={AppResource.icons.icLoyaltyPoint} />
        </div>
      </div>
    </div>
  );
}

export default CellPointHistory;
