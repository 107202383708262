import { AppDialogHelper } from 'general/components/AppDialog';
import KTBSProgress from 'general/components/OtherKeenComponents/KTBSProgress';
import KTTooltip from 'general/components/OtherKeenComponents/KTTooltip';
import BreadCrumb from 'general/components/SaymeeOtherComponents/BreadCrumb';
import SaymeeButton from 'general/components/SaymeeOtherComponents/SaymeeButton';
import SaymeeEmpty from 'general/components/SaymeeOtherComponents/SaymeeEmpty';
import AppResource from 'general/constants/AppResource';
import Utils from 'general/utils/Utils';
import useAccount from 'hooks/useAccount';
import useRouter from 'hooks/useRouter';
import _ from 'lodash';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuItem from '../../components/MenuItem';
import ModalServiceDetail from '../../components/ModalServiceDetail';
import ServiceItem from '../../components/ServiceItem';
import TrafficItem from '../../components/TrafficItem';

AccountInfoScreen.propTypes = {};

function AccountInfoScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const {
    displayName,
    phone,
    currentLoyalty,
    nextLoyalty,
    ballance,
    packageInfo,
    registeredServiceList,
  } = useAccount();
  const router = useRouter();
  const utilityMenuItemList = useMemo(
    () => [
      {
        icon: AppResource.icons.icRechargeMoney,
        text: t('RechargeMoney'),
        onPress: () => {
          router.navigate('/saymee/account/utilities');
        },
      },
      {
        icon: AppResource.icons.icActiveSim,
        text: t('ActiveSim'),
        onPress: () => {
          router.navigate('/saymee/account/utilities');
        },
      },
      {
        icon: AppResource.icons.icRechargeMoney,
        text: t('UpdateInfo'),
        onPress: () => {
          router.navigate('/saymee/account/update-info');
        },
      },
    ],
    []
  );
  const chunkRegisteredServiceList = Utils.chunkItems(registeredServiceList, 3);
  const promotionalList = useMemo(() => {
    return [
      {
        backgroundColor: '#E1F8FF',
        icon: '',
        text: 'Merry Christmas: Mở tung nắp hộp, chộp hoàn tiền ngẫu nhiên lên đến 100% giá trị!',
        date: '2022-12-24',
      },
      {
        backgroundColor: '#DEFCF9',
        icon: '',
        text: 'Nhận ngay nhiều phần quà hấp dẫn khi mua SIM Saymee số đẹp',
        date: '2022-12-24',
      },
      {
        backgroundColor: '#D8FFE6',
        icon: '',
        text: 'Chất lượng âm thanh vượt trội, không quảng cảo cùng gói Spotify giá cực rẻ',
        date: '2022-12-24',
      },
    ];
  }, []);
  const trafficItemList = useMemo(() => {
    return [
      {
        total: packageInfo.data.total,
        remaining: packageInfo.data.remaining,
        unit: 'MB',
        text: 'Data',
        color: '#6979F8',
      },
      {
        total: packageInfo.call.total,
        remaining: packageInfo.call.remaining,
        unit: 'phút',
        text: 'Cước gọi',
        color: '#6979F8',
      },
      {
        total: packageInfo.sms.total,
        remaining: packageInfo.sms.remaining,
        unit: 'SMS',
        text: 'SMS',
        color: '#6979F8',
      },
    ];
  }, []);
  const [modalServiceDetailShowing, setModalServiceDetailShowing] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  // MARK: --- Functions ---
  function showDialogNotSupportFunction() {
    AppDialogHelper.show(
      t('Notice'),
      t('PleaseTakeThisActionOnApp'),
      [
        {
          title: t('Close'),
          type: 'positive',
          onClick: () => {
            AppDialogHelper.hide();
          },
        },
      ],
      AppResource.images.imgDialogError,
      AppResource.colors.success
    );
  }

  return (
    <div className="pb-10">
      <BreadCrumb
        breadCrumbItemList={[
          { title: t('Home'), url: '/saymee', active: false },
          { title: t('AccountInfo'), url: '#', active: true },
        ]}
      />

      {/* thong tin */}
      <div
        className="px-6 px-md-20 pt-10 pb-0 mt-6"
        style={{
          background:
            'linear-gradient(90.24deg, rgba(25, 211, 197, 0.12) 4.63%, rgba(0, 113, 206, 0.12) 95.78%)',
          borderRadius: '20px',
        }}
      >
        <div
          className="d-flex align-items-center position-relative flex-column flex-md-row"
          style={{
            paddingBottom: '110px',
          }}
        >
          <KTTooltip text={currentLoyalty.name}>
            <div
              className="w-120px h-120px rounded-circle overflow-hidden cursor-pointer p-4"
              onClick={() => {
                router.navigate('/saymee/account/loyalty');
              }}
              style={{
                backgroundColor: '#FFD7EF',
                flex: 'none',
              }}
            >
              <img src={currentLoyalty.avatar} className="w-100 h-100" />
            </div>
          </KTTooltip>
          <div className="ml-6 flex-grow-1">
            <p
              className="text-dark mb-2 text-center text-md-left"
              style={{
                fontWeight: 700,
                fontSize: '2.2rem',
              }}
            >
              {displayName}
            </p>
            <p
              className="text-dark mb-2 text-center text-md-left"
              style={{
                fontWeight: 700,
                fontSize: '1.3rem',
              }}
            >
              {`mee.id ${phone}`}
            </p>
            <div
              className="d-flex align-items-center cursor-pointer justify-content-center justify-content-md-start"
              onClick={() => {
                router.navigate('/saymee/account/loyalty');
              }}
            >
              <p
                className="mb-0 text-dark"
                style={{
                  fontWeight: 400,
                  fontSize: '1rem',
                }}
              >
                {t('SaymeeLoyalty')}:{' '}
                <span
                  className="mb-2"
                  style={{
                    fontWeight: 600,
                    fontSize: '1.1rem',
                    color: AppResource.colors.feature,
                  }}
                >
                  {Utils.formatNumber(currentLoyalty.point)}
                </span>
              </p>
              <div
                className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: AppResource.colors.feature,
                }}
              >
                <img src={AppResource.icons.icLoyaltyPoint} />
              </div>
            </div>
          </div>

          {/* right buttons */}
          <div
            className="ml-2 mt-6 mt-md-0"
            style={{
              flex: 'none',
            }}
          >
            <SaymeeButton
              text={t('RechargeMoney')}
              className="saymee-primary-button px-20 px-md-10 h-40px"
              onPress={() => {
                showDialogNotSupportFunction();
              }}
            />
            <SaymeeButton
              text={t('CheckFee')}
              className="saymee-primary-outline-button px-20 px-md-10 h-40px mt-4"
              onPress={() => {
                router.navigate('/saymee/account/fee-check');
              }}
            />
          </div>
        </div>
      </div>

      {/* tai khoan */}
      <div
        className="px-10 px-md-20"
        style={{
          marginTop: '-80px',
        }}
      >
        <div
          className="bg-white w-100 row py-6 m-0"
          style={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
            borderRadius: '0px 0px 12px 12px',
            borderTop: '2px solid #E31D93',
          }}
        >
          {/* tai khoan chinh */}
          <div className="h-100 d-flex flex-column align-items-center justify-content-center col-12 col-sm">
            <p
              className="text-uppercase mb-0 text-center"
              style={{
                fontWeight: 500,
                fontSize: '1.3rem',
                color: AppResource.colors.textSecondary,
              }}
            >
              {t('MainAccount')}
            </p>
            <p
              className="mb-0"
              style={{
                fontWeight: 700,
                fontSize: '2.6rem',
                color: AppResource.colors.feature,
              }}
            >
              {ballance.main}
            </p>
            {!_.isEmpty(ballance.mainExpireDate) ? (
              <p
                className=""
                style={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  color: AppResource.colors.placeholder,
                }}
              >
                <i className="far fa-clock mr-2" />
                {ballance.mainExpireDate}
              </p>
            ) : (
              <p
                className=""
                style={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  color: AppResource.colors.placeholder,
                }}
              >
                &nbsp;
              </p>
            )}
          </div>

          {/* divider */}
          <div className="col-1 align-items-center justify-content-center d-none d-sm-flex">
            <div
              className="w-3px align-self-center"
              style={{
                height: '70%',
                backgroundColor: '#E5E4E4',
                borderRadius: '2px',
              }}
            ></div>
          </div>

          {/* tai khoan khuyen mai */}
          <div className="h-100 d-flex flex-column align-items-center justify-content-center col-12 col-sm mt-4 mt-sm-0">
            <p
              className="text-uppercase mb-0 text-center"
              style={{
                fontWeight: 500,
                fontSize: '1.3rem',
                color: AppResource.colors.textSecondary,
              }}
            >
              {t('PromotionalAccount')}
            </p>
            <p
              className="mb-0"
              style={{
                fontWeight: 700,
                fontSize: '2.6rem',
                color: AppResource.colors.feature,
              }}
            >
              {ballance.promotional}
            </p>
            {!_.isEmpty(ballance.promotionalExpireDate) ? (
              <p
                className=""
                style={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  color: AppResource.colors.placeholder,
                }}
              >
                <i className="far fa-clock mr-2" />
                {ballance.promotionalExpireDate}
              </p>
            ) : (
              <p
                className=""
                style={{
                  fontWeight: 500,
                  fontSize: '1.2rem',
                  color: AppResource.colors.placeholder,
                }}
              >
                &nbsp;
              </p>
            )}
          </div>
        </div>
      </div>

      {/* luu luong khuyen mai */}
      <div
        className="bg-white px-20 py-10 d-flex flex-column align-items-center justify-content-center mt-16"
        style={{
          // marginTop: '130px',
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
        }}
      >
        <p
          className=""
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
            color: AppResource.colors.textPrimary,
          }}
        >
          {t('PromotionalTraffic')}
        </p>
        <div className="row w-100 mt-4">
          {trafficItemList.map((item, index) => {
            return (
              <div
                key={index}
                className="col-12 col-sm-4 d-flex align-items-center justify-content-center"
              >
                <TrafficItem
                  total={item.total}
                  remaining={item.remaining}
                  unit={item.unit}
                  text={item.text}
                  color={item.color}
                />
              </div>
            );
          })}
        </div>
      </div>

      {/* saymee point */}
      <div
        className="px-20 py-10 d-flex flex-column align-items-center justify-content-center mt-16"
        style={{
          boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
          borderRadius: '20px',
          background:
            'linear-gradient(90.24deg, rgba(0, 113, 206, 0.06) 4.63%, rgba(25, 211, 197, 0.06) 95.78%)',
        }}
      >
        <p
          className=""
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
            color: AppResource.colors.textPrimary,
          }}
        >
          {t('SaymeePoint')}
        </p>
        <div className="row w-100">
          <div className="col-lg-4 d-flex align-items-center justify-content-center">
            <KTTooltip text={currentLoyalty.name}>
              <img src={currentLoyalty.avatar} />
            </KTTooltip>
          </div>
          <div
            className="col-lg-8 bg-white p-6 position-relative cursor-pointer hover-opacity-100"
            onClick={() => {
              router.navigate('/saymee/account/loyalty');
            }}
            style={{
              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.04), 0px 4px 20px rgba(0, 0, 0, 0.06)',
              borderRadius: '20px',
            }}
          >
            <p
              className="text-dark mb-2"
              style={{
                fontSize: '1.1rem',
              }}
            >
              {t('YourPoint')}
            </p>
            <div className="d-flex align-items-center">
              <p
                className="mb-0"
                style={{
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  color: AppResource.colors.feature,
                }}
              >
                {Utils.formatNumber(currentLoyalty.point)}
              </p>
              <div
                className="ml-2 w-25px h-25px rounded-circle d-flex align-items-center justify-content-center"
                style={{
                  backgroundColor: AppResource.colors.feature,
                }}
              >
                <img src={AppResource.icons.icLoyaltyPoint} />
              </div>
            </div>
            <div className="mt-0 d-flex w-100 align-items-start justify-content-between">
              <KTTooltip
                text={`${Utils.formatNumber(currentLoyalty.point)}/${
                  nextLoyalty?.point ? Utils.formatNumber(nextLoyalty?.point) : '+ꝏ'
                }`}
                additionalClassName="flex-grow-1"
              >
                <div className="flex-grow-1 pt-5">
                  <KTBSProgress
                    heightInPixel={16}
                    valueMax={1}
                    valueMin={0}
                    valueNow={(currentLoyalty.point * 100) / nextLoyalty.point}
                    borderRadius={8}
                    progressBarAdditionalClassName=""
                    progressBarAdditionalStyle={{
                      backgroundColor: AppResource.colors.feature,
                      borderRadius: '8px',
                    }}
                  />
                  <p
                    className="text-dark mb-0 mt-3"
                    style={{
                      fontSize: '1rem',
                    }}
                  >
                    {t('AccumulateMorePoints', {
                      point: nextLoyalty?.point
                        ? Utils.formatNumber(nextLoyalty?.point - currentLoyalty.point)
                        : '+ꝏ',
                    })}
                  </p>
                </div>
              </KTTooltip>
              <div className="ml-4">
                <KTTooltip text={nextLoyalty.name}>
                  <img src={nextLoyalty.avatar} className="h-50px" />
                </KTTooltip>
              </div>
            </div>

            <img
              className="position-absolute"
              style={{
                top: '22px',
                right: '16px',
              }}
              src={AppResource.icons.icArrowRight}
            />
          </div>
        </div>
      </div>

      {/* tien ich */}
      <div className="d-flex flex-column align-items-center justify-content-center mt-16">
        <p
          className=""
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
            color: AppResource.colors.textPrimary,
          }}
        >
          {t('Utilities')}
        </p>
        <div className="row w-100">
          {utilityMenuItemList.map((item, index) => {
            return (
              <div
                className="col-12 col-sm-6 col-md-4 d-flex align-items-center justify-content-center mb-7 mb-md-0"
                key={index}
              >
                <MenuItem icon={item.icon} text={item.text} onPress={item.onPress} />
              </div>
            );
          })}
        </div>
      </div>

      {/* dich vu da dang ky */}
      <div className="d-flex flex-column align-items-center justify-content-center mt-16">
        <p
          className=""
          style={{
            fontWeight: 700,
            fontSize: '1.8rem',
            color: AppResource.colors.textPrimary,
          }}
        >
          {t('ServiceRegistered')}
        </p>

        {chunkRegisteredServiceList?.length > 0 ? (
          <>
            {chunkRegisteredServiceList.map((item, index) => {
              return (
                <div className="row w-100" key={index}>
                  {item &&
                    _.isArray(item) &&
                    item.map((subItem, subIndex) => {
                      return (
                        <div
                          className="col-12 col-md-6 col-lg-4 d-flex align-items-center justify-content-center mb-7 mb-md-0"
                          key={subIndex}
                        >
                          <ServiceItem
                            title={subItem.title}
                            descriptionList={subItem.descriptionList}
                            iconText={subItem.iconText}
                            backgroundColor={subItem.backgroundColor}
                            expiredDate={subItem.expiredDate}
                            icon={subItem.icon}
                            onPress={() => {
                              setSelectedService(subItem);
                              setModalServiceDetailShowing(true);
                            }}
                          />
                        </div>
                      );
                    })}
                </div>
              );
            })}
          </>
        ) : (
          <>
            <SaymeeEmpty message={t('RegisteredServiceListEmpty')} />
          </>
        )}
      </div>

      {/* khuyen mai danh cho ban */}
      {/* <>
        <div className="d-flex flex-column align-items-center justify-content-center mt-16">
          <p
            className=""
            style={{
              fontWeight: 700,
              fontSize: '1.8rem',
              color: AppResource.colors.textPrimary,
            }}
          >
            {t('PromotionalForYou')}
          </p>
          <div className="row w-100">
            {promotionalList.map((item, index) => {
              return (
                <div className="col d-flex align-items-center justify-content-center" key={index}>
                  <PromotionalItem
                    backgroundColor={item.backgroundColor}
                    date={item.date}
                    text={item.text}
                  />
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-10 d-flex align-items-center justify-content-center">
          <SaymeeButton
            text={t('ViewMore')}
            className="saymee-primary-outline-button px-10 h-40px"
            onPress={() => {}}
            style={{
              width: '369px',
            }}
          />
        </div>
      </> */}

      {/* Modal service detail */}
      <ModalServiceDetail
        serviceItem={selectedService}
        show={modalServiceDetailShowing}
        onClose={() => {
          setModalServiceDetailShowing(false);
        }}
      />
    </div>
  );
}

export default AccountInfoScreen;
